import React, { useState } from 'react';
import Button from "../../../components/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";
import { saveCoachHour } from '../../../services/eventServices';
import { toast } from 'react-toastify';


const EditHoursModal = ({ isOpen, onClose, event, refreshEvents }) => {
    const [coachHour, setCoachHour] = useState(event.AdherentEvent.coach_hour || 0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = { coach_hour: coachHour };
            console.log("Attempting to update coach hours with data:", formData);
            
            await saveCoachHour(event.AdherentEvent.adherent_id, event.AdherentEvent.event_id, formData);
            toast.success('Heures de coaching mises à jour avec succès.');
            onClose(); // Fermer la modale et rafraîchir les événements
            refreshEvents(); // Optionnellement rafraîchir les événements si nécessaire
        } catch (error) {
            toast.error('Erreur lors de la mise à jour des heures de coaching.');
            console.error("Erreur lors de la mise à jour des heures de coaching:", error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                    Modifier les Heures de Coaching
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Heures de présence:</label>
                        <input
                            type="number"
                            name="coach_hour"
                            value={coachHour}
                            onChange={(e) => setCoachHour(e.target.value)}
                            className="input input-bordered w-full"
                            min="0"
                            required
                        />
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditHoursModal;
