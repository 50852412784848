import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/commentnews`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les commentaires de toutes les actualités
export const getComments = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllComments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des commentaires:", error);
    return [];
  }
}

// Fonction pour récupérer tous les commentaires d'une actualité
export const getCommentsByNews = async (newsId) => {
  try {
    const response = await axios.get(`${apiUrl}/getAllComments/${newsId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des commentaires:", error);
    return [];
  }
}

/// POST
// Fonction pour créer un commentaire
export const createComment = async (comment) => {
  try {
    const response = await axios.post(`${apiUrl}/addComment`, comment, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la création du commentaire:", error);
    return null;
  }
}

/// PUT
// Fonction pour modifier un commentaire
export const updateComment = async (comment) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateComment/${comment.id}`,
      comment,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la modification du commentaire:", error);
    return null;
  }
}

/// DELETE
// Fonction pour supprimer un commentaire
export const deleteComment = async (commentId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteComment/${commentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors de la suppression du commentaire:", error);
    return null;
  }
}

