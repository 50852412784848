import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getAgeDistribution } from "../../../services/dashboardServices";

const AgeDistributionChart = () => {
  const [ageData, setAgeData] = useState([]);

  useEffect(() => {
    const fetchAgeData = async () => {
      try {
        const response = await getAgeDistribution();
        const transformedData = response.map((item) => ({
          name: item.age,
          value: item.count,
        }));
        setAgeData(transformedData);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la répartition par âge",
          error
        );
      }
    };

    fetchAgeData();
  }, []);

  const COLORS = ["#4C51BF", "#F6AD55", "#FC8181", "#68D391"];

  return (
    <div className="w-full" style={{ height: "50vh" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={ageData}
            cx="50%"
            cy="50%"
            outerRadius="80%"
            fill="#8884d8"
            dataKey="value"
            nameKey="name"
            label={({ name, value }) => `${name}: ${value}`}
          >
            {ageData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          {/* Mise à jour ici pour la légende en bas */}
          <Legend layout="horizontal" verticalAlign="bottom" align="center" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AgeDistributionChart;
