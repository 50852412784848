import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/usersEvents`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour obtenir tous les événements favoris d'un utilisateur
export const getUsersEvents = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des événements:", error);
    return [];
  }
};

/// POST
// Fonction pour ajouter un événement aux favoris d'un utilisateur
export const addUserEvent = async (user_id, event_id) => {
  try {
    console.log("Sending to addEvent:", { user_id, event_id });
    const response = await axios.post(
      `${apiUrl}/add`,
      { user_id, event_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'événement favori:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour retirer un événement des favoris d'un utilisateur
export const deleteUserEvent = async (user_id, event_id) => {
  try {
    console.log("Sending to removeEvent:", { user_id, event_id });
    const response = await axios.delete(`${apiUrl}/delete`, {
      data: { user_id, event_id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'événement favori:",
      error
    );
    throw error;
  }
};
