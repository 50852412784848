import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollToTop from "./../../../hooks/useScollToTop";
import "./DiplomePage.css";
import { getSettings } from "../../../services/settingsServices";

const DiplomePage = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const location = useLocation();
  const [settings, setSettings] = React.useState(null);
  const {
    adherentId,
    adherentNom,
    adherentPrenom,
    dateNaissance,
    datePassage,
    beltImage,
    beltName,
  } = location.state || {};

  React.useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          setSettings(data[0]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres:", error);
      }
    };
    fetchSettings();
  }, []);

  if (!location.state || !settings) {
    return <div>Pas de données disponibles pour ce diplôme.</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[150px] dark:text-white">
      <div className="printable-area mx-auto max-w-4xl border border-black p-4 bg-gray-200 shadow-lg rounded-lg">
        <div className="header_diplome flex justify-between items-center mb-24">
          <img
            src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720510022/images/disciplines/judo_kanji_stiv7s.jpg"
            alt="Kanji Judo"
            className="kanji-image w-24 h-48 object-contain"
          />
          <h1 className="diploma-title">Attestation de Grade</h1>
          <img
            src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720512682/images/disciplines/jigoro_kano_c7tpyj.jpg"
            alt="Jigoro Kano"
            className="jigoro-image w-24 h-48 rounded-lg object-contain"
          />
        </div>
        <div className="content flex">
          <div className="left w-1/3">
            <img
              src="https://res.cloudinary.com/dy5kblr32/image/upload/v1720512400/images/disciplines/code_moral_judo_ai5yvf.jpg"
              alt="Code Moral"
              className="mx-auto"
            />
          </div>
          <div className="right w-2/3 pl-8">
            <div className="text-center mb-4">
              <img
                src={`/img/belt/${beltImage}`}
                alt={beltName}
                className="mx-auto w-48 h-auto object-contain"
              />
            </div>
            <p className="text-xl">Ceinture : <strong>{beltName}</strong></p>
            <p className="text-xl">Décerné à : <strong>{adherentPrenom} {adherentNom}</strong></p>
            <p className="text-xl">Né le : <strong>{new Date(dateNaissance).toLocaleDateString()}</strong></p>
            <p className="text-xl mt-4">Qui a satisfait aux épreuves de son grade et qui se comporte selon les règles de l'esprit du Judo.</p>
            <p className="text-xl mt-4">Délivré à : <strong>{settings.commune_club}</strong></p>
            <p className="text-xl">Date : <strong>{datePassage}</strong></p>
            <div className="signatures mt-4 flex justify-between mb-4">
              <div className="signature">
                <p>____________________</p>
                <p>Professeur</p>
              </div>
              <div className="signature">
                <p>____________________</p>
                <p>Président</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_diplome mt-8 flex justify-between items-center">
          <img src={settings.logo_club} alt="Logo Club" className="w-32 h-auto"/>
          <div className="text-right">
            <p className="text-sm font-bold">{settings.nom_club}</p>
            <p className="text-sm">{settings.adresse_club}</p>
            <p className="text-sm">{settings.siteweb_club}</p>
          </div>
        </div>
      </div>
      <div className="text-center mt-4 flex justify-center space-x-4">
        <button
          onClick={handlePrint}
          className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700"
        >
          Imprimer / Générer PDF
        </button>
        <button
          onClick={handleBack}
          className="bg-gray-500 text-white py-1 px-3 rounded hover:bg-gray-700"
        >
          Retour
        </button>
      </div>
    </div>
  );
};

export default DiplomePage;
