import React, { useState, useEffect } from "react";
import formatDate from "../../../utils/formatDate";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getReglementsByUser } from "../../../services/achatsServices";
import Button from "../../../components/Button/Button";
import { FaCreditCard } from "react-icons/fa";
import PaymentModal from "./PaymentModal.jsx";
import PaymentPlanModal from "./PaymentPlanModal.jsx";
import Modal from "./Modal.jsx";
import {
  createCheckoutSessionForArticles,
  createCheckoutSessionForInscriptions,
  verifyPayment,
} from "../../../services/paymentServices";
import PaymentSuccess from "./PaymentSuccess.js";
import { getSettings } from "../../../services/settingsServices"; // Importer la fonction getSettings

const UsersReglements = () => {
  useScrollToTop();
  const [reglements, setReglements] = useState([]);
  const [groupedReglements, setGroupedReglements] = useState({});
  const [boutiqueItems, setBoutiqueItems] = useState([]);
  const [licenceItems, setLicenceItems] = useState([]);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPaymentPlanModalOpen, setIsPaymentPlanModalOpen] = useState(false);
  const [nonPaidArticles, setNonPaidArticles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState("One-time");
  const [sessionId, setSessionId] = useState(null);
  const [defaultPhotoUrl, setDefaultPhotoUrl] = useState(""); // Ajouter l'état pour la photo par défaut

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();
        const defaultPhoto = settings.find(
          (setting) => setting.key === "photo_url_default_lic_cot"
        );
        setDefaultPhotoUrl(defaultPhoto ? defaultPhoto.value : "");
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchReglements = async () => {
      try {
        const response = await getReglementsByUser(userId);
        if (!response || response.length === 0) {
          console.error("Aucun règlement trouvé pour l'utilisateur");
          return;
        }
        const sortedReglements = response.sort(
          (a, b) => new Date(b.date_achat) - new Date(a.date_achat)
        ); // Tri décroissant
        const reglementsAvecSaison = sortedReglements.map((reglement) => {
          return {
            ...reglement,
            saison: determineSeason(reglement.date_achat),
            nomArticle: reglement.Article
              ? reglement.Article.nom
              : "Règlement Inscription",
            photoUrl: reglement.Article
              ? reglement.Article.photo_url || defaultPhotoUrl
              : defaultPhotoUrl,
          };
        });
        const groupedBySeason = groupReglementsBySeason(reglementsAvecSaison);
        setGroupedReglements(groupedBySeason);
        setReglements(sortedReglements);

        // Séparer les articles de la boutique et les règlements de licence
        const boutique = reglementsAvecSaison.filter(
          (item) => item.type_achat === "boutique"
        );
        const licence = reglementsAvecSaison.filter(
          (item) => item.type_achat === "licence-assurance-cotisation"
        );
        setBoutiqueItems(boutique);
        setLicenceItems(licence);

        console.log("Reglements de l'utilisateur:", reglementsAvecSaison);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des règlements de l'utilisateur",
          error
        );
      }
    };

    if (userId) {
      fetchReglements();
    }
  }, [userId, defaultPhotoUrl]); // Dépendance sur userId et defaultPhotoUrl

  useEffect(() => {
    console.log("Reglements actuels:", reglements); // Cela vous montrera ce que vous êtes sur le point de filtrer
    const filteredArticles = reglements.filter(
      (reg) => reg.statut_reglement === "Non Réglé"
    );
    setNonPaidArticles(filteredArticles);
    console.log("Articles non payés:", filteredArticles);
  }, [reglements]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get("session_id");
    if (sessionId) {
      setSessionId(sessionId);
    }
  }, []);

  const handlePaymentModalToggle = () => {
    setIsPaymentModalOpen(!isPaymentModalOpen);
  };

  const handlePaymentPlanModalToggle = () => {
    setIsPaymentPlanModalOpen(!isPaymentPlanModalOpen);
  };

  const handlePayerArticlesClick = async () => {
    try {
      const items = selectedItems.map((itemId) => {
        const article = nonPaidArticles.find(
          (article) => article.id === itemId
        );
        return {
          id: article.id,
          name: article.Article ? article.Article.nom : "Règlement Inscription",
          price: article.prix_total,
          quantity: article.quantite,
        };
      });
      const url = await createCheckoutSessionForArticles(items);
      window.location.href = url;
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session de paiement des articles:",
        error
      );
    }
  };

  const handlePayerInscriptionsClick = async (selectedPaymentPlan) => {
    try {
      const items = selectedItems.map((itemId) => {
        const article = nonPaidArticles.find(
          (article) => article.id === itemId
        );
        return {
          id: article.id,
          name: article.Article ? article.Article.nom : "Règlement Inscription",
          price: article.prix_total,
          quantity: article.quantite,
        };
      });
      const url = await createCheckoutSessionForInscriptions(
        items,
        selectedPaymentPlan
      );
      window.location.href = url;
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session de paiement des inscriptions:",
        error
      );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Non Réglé":
        return "bg-red-500 text-white";
      case "Réglé":
        return "bg-green-500 text-white";
      case "En Attente":
        return "bg-yellow-300 text-black";
      case "Annulé":
        return "bg-black text-white";
      case "Remboursé":
        return "bg-purple-500 text-white";
      case "Partiellement Réglé":
        return "bg-green-200 text-black";
      default:
        return "bg-gray-200 text-black";
    }
  };

  const determineSeason = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const seasonStart = new Date(`${year}-08-01`);
    const seasonEnd = new Date(`${year + 1}-07-31`);
    if (date >= seasonStart && date <= seasonEnd) {
      return `Saison ${year}-${year + 1}`;
    } else {
      return `Saison ${year - 1}-${year}`;
    }
  };

  const groupReglementsBySeason = (reglements) => {
    return reglements.reduce((acc, reglement) => {
      const season = determineSeason(reglement.date_achat);
      if (!acc[season]) {
        acc[season] = [];
      }
      acc[season].push(reglement);
      return acc;
    }, {});
  };

  const openPaymentModal = (nonPaidArticles) => {
    setNonPaidArticles(nonPaidArticles);
    setIsPaymentModalOpen(true);
    console.log("Articles non payés:", nonPaidArticles);
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const openPaymentPlanModal = (nonPaidArticles) => {
    setNonPaidArticles(nonPaidArticles);
    setIsPaymentPlanModalOpen(true);
    console.log("Articles non payés:", nonPaidArticles);
  };

  const closePaymentPlanModal = () => {
    setIsPaymentPlanModalOpen(false);
  };

  const boutiqueArticlesNonRegles = boutiqueItems.filter(
    (article) => article.statut_reglement === "Non Réglé"
  );
  const inscriptionArticlesNonRegles = licenceItems.filter(
    (article) => article.statut_reglement === "Non Réglé"
  );

  const hasNonRegles =
    boutiqueArticlesNonRegles.length > 0 ||
    inscriptionArticlesNonRegles.length > 0;

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Vos Commandes & Réglements
      </h2>
      <DashboardNavigation role={userRole} />

      {sessionId && <PaymentSuccess sessionId={sessionId} />}

      <div className="text-center p-4 bg-blue-100 rounded-lg shadow max-w-[800px] mx-auto mb-4 dark:text-gray-800">
        <p className="text-lg font-semibold mb-2">
          Bienvenue sur la page Règlements de vos articles et cotisations
        </p>
        <p className="text-sm text-left">
          Sur cette page, vous pouvez régler en ligne les articles commandés dans
          notre boutique, et les licences-cotisations pour la saison en
          cours.
        </p>
        <p className="text-sm text-left">
          Si le statut du règlement n'a pas changé après votre paiement, veuillez
          rafraichir la page pour voir les mises à jour.
        </p>
      </div>

      <div className="text-center mb-4">
        {hasNonRegles ? (
          <p className="text-lg font-semibold text-red-500">
            Vous avez des articles ou licence-cotisation non réglés
          </p>
        ) : (
          <p className="text-lg font-semibold text-green-500">
            Vous êtes à jour des règlements
          </p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row justify-between mb-4 max-w-[1280px] mx-auto font-montserrat">
        <div className="w-full lg:w-1/2 p-2 border-r lg:border-r-2 border-gray-300">
          <div className="mb-4 flex justify-between items-center">
            <h3 className="text-lg font-semibold">Règlement Achats Boutique</h3>
            {boutiqueArticlesNonRegles.length > 0 ? (
              <Button
                text="Effectuer Règlement Boutique"
                icon={FaCreditCard}
                onClick={handlePaymentModalToggle}
                className="gap-2"
              />
            ) : (
              <Button
                text="Pas d'articles à régler"
                icon={FaCreditCard}
                disabled={true}
                className="gap-2"
              />
            )}
          </div>
          <table className="min-w-full bg-white divide-y divide-gray-200 font-lato hidden sm:table">
            {boutiqueItems.length > 0 ? (
              <tbody className="divide-y divide-gray-200 font-montserrat">
                {boutiqueItems.map((reglement) => (
                  <tr
                    key={reglement.id}
                    className="dark:bg-slate-200 hover:bg-slate-200 dark:hover:bg-slate-400 dark:hover:text-slate-200 cursor-pointer"
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(reglement.date_achat)}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      <div className="font-semibold">{`${reglement.quantite} x ${reglement.nomArticle}`}</div>
                      <div className="text-xs italic text-gray-800">
                        {reglement.message_achat}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      {reglement.photoUrl && (
                        <img
                          src={reglement.photoUrl}
                          alt={reglement.nomArticle}
                          className="w-32 h-32 object-contain aspect-square"
                        />
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500 min-w-[90px]">{`${reglement.prix_total} €`}</td>
                    <td className="text-center">
                      <span
                        className={`inline-block ml-2 my-2 px-3 py-1 rounded-full font-semibold text-xs ${getStatusColor(
                          reglement.statut_reglement
                        )}`}
                      >
                        {reglement.statut_reglement}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan="5"
                    className="text-center py-4 px-4 dark:text-black"
                  >
                    Pas d'articles commandés
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="sm:hidden">
            {boutiqueItems.length > 0 ? (
              boutiqueItems.map((reglement) => (
                <div
                  key={reglement.id}
                  className="bg-white dark:bg-slate-200 shadow-md rounded-lg mb-4 p-4 flex"
                >
                  <div className="flex-1 pr-4">
                    <div className="text-sm text-gray-500">
                      {formatDate(reglement.date_achat)}
                    </div>
                    <div className="text-sm text-gray-500 font-semibold">
                      {`${reglement.quantite} x ${reglement.nomArticle}`}
                    </div>
                    <div className="text-xs italic text-gray-800">
                      {reglement.message_achat}
                    </div>
                    <div className="text-normal font-bold text-gray-500">{`${reglement.prix_total} €`}</div>
                    <span
                      className={`inline-block ml-2 my-2 px-3 py-1 rounded-full font-semibold text-xs ${getStatusColor(
                        reglement.statut_reglement
                      )}`}
                    >
                      {reglement.statut_reglement}
                    </span>
                  </div>
                  {reglement.photoUrl && (
                    <div className="w-32 h-32">
                      <img
                        src={reglement.photoUrl}
                        alt={reglement.nomArticle}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center py-4 px-4 dark:text-black">
                Pas d'articles commandés
              </div>
            )}
          </div>
        </div>

        <div className="w-full lg:w-1/2 p-2 lg:ml-4">
          <div className="mb-4 flex justify-between items-center">
            <h3 className="text-lg font-semibold">Règlement Inscription</h3>
            {inscriptionArticlesNonRegles.length > 0 ? (
              <Button
                text="Effectuer Règlement Inscription"
                icon={FaCreditCard}
                onClick={() =>
                  openPaymentPlanModal(inscriptionArticlesNonRegles)
                }
                className="gap-2"
              />
            ) : (
              <Button
                text="Pas d'inscription à régler"
                icon={FaCreditCard}
                disabled={true}
                className="gap-2"
              />
            )}
          </div>
          <table className="min-w-full bg-white divide-y divide-gray-200 font-lato hidden sm:table">
            {licenceItems.length > 0 ? (
              <tbody className="divide-y divide-gray-200 font-montserrat">
                {licenceItems.map((reglement) => (
                  <tr
                    key={reglement.id}
                    className="dark:bg-slate-200 hover:bg-slate-200 dark:hover:bg-slate-400 dark:hover:text-slate-200 cursor-pointer"
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(reglement.date_achat)}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      <div className="font-semibold">{`${reglement.quantite} x ${reglement.nomArticle}`}</div>
                      <div className="text-xs italic text-gray-800">
                        {reglement.message_achat}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500">
                      {reglement.photoUrl && (
                        <img
                          src={reglement.photoUrl}
                          alt={reglement.nomArticle}
                          className="w-16 h-16 object-cover"
                        />
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500 min-w-[90px]">{`${reglement.prix_total} €`}</td>
                    <td className="text-center">
                      <span
                        className={`inline-block ml-2 my-2 px-3 py-1 rounded-full font-semibold text-xs ${getStatusColor(
                          reglement.statut_reglement
                        )}`}
                      >
                        {reglement.statut_reglement}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan="5"
                    className="text-center py-4 px-4 dark:text-black"
                  >
                    Pas de règlements de licence-assurance-cotisation
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="sm:hidden">
            {licenceItems.length > 0 ? (
              licenceItems.map((reglement) => (
                <div
                  key={reglement.id}
                  className="bg-white dark:bg-slate-200 shadow-md rounded-lg mb-4 p-4 flex"
                >
                  <div className="flex-1 pr-4">
                    <div className="text-sm text-gray-500">
                      {formatDate(reglement.date_achat)}
                    </div>
                    <div className="text-sm text-gray-500 font-semibold">
                      {`${reglement.quantite} x ${reglement.nomArticle}`}
                    </div>
                    <div className="text-xs italic text-gray-800">
                      {reglement.message_achat}
                    </div>
                    <div className="text-sm text-gray-500">{`${reglement.prix_total} €`}</div>
                    <span
                      className={`inline-block ml-2 my-2 px-3 py-1 rounded-full font-semibold text-xs ${getStatusColor(
                        reglement.statut_reglement
                      )}`}
                    >
                      {reglement.statut_reglement}
                    </span>
                  </div>
                  {reglement.photoUrl && (
                    <div className="w-16 h-16">
                      <img
                        src={reglement.photoUrl}
                        alt={reglement.nomArticle}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center py-4 px-4 dark:text-black">
                Pas de règlements de licence-assurance-cotisation
              </div>
            )}
          </div>
        </div>
      </div>

      {isPaymentModalOpen && (
        <Modal
          isOpen={openPaymentModal}
          onClose={closePaymentModal}
          title="Règlement des Articles"
        >
          <PaymentModal
            isOpen={isPaymentModalOpen}
            onClose={handlePaymentModalToggle}
            articles={nonPaidArticles}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onPayer={handlePayerArticlesClick}
          />
        </Modal>
      )}

      {isPaymentPlanModalOpen && (
        <Modal
          isOpen={openPaymentPlanModal}
          onClose={closePaymentPlanModal}
          title="Règlement des Inscriptions"
        >
          <PaymentPlanModal
            isOpen={isPaymentPlanModalOpen}
            onClose={handlePaymentPlanModalToggle}
            articles={nonPaidArticles}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onPayer={handlePayerInscriptionsClick}
          />
        </Modal>
      )}
    </div>
  );
};

export default UsersReglements;
