import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/heures_enseignement`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour obtenir toutes les heures d'enseignement
export const getAllHeures_enseignement = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allHeures_enseignement`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des heures d'enseignement:",
      error
    );
    throw error;
  }
};

/// POST
// Fonction pour créer une nouvelle heure d'enseignement
export const createHeures_enseignement = async (data) => {
  try {
    const response = await axios.post(
      `${apiUrl}/createHeures_enseignement`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la création des heures d'enseignement:",
      error
    );
    throw error;
  }
};

/// PUT
// Fonction pour mettre à jour une heure d'enseignement
export const updateHeures_enseignement = async (id, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateHeures_enseignement/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour des heures d'enseignement:",
      error
    );
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer une heure d'enseignement
export const deleteHeures_enseignement = async (id) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteHeures_enseignement/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression des heures d'enseignement:",
      error
    );
    throw error;
  }
};

// Fonction pour supprimer toutes les heures d'enseignement
export const deleteAllHeures_enseignement = async () => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteAllHeures_enseignement`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de toutes les heures d'enseignement:",
      error
    );
    throw error;
  }
};
