import React, { useState, useEffect } from "react";
import { getAllMessages, deleteMessageById } from "./../../../services/messageServices";
import { getAdminsAndInstructorsIds } from "./../../../services/userServices"; // Importez la fonction ici
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { ToastContainer, toast } from "react-toastify";

const MessageAdmin = () => {
  useScrollToTop();
  const { user } = useUser();
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchSender, setSearchSender] = useState("");
  const [searchRecipient, setSearchRecipient] = useState("");
  const [searchSubject, setSearchSubject] = useState("");
  const [showAllMessages, setShowAllMessages] = useState(false); // État pour basculer entre les vues
  const [adminInstructorIds, setAdminInstructorIds] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const [messagesData, adminInstructorIdsData] = await Promise.all([
          getAllMessages(),
          getAdminsAndInstructorsIds()
        ]);

        const adminInstructorIdsList = adminInstructorIdsData.map(item => item.id);
        setAdminInstructorIds(adminInstructorIdsList);

        const filteredData = messagesData.filter(
          (message) => !message.is_copy
        );

        setMessages(filteredData);
        setFilteredMessages(filteredData);
      } catch (error) {
        toast.error("Erreur lors du chargement des messages.");
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    let filtered = messages;

    if (!showAllMessages) {
      filtered = filtered.filter(
        (message) => !adminInstructorIds.includes(message.sender_id)
      );
    }

    if (searchSender) {
      filtered = filtered.filter((message) =>
        `${message.Sender.nom} ${message.Sender.prenom}`
          .toLowerCase()
          .includes(searchSender.toLowerCase())
      );
    }
    if (searchRecipient) {
      filtered = filtered.filter((message) =>
        `${message.Recipient.nom} ${message.Recipient.prenom}`
          .toLowerCase()
          .includes(searchRecipient.toLowerCase())
      );
    }
    if (searchSubject) {
      filtered = filtered.filter((message) =>
        message.subject.toLowerCase().includes(searchSubject.toLowerCase())
      );
    }
    setFilteredMessages(filtered);
  }, [searchSender, searchRecipient, searchSubject, messages, showAllMessages, adminInstructorIds]);

  const handleDeleteMessage = async (messageId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?")) {
      try {
        await deleteMessageById(messageId);
        setMessages(messages.filter((message) => message.id !== messageId));
        toast.success("Message supprimé avec succès.");
      } catch (error) {
        toast.error("Erreur lors de la suppression du message.");
      }
    }
  };

  const getBgColorBySubject = (subject) => {
    switch (subject) {
      case "Adhésion":
        return "bg-green-100";
      case "Dossier d'inscription":
        return "bg-blue-100";
      case "Règlement":
        return "bg-yellow-100";
      case "Informations cours":
        return "bg-purple-100";
      case "Absence":
        return "bg-red-100";
      case "Inscription compétition":
        return "bg-orange-100";
      case "Perte d'objet":
        return "bg-pink-100";
      case "Autre":
        return "bg-gray-100";
      default:
        return "bg-white";
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('fr-FR', options).replace(',', ' -');
  };

  if (!user) {
    return <div>Chargement...</div>;
  }

  const userRole = getRoleFromRoleId(user.role_id);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Messages Admin
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col md:flex-row items-center justify-center gap-4 mt-4 max-w-[1280px] mx-auto w-full">
        <input
          type="text"
          placeholder="Rechercher par expéditeur"
          value={searchSender}
          onChange={(e) => setSearchSender(e.target.value)}
          className="input input-bordered w-full md:w-1/3 dark:text-gray-800 dark:bg-slate-200"
        />
        <input
          type="text"
          placeholder="Rechercher par destinataire"
          value={searchRecipient}
          onChange={(e) => setSearchRecipient(e.target.value)}
          className="input input-bordered w-full md:w-1/3 dark:text-gray-800 dark:bg-slate-200"
        />
      </div>

      <div className="w-full max-w-[1280px] mx-auto mt-4 mb-4">
        <select
          value={searchSubject}
          onChange={(e) => setSearchSubject(e.target.value)}
          className="select select-bordered w-full dark:text-gray-800 dark:bg-slate-200"
        >
          <option value="">Sélectionnez une option</option>
          <option value="Adhésion">Adhésion</option>
          <option value="Dossier d'inscription">Dossier d'inscription</option>
          <option value="Règlement">Règlement</option>
          <option value="Informations cours">Informations cours</option>
          <option value="Absence">Absence</option>
          <option value="Inscription compétition">Inscription compétition</option>
          <option value="Perte d'objet">Perte d'objet</option>
          <option value="Autre">Autre</option>
        </select>
        <label className="flex items-center mt-2">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={showAllMessages}
            onChange={(e) => setShowAllMessages(e.target.checked)}
          />
          <span className="ml-2">Afficher tous les messages</span>
        </label>
      </div>

      <div className="w-full max-w-[1280px] mx-auto mt-4 mb-4">
        {filteredMessages.map((message) => (
          <div
            key={message.id}
            className={`p-4 mb-4 shadow-md rounded-lg flex flex-col md:flex-row justify-between items-start ${getBgColorBySubject(message.subject)}`}
          >
            <div className="flex-grow">
              <div className="text-sm text-gray-900 dark:text-gray-800 mb-1">
                <span className="font-semibold">{message.Sender.nom} {message.Sender.prenom}</span> {'>'} <span className="font-semibold">{message.Recipient.nom} {message.Recipient.prenom}</span>
              </div>
              <div className="text-sm text-gray-900 dark:text-gray-800 mb-1">
                <span className="font-semibold">Sujet:</span> {message.subject}
              </div>
              <div className="text-sm text-gray-900 dark:text-gray-800 mb-1">
                <span className="font-semibold">Message:</span>
                <span dangerouslySetInnerHTML={{ __html: message.content }} />
              </div>
              <div className="text-xs text-gray-600 dark:text-gray-400 mb-1 italic">
                {formatDateTime(message.created_at)}
              </div>
            </div>
            <div className="flex-shrink-0 md:ml-4">
              <ButtonDelete
                onClick={() => handleDeleteMessage(message.id)}
                hoverLabel="Supprimer"
              />
            </div>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default MessageAdmin;
