import React, { useState, useEffect } from "react";
import { getPalmaresByAdherentId } from "../../../services/palmaresServices";

const AdherentPalmares = ({ adherentId }) => {
  const [palmaress, setPalmaress] = useState([]);

  useEffect(() => {
    const getPalmaresByAdherent = async () => {
      try {
        const data = await getPalmaresByAdherentId(adherentId);
        if (Array.isArray(data)) {
          setPalmaress(data);
        } else {
          throw new Error("Data received is not an array");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des palmarès:", error);
      }
    };

    getPalmaresByAdherent();
  }, [adherentId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Palmarès</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2">Date</th>
              <th className="py-2 px-4 border-b-2">Animations & Résultats</th>
            </tr>
          </thead>
          <tbody>
            {palmaress.length > 0 ? (
              palmaress.map((palmares, index) => (
                <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="py-2 px-4 border-b">{new Date(palmares.date_palmares).toLocaleDateString()}</td>
                  <td className="py-2 px-4 border-b">
                    <div className="font-semibold">{palmares.titre_palmares || "Inconnu"}</div>
                    {palmares.resultats_palmares && <div>Résultats: {palmares.resultats_palmares}</div>}
                    {palmares.classement_palmares && <div>Classement: {palmares.classement_palmares}</div>}
                    {palmares.observations_palmares && <div>Observations: {palmares.observations_palmares}</div>}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center py-4">Aucun palmarès.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdherentPalmares;
