import React, { useEffect, useState } from 'react';
import { updateDossierInscription } from '../../../services/dossierServices'; // Assurez-vous que le chemin est correct
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import { FaSave, FaTimes, FaCheck, FaTimesCircle } from 'react-icons/fa';

const EditDossierModal = ({ isOpen, onClose, dossier, refreshDossiers }) => {
  const [formData, setFormData] = useState({
    cm_url: dossier.cm_url || '',
    formulaire_licence_a_signer: dossier.formulaire_licence_a_signer || '',
    date_cm: dossier.date_cm || '',
  });

  useEffect(() => {
    if (dossier) {
      setFormData({
        cm_url: dossier.cm_url || '',
        formulaire_licence_a_signer: dossier.formulaire_licence_a_signer || '',
        date_cm: dossier.date_cm ? new Date(dossier.date_cm).toISOString().slice(0, 10) : '',
      });
    }
  }, [dossier]);

  const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData(prev => ({
    ...prev,
    [name]: value,
  }));
};

  const handleSubmit = async (e) => {
  e.preventDefault();
  // Vérifiez si l'ID du dossier est bien défini
  if (!dossier || !dossier.id) {
    toast.error('Identifiant du dossier manquant.');
    return; // Stop further execution if no dossier ID is available
  }
  try {
    // Utilisez `dossier.id` pour l'identifiant du dossier
    await updateDossierInscription(dossier.id, formData);
    refreshDossiers(); // Rafaîchir les données après mise à jour
    toast.success('Dossier mis à jour avec succès');
    onClose(); // Fermer la modale après mise à jour
  } catch (error) {
    console.error('Erreur lors de la mise à jour du dossier', error);
    toast.error('Erreur lors de la mise à jour du dossier');
  }
};

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 font-montserrat">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier le Dossier d'Inscription
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 dark:text-black">Lien Certificat Médical:
              {formData.cm_url ? <FaCheck className="inline ml-2 text-green-500" /> : <FaTimesCircle className="inline ml-2 text-red-500" />}
            </label>
            <input
              type="text"
              name="cm_url"
              value={formData.cm_url}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 dark:text-black">Date Certificat Médical:
              {formData.date_cm ? <FaCheck className="inline ml-2 text-green-500" /> : <FaTimesCircle className="inline ml-2 text-red-500" />}
            </label>
            <input
              type="date"
              name="date_cm"
              value={formData.date_cm}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1 dark:text-black">Lien Formulaire Licence:
              {formData.formulaire_licence_a_signer ? <FaCheck className="inline ml-2 text-green-500" /> : <FaTimesCircle className="inline ml-2 text-red-500" />}
            </label>
            <input
              type="text"
              name="formulaire_licence_a_signer"
              value={formData.formulaire_licence_a_signer}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDossierModal;
