import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { updatePalmares } from '../../../services/palmaresServices';
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';

const EditPalmaresModal = ({ isOpen, onClose, refreshList, palmares }) => {
  const [formData, setFormData] = useState({
    adherent_palmares_id: '',
    event_palmares_id: '',
    date_palmares: '',
    titre_palmares: '',
    resultats_palmares: '',
    classement_palmares: '',
    observations_palmares: '',
  });

  useEffect(() => {
    if (palmares) {
      setFormData({
        adherent_palmares_id: palmares.adherent_palmares_id || '',
        event_palmares_id: palmares.event_palmares_id || '',
        date_palmares: palmares.date_palmares ? new Date(palmares.date_palmares).toISOString().slice(0, 10) : '',
        titre_palmares: palmares.titre_palmares || '',
        resultats_palmares: palmares.resultats_palmares || '',
        classement_palmares: palmares.classement_palmares || '',
        observations_palmares: palmares.observations_palmares || '',
      });
    }
  }, [palmares]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updatePalmares(palmares.id, formData);
      refreshList();
      onClose();
      toast.success('Palmarès mis à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour du palmarès", error);
      toast.error("Erreur lors de la mise à jour du palmarès");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier un Palmarès
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="date" name="date_palmares" value={formData.date_palmares} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="titre_palmares" placeholder="Titre" value={formData.titre_palmares} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="resultats_palmares" placeholder="Résultats" value={formData.resultats_palmares} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="classement_palmares" placeholder="Classement" value={formData.classement_palmares} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <textarea name="observations_palmares" placeholder="Observations" value={formData.observations_palmares} onChange={handleChange} className="textarea textarea-bordered w-full mb-3"></textarea>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPalmaresModal;
