import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const InfoBubble = ({ title, children }) => {
  return (
    <div className="relative max-w-[800px] mx-auto mb-6 p-4 bg-blue-100 rounded-lg shadow dark:bg-blue-900 dark:text-gray-200">
      <div className="absolute top-0 left-0 h-full w-2 bg-blue-400 rounded-tl-lg rounded-bl-lg"></div>
      <div className="flex items-center mb-2">
        <FaInfoCircle className="text-blue-400 mr-2" />
        <p className="text-lg font-semibold">{title}</p>
      </div>
      <div className="text-sm text-left">{children}</div>
    </div>
  );
};

export default InfoBubble;