import React from "react";
import Button from "../../../components/Button/Button";

const EventDetailsModal = ({ event, isOpen, onClose }) => {
  if (!isOpen || !event) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-40 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-md bg-white flex flex-col md:flex-row h-auto md:h-[60vh] overflow-hidden">
        <div className="flex flex-col w-full md:w-2/3 p-4 overflow-y-auto font-montserrat">
          <h3 className="text-xl leading-6 font-medium mb-4 bg-slate-800 text-white p-2">{event.titre}</h3>
          {event.description && <p className="text-sm text-gray-500">{event.description}</p>}
          {event.start && <p><strong>Date de début :</strong> {new Date(event.start).toLocaleDateString("fr-FR")}</p>}
          {event.end && <p><strong>Date de fin :</strong> {new Date(event.end).toLocaleDateString("fr-FR")}</p>}
          {event.lieu_event && <p><strong>Lieu :</strong> {event.lieu_event}</p>}
          {event.type_event && <p><strong>Type :</strong> {event.type_event}</p>}
          {event.level_event && <p><strong>Niveau :</strong> {event.level_event}</p>}
          {event.cate_event && <p><strong>Catégories :</strong> {event.cate_event}</p>}
          {event.horaire_event && <p><strong>Horaires :</strong> {event.horaire_event}</p>}
          {event.agenda_url && (
            <p><a href={event.agenda_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">Ajouter à votre agenda</a></p>
          )}
          {/* Trait de séparation */}
          <hr className="my-4 border-t border-gray-200"/>
          {event.resultats && <p><strong>Résultats :</strong> {event.resultats}</p>}
          {event.classement && <p><strong>Classement :</strong> {event.classement}</p>}
          {event.observations && <p><strong>Observations :</strong> {event.observations}</p>}
        </div>
        {event.photo_url && (
          <div className="w-full md:w-1/3 flex justify-center items-center p-4 bg-gray-100">
            <img src={event.photo_url} alt="Event" className="max-w-full md:h-full object-cover rounded-md shadow"/>
          </div>
        )}
        <div className="absolute bottom-0 left-0 w-full px-4 py-3 bg-white border-t">
          <Button
            text="Fermer"
            onClick={onClose}
            className="bg-gray-500 text-white font-medium rounded-md px-4 py-2"
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetailsModal;
