import React, { useEffect, useState } from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getAllGrades, deleteGrade } from "../../../services/gradCnServices";
import { getSettings } from "../../../services/settingsServices";
import {
  getBeltHistoryByAdherent,
  getBeltsList,
} from "../../../services/beltServices";
import Button from "./../../../components/Button/Button";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { FaPlusSquare } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import CreateGradeModal from "./CreateGradeModal";
import EditGradeModal from "./EditGradeModal";

const getDefaultPhoto = (genre) => {
  return genre === "Masculin"
    ? "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judoka_avatar_pi37iq.png"
    : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judokate_avatar_odsr9l.png";
};

function GradeCNPage() {
  useScrollToTop();
  const [grades, setGrades] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editableGrade, setEditableGrade] = useState({});
  const [beltHistories, setBeltHistories] = useState({});
  const [belts, setBelts] = useState([]);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [settings, setSettings] = useState({});
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [gradesData, beltsData, settingsData] = await Promise.all([
          getAllGrades(),
          getBeltsList(),
          getSettings(),
        ]);
        setGrades(gradesData);
        setBelts(beltsData);
        const fetchedSettings = settingsData[0];
        setSettings(fetchedSettings);
        setSelectedSeason(fetchedSettings?.saison_active);

        const previousSeason = getPreviousSeason(fetchedSettings.saison_active);
        const nextSeason = getNextSeason(fetchedSettings.saison_active);
        setSeasons([previousSeason, fetchedSettings.saison_active, nextSeason]);

        const beltHistoryPromises = gradesData.map((grade) =>
          getBeltHistoryByAdherent(grade.adherent_id)
        );
        const beltHistoriesResults = await Promise.all(beltHistoryPromises);
        const beltHistories = beltHistoriesResults.reduce(
          (acc, history, index) => {
            acc[gradesData[index].adherent_id] = history[0];
            return acc;
          },
          {}
        );
        setBeltHistories(beltHistories);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        setGrades([]);
      }
    };

    fetchData();
  }, []);

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const getFilteredGrades = () => {
    return grades.filter((grade) => {
      const matchesSearchTerm =
        grade.Adherent.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        grade.Adherent.prenom.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesSeason =
        selectedSeason === "" ||
        grade.Adherent.saison_active === selectedSeason;
      return matchesSearchTerm && matchesSeason;
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e.target.value);
  };

  const openEditModal = (grade) => {
    setEditableGrade(grade);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    fetchGrades();
    setIsModalOpen(false);
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    fetchGrades();
    setIsCreateModalOpen(false);
  };

  const deleteGradeHandler = async (id) => {
    try {
      await deleteGrade(id);
      const updatedGrades = grades.filter((grade) => grade.id !== id);
      setGrades(updatedGrades);
      toast.success("Grade supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression du grade", error);
      toast.error("Erreur lors de la suppression du grade");
    }
  };

  const fetchGrades = async () => {
    try {
      const data = await getAllGrades();
      setGrades(data);
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      setGrades([]);
    }
  };

  const renderUvIcon = (value) => {
    return value === 1 ? "✅" : "❌";
  };

  const getBeltPhoto = (beltId) => {
    const belt = belts.find((b) => b.id === beltId);
    return belt
      ? `/img/belt/${belt.belt_url}`
      : "https://via.placeholder.com/150";
  };

  const getAdherentPhoto = (adherent) => {
    return adherent.photo_url
      ? adherent.photo_url
      : getDefaultPhoto(adherent.genre);
  };

  const getNextBeltPhoto = (currentBeltId) => {
    const currentBeltIndex = belts.findIndex((b) => b.id === currentBeltId);
    if (currentBeltIndex === -1 || currentBeltIndex + 1 >= belts.length) {
      return "https://via.placeholder.com/150";
    }
    const nextBelt = belts[currentBeltIndex + 1];
    return nextBelt
      ? `/img/belt/${nextBelt.belt_url}`
      : "https://via.placeholder.com/150";
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Grades CN
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4 mt-4">
        <div className="w-full max-w-4xl px-2">
          <div className="mb-4 w-full md:w-auto font-montserrat">
            <div className="form-control">
              <label className="text-sm font-medium text-gray-700 mb-1">
                <span className="label-text dark:text-white">Recherche</span>
              </label>
              <input
                type="text"
                placeholder="Rechercher par nom ou prénom..."
                className="input input-bordered w-full dark:bg-slate-200"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="form-control mt-4">
              <label className="text-sm font-medium text-gray-700 mb-1">
                <span className="label-text dark:text-white">Saison</span>
              </label>
              <select
                name="selectedSeason"
                value={selectedSeason}
                onChange={handleSeasonChange}
                className="input input-bordered w-full dark:bg-slate-200"
              >
                {seasons.map((season) => (
                  <option key={season} value={season}>
                    {season}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <Button
            text="Créer un Grade"
            onClick={openCreateModal}
            icon={FaPlusSquare}
            className="gap-2 mt-4 mb-4"
            hoverLabel="Créer un grade"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {getFilteredGrades().map((grade) => {
            const beltHistory = beltHistories[grade.adherent_id];
            const currentBeltPhoto = beltHistory
              ? getBeltPhoto(beltHistory.belt_id)
              : "https://via.placeholder.com/150";
            const nextBeltPhoto = beltHistory
              ? getNextBeltPhoto(beltHistory.belt_id)
              : "https://via.placeholder.com/150";
            const beltDate = beltHistory
              ? formatDate(beltHistory.date_passage)
              : "N/A";

            return (
              <div
                key={grade.id}
                className="bg-slate-300 shadow-md rounded-lg p-4"
              >
                <div className="flex flex-col items-center">
                  <img
                    src={getAdherentPhoto(grade.Adherent)}
                    alt="Photo de l'adhérent"
                    className="w-24 h-24 object-cover rounded-full mb-2"
                  />
                  <div className="font-semibold">{`${grade.Adherent.nom} ${grade.Adherent.prenom}`}</div>
                  <div className="text-sm text-gray-600 mb-2">
                    Dernier passage: {beltDate}
                  </div>
                  <div className="flex items-center mt-2">
                    <img
                      src={currentBeltPhoto}
                      alt="Ceinture Actuelle"
                      className="w-24 h-10 object-cover"
                    />
                    <span className="mx-2">&gt;</span>
                    <img
                      src={nextBeltPhoto}
                      alt="Ceinture Supérieure"
                      className="w-24 h-10 object-cover"
                    />
                  </div>
                  <div className="w-full mt-2 text-sm">
                    <div>
                      <strong>Dominante:</strong> {grade.dominante}
                    </div>
                    <div>
                      <strong>UV1:</strong> {renderUvIcon(grade.uv1)}
                    </div>
                    <div>
                      <strong>UV2:</strong> {renderUvIcon(grade.uv2)}
                    </div>
                    <div>
                      <strong>UV3 Points:</strong> {grade.uv3_points}
                    </div>
                    <div>
                      <strong>UV3:</strong> {renderUvIcon(grade.uv3)}
                    </div>
                    <div>
                      <strong>UV4:</strong> {renderUvIcon(grade.uv4)}
                    </div>
                    <div>
                      <strong>Observations:</strong> {grade.observations}
                    </div>
                  </div>
                  <div className="flex justify-center gap-2 mt-4">
                    <ButtonEdit
                      onClick={() => openEditModal(grade)}
                      hoverLabel="Éditer"
                    />
                    <ButtonDelete
                      onClick={() => deleteGradeHandler(grade.id)}
                      hoverLabel="Supprimer"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <CreateGradeModal
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
          refreshList={fetchGrades}
        />
        <EditGradeModal
          isOpen={isModalOpen}
          onClose={closeEditModal}
          grade={editableGrade}
          refreshList={fetchGrades}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default GradeCNPage;
