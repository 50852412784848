import React, { useState, useEffect } from "react";
import { getLatestMembers } from "../../../services/dashboardServices";
import ModalFicheAdherent from "./ModalFicheAdherent";
import AdherentDetails from "./../../PagesUtilisateurs/FicheAdherent/AdherentDetails";
import AdherentGroupCategory from "./../../PagesUtilisateurs/FicheAdherent/AdherentGroupCategory";
import AdherentPassageCeinture from "./../../PagesUtilisateurs/FicheAdherent/AdherentPassageCeinture";
import AdherentPalmares from "./../../PagesUtilisateurs/FicheAdherent/AdherentPalmares";

const LatestMembers = () => {
  const [derniersAdherents, setDerniersAdherents] = useState([]);
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getLatestMembers()
      .then((members) => {
        setDerniersAdherents(
          members.map((member) => ({
            ...member,
            groups: member.Groups.map((g) => g.nom),
          }))
        );
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des derniers adhérents",
          error
        );
      });
  }, []);

  // Fonction pour choisir l'image par défaut en fonction du genre
  const getDefaultPhoto = (genre) => {
    return genre === "Masculin"
      ? "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judoka_avatar_pi37iq.png"
      : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judokate_avatar_odsr9l.png";
  };

  const getStatusColor = (statut_inscription) => {
    switch (statut_inscription) {
      case "Licencié":
        return "bg-green-300";
      case "Licencié Hors Club":
        return "bg-green-200";
      case "Inscrit":
        return "bg-yellow-300";
      case "Cours d'essai":
        return "bg-red-300";
      case "Licencié - Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié et Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié":
        return "bg-slate-400";
      default:
        return "bg-base-100"; // Default background color if status is undefined or not in the list
    }
  };

  const GroupTag = ({ groups }) => {
    return (
      <div className="absolute top-0 left-0 p-1 bg-opacity-75 bg-black text-white text-xs rounded-br-lg">
        {groups.join(", ")}
      </div>
    );
  };

  const openModal = (adherent) => {
    setSelectedAdherent(adherent);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdherent(null);
  };

  const getAdherentPhotoUrl = (photoUrl, genre) => {
    return photoUrl && photoUrl !== "/img/default_avatar.png"
      ? photoUrl
      : getDefaultPhoto(genre);
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4">
        {derniersAdherents.map((adherent) => (
          <div
            key={adherent.id}
            onClick={() => openModal(adherent)}
            className={`relative shadow-xl rounded-lg overflow-hidden ${getStatusColor(
              adherent.statut_inscription
            )}`}
          >
            <div className="flex flex-col h-full">
              <div className="relative w-full flex-grow">
                <GroupTag groups={adherent.groups} />
                <img
                  src={getAdherentPhotoUrl(adherent.photo_url, adherent.genre)}
                  alt={`${adherent.nom} ${adherent.prenom}`}
                  className="object-cover w-full h-full rounded-t-lg"
                />
              </div>
              <div className="p-2 text-center dark:text-gray-800 h-10 flex items-center justify-center">
                <p className="text-sm font-medium">
                  {adherent.nom} {adherent.prenom}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <ModalFicheAdherent onClose={closeModal}>
          <AdherentDetails adherentId={selectedAdherent.id} />
          <AdherentGroupCategory adherentId={selectedAdherent.id} />
          <AdherentPassageCeinture adherentId={selectedAdherent.id} />
          <AdherentPalmares adherentId={selectedAdherent.id} />
        </ModalFicheAdherent>
      )}
    </div>
  );
};

export default LatestMembers;
