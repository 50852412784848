import React, { useState, useEffect } from 'react';
import { FaCreditCard, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { getSettings } from "../../../services/settingsServices";

const PaymentPlanModal = ({ articles, onClose, onPayer, selectedItems, setSelectedItems }) => {
  const [total, setTotal] = useState(0);
  const [paymentPlan, setPaymentPlan] = useState("One-time");
  const [maxPayments, setMaxPayments] = useState(1);
  const [paymentInterval, setPaymentInterval] = useState(1);
  const [defaultPhotoUrl, setDefaultPhotoUrl] = useState(""); // Ajouter l'état pour la photo par défaut

  const toggleItem = (itemId) => {
    const isSelected = selectedItems.includes(itemId);
    const newSelectedItems = isSelected
      ? selectedItems.filter(id => id !== itemId)
      : [...selectedItems, itemId];
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();
        const defaultPhoto = settings.find(setting => setting.key === "photo_url_default_lic_cot");
        setDefaultPhotoUrl(defaultPhoto ? defaultPhoto.value : "");

        if (settings && settings.length > 0) {
          setMaxPayments(settings[0].max_payments);
          setPaymentInterval(settings[0].payment_interval);
          setDefaultPhotoUrl(settings[0].photo_url_default_lic_cot); // Définir photo_url_default_lic_cot
        } else {
          console.error("max_payments, payment_interval or photo_url_default_lic_cot not found in settings");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const newTotal = selectedItems.reduce((acc, itemId) => {
      const article = articles.find(article => article.id === itemId);
      return acc + (article ? parseFloat(article.prix_total) : 0);
    }, 0);
    setTotal(newTotal);
  }, [selectedItems, articles]);

  const renderPaymentOptions = () => {
    const options = [];
    options.push({ key: "One-time", value: "One-time", label: "1x" });
    if (maxPayments >= 2) options.push({ key: "Two-times", value: "Two-times", label: "2x" });
    if (maxPayments >= 3) options.push({ key: "Three-times", value: "Three-times", label: "3x" });
    if (maxPayments >= 4) options.push({ key: "Four-times", value: "Four-times", label: "4x" });
    if (maxPayments >= 5) options.push({ key: "Five-times", value: "Five-times", label: "5x" });
    return options;
  };

  const handlePayment = async () => {
    const itemsToSend = selectedItems.map(itemId => {
      const article = articles.find(article => article.id === itemId);
      return {
        id: article.id,
        name: article.Article ? article.Article.nom : "Règlement Inscription",
        price: parseFloat(article.prix_total),
        quantity: article.quantite
      };
    });

    const dataToSend = {
      items: itemsToSend,
      paymentPlan
    };

    console.log("Data sent to create checkout session:", dataToSend);
    await onPayer(dataToSend);
  };

  const calculateInstallments = () => {
    const paymentPlanMap = {
      "One-time": 1,
      "Two-times": 2,
      "Three-times": 3,
      "Four-times": 4,
      "Five-times": 5,
    };

    const paymentCount = paymentPlanMap[paymentPlan];
    if (!paymentCount || total === 0) return "";

    const installmentAmount = (total / paymentCount).toFixed(2);
    return paymentPlan === "One-time"
      ? `Paiement unique de ${installmentAmount}€`
      : `${paymentCount} paiements de ${installmentAmount}€, espacés de ${paymentInterval} mois`;
  };

  return (
    <div className="bg-white dark:bg-gray-400 p-4 rounded-lg shadow-lg max-w-[800px] mx-auto font-montserrat">
      <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Règlement des Inscriptions</h3>
      <p className='font-montserrat text-sm text-center mb-2'>Sélectionnez le ou les inscriptions à régler</p>
      <div className="max-w-[800px] ">
        <table className="min-w-full divide-y divide-gray-200 font-montserrat">
          <thead>
            <tr className='font-montserrat'>
              <th className="px-4 py-2"></th>
              <th className="px-4 py-2">Article(s)</th>
              <th className="px-4 py-2">Prix Total</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-200">
            {articles.map((article) => (
              <tr key={article.id} className="mt-2">
                <td className="px-4 py-1">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(article.id)}
                    onChange={() => toggleItem(article.id)}
                    className="form-checkbox"
                  />
                </td>
                <td className="-ml-2 py-1 flex items-center text-sm">
                  <img
                    src={defaultPhotoUrl}
                    alt="Règlement Inscription"
                    className="w-16 h-16 object-cover mr-2 rounded-full"
                    onError={(e) => e.target.src = defaultPhotoUrl}
                  />
                  {`${article.quantite} x ${article.Article ? article.Article.nom : "Règlement Inscription"}`}
                </td>
                <td className="px-4 pt-5 mt-2 text-sm">{`${article.prix_total} €`}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="bg-gray-100 dark:bg-gray-800">
              <td className="text-right px-4 py-2 dark:bg-gray-800" colSpan="2"><strong>Total à régler :</strong></td>
              <td className="px-4 py-2 dark:bg-gray-800"><strong>{`${total.toFixed(2)} €`}</strong></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="mt-4">
        <label htmlFor="payment-plan-select" className="block text-sm font-medium text-gray-700">Plan de Paiement :</label>
        <div className="flex space-x-2 mt-2">
          {renderPaymentOptions().map(option => (
            <button
              key={option.key}
              className={`py-2 px-4 rounded-full ${paymentPlan === option.value ? 'bg-primary text-white' : 'bg-gray-300 text-gray-700'}`}
              onClick={() => setPaymentPlan(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
      {selectedItems.length > 0 && (
        <p className="mt-4 text-center font-semibold">
          {calculateInstallments()}
        </p>
      )}
      <div className="flex justify-center mt-4 gap-4">
        <Button text="Payer" icon={FaCreditCard} onClick={handlePayment} className="ml-4 gap-2" />
        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="ml-4 gap-2" />
      </div>
    </div>
  );
};

export default PaymentPlanModal;
