import React, { useState, useEffect } from "react";
import { getReglementInscriptionByIdAndSaison } from "../../../services/reglementinscriptionServices";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const ReglementInscription = ({ adherentId, saison }) => {
  const [reglement, setReglement] = useState(null);

  useEffect(() => {
    const fetchReglement = async () => {
      const data = await getReglementInscriptionByIdAndSaison(adherentId, saison);
      setReglement(data);
    };

    fetchReglement();
  }, [adherentId, saison]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Règlement d'Inscription</h3>
      {reglement ? (
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2">Champ</th>
              <th className="py-2 px-4 border-b-2">Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Saison</td>
              <td className="py-2 px-4 border-b">{reglement.saison}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Montant Licence Assurance</td>
              <td className="py-2 px-4 border-b">{reglement.montant_lic_assurance} €</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Montant Cotisation</td>
              <td className="py-2 px-4 border-b">{reglement.montant_cotisation} €</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Réduction Foyer</td>
              <td className="py-2 px-4 border-b">{reglement.reduction_foyer} €</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Pass Sport</td>
              <td className="py-2 px-4 border-b">{reglement.pass_sport} €</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Réduction Saison</td>
              <td className="py-2 px-4 border-b">{reglement.reduction_saison} €</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Type de Règlement</td>
              <td className="py-2 px-4 border-b">{reglement.type_reglement}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Nombre de Règlements</td>
              <td className="py-2 px-4 border-b">{reglement.nb_reglement}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Statut du Règlement</td>
              <td className="py-2 px-4 border-b">{reglement.statut_reglement}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Observations</td>
              <td className="py-2 px-4 border-b">{reglement.observations}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p>Chargement des données...</p>
      )}
    </div>
  );
};

export default ReglementInscription;
