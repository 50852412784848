import React from "react";
import { FaBirthdayCake, FaBell } from "react-icons/fa";
import { sendNotification } from "../../../services/notificationServices";
import { getUserIdByAdherentId } from "../../../services/adherentServices";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";

const BirthdaySection = ({ birthdayAdherents, formatDate, calculateAge, getDefaultPhoto, token }) => {
  const handleSendNotification = async (adherent) => {
    const message = `Nous souhaitons un joyeux anniversaire à ${adherent.prenom} 🎂`;
    try {
      const users = await getUserIdByAdherentId(adherent.id);
      for (const user of users) {
        await sendNotification(message, user.UserAdherent.user_id, token);
      }
      toast.success("Notification envoyée avec succès");
    } catch (error) {
      console.error("Erreur lors de l'envoi de la notification:", error);
      toast.error("Erreur lors de l'envoi de la notification");
    }
  };

  return (
    <div className="birthday-section my-4 bg-blue-200 rounded-md p-4 font-montserrat">
      <div className="flex gap-2 justify-start">
        <FaBirthdayCake className="text-2xl text-blue-800" />
        <h3 className="text-md font-semibold dark:text-gray-800">
          Anniversaires : {formatDate()}
        </h3>
      </div>
      <div className="flex flex-wrap">
        {birthdayAdherents.map((adherent) => (
          <div
            key={adherent.id}
            className="p-2 m-2 border rounded bg-white dark:bg-gray-600 dark:border-gray-800 aspect-square"
          >
            <img
              src={adherent.photo_url || getDefaultPhoto(adherent.genre)}
              alt={`${adherent.nom} ${adherent.prenom}`}
              className="w-20 h-20 rounded-full object-cover mx-auto"
            />
            <div className="flex flex-col items-center">
              <p className="text-center font-medium text-sm">
                {adherent.nom}
              </p>
              <p className="text-center text-sm">{adherent.prenom}</p>
              <p className="text-xs text-gray-600 text-center italic dark:text-slate-200 mb-2">
                ({calculateAge(adherent.date_naissance)} ans)
              </p>
              <Button
                text=""
                onClick={() => handleSendNotification(adherent)}
                icon={FaBell}
                hoverLabel="Envoyer une notification"
                className="text-xs"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BirthdaySection;
