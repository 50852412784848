import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/group`;
const token = localStorage.getItem("token");

// Fonction pour récupérer les groupes pour GroupeTable
export const getGroupsHome = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allgroupsHome`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des groupes:", error);
  }
};

// Fonction pour récupérer le nombre de groupes pour HeaderCount
export const getGroupsCount = async () => {
  try {
    const response = await axios.get(`${apiUrl}/groupCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement du nombre de groupes:", error);
  }
};

// Fonction pour récupérer tous les groupes (nom + id) pour le formulaire d'envoi de messages groupés
export const getAllGroupsMessage = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allgroupsMessage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des groupes:", error);
  }
};

// Fonction pour récupérer le nom du groupe d'un adhérent
export const getGroupName = async (adherentId) => {
  try {
    const response = await axios.get(`${apiUrl}/findGroup/${adherentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Modifiez ici pour accéder directement à `groupName` dans la réponse de l'API
    return response.data.groupNames ? response.data.groupNames : [];
  } catch (error) {
    console.error("Erreur lors du chargement du nom du groupe:", error);
    return null; // Retourne null en cas d'erreur pour traiter proprement dans le composant
  }
};

// Fonction pour récupérer la liste des groupes et le nombre d'adhérents par groupe
export const getGroupsList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/listGroup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des groupes:", error);
  }
};

// Fonction pour récupérer la liste des groupes actifs et le nombre d'adhérents par groupe
export const getActiveGroupsList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/listGroupActive`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des groupes actifs:", error);
  }
};

/// POST
// Fonction pour créer un groupe
export const createGroup = async (group) => {
  try {
    const response = await axios.post(`${apiUrl}/addGroup`, group, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du groupe:", error);
  }
};

/// PUT
// Fonction pour modifier un groupe
export const updateGroup = async (group) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateGroup/${group.id}`,
      group,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du groupe:", error);
  }
};

//Fonction pour modifier l'ordre d'un groupe
export const updateOrderGroup = async (groups) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateOrderGroup`,
      { groups },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'ordre des groupes:",
      error
    );
  }
};

// Fonction pour activer ou désactiver un groupe
export const activateGroup = async (groupId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/activateGroup/${groupId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du groupe:", error);
  }
};

/// DELETE
// Fonction pour supprimer un groupe
export const deleteGroup = async (groupId) => {
  try {
    const response = await axios.delete(`${apiUrl}/removeGroup/${groupId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du groupe:", error);
  }
};
