import React, { useState, useEffect } from 'react';
import { FaCreditCard, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { getSettings } from "../../../services/settingsServices"; // Importer la fonction getSettings

const PaymentModal = ({ articles, onClose, onPayer, selectedItems, setSelectedItems }) => {
  const [total, setTotal] = useState(0);
  const [defaultPhotoUrl, setDefaultPhotoUrl] = useState(""); // Ajouter l'état pour la photo par défaut

  const toggleItem = (itemId) => {
    const isSelected = selectedItems.includes(itemId);
    const newSelectedItems = isSelected
      ? selectedItems.filter(id => id !== itemId)
      : [...selectedItems, itemId];
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    const newTotal = selectedItems.reduce((acc, itemId) => {
      const article = articles.find(article => article.id === itemId);
      return acc + (article ? parseFloat(article.prix_total) : 0);
    }, 0);
    setTotal(newTotal);
  }, [selectedItems, articles]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        const defaultPhoto = settingsData.find(setting => setting.key === "photo_url_default_lic_cot");
        setDefaultPhotoUrl(defaultPhoto ? defaultPhoto.value : "");
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres", error);
      }
    };

    fetchSettings();
  }, []);

  const filteredArticles = articles.filter(article => 
    article.Article && article.Article.nom !== "Règlement Inscription"
  );

  return (
    <div className="bg-white dark:bg-gray-400 p-4 rounded-lg shadow-lg max-w-[800px] mx-auto font-montserrat">
      <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Règlement des Articles</h3>
      <p className='font-montserrat text-sm text-center mb-2'>Sélectionnez le ou les articles à régler</p>
      <div className="max-w-[800px] ">
        <table className="min-w-full divide-y divide-gray-200 font-montserrat">
          <thead>
            <tr className='font-montserrat'>
              <th className="px-4 py-2"></th>
              <th className="px-4 py-2">Article(s)</th>
              <th className="px-4 py-2">Prix Total</th>
            </tr>
          </thead>
          <tbody>
            {filteredArticles.map(article => (
              <tr key={article.id} className="bg-white dark:bg-gray-400">
                <td className="px-2 py-5">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(article.id)}
                      onChange={() => toggleItem(article.id)}
                      className='form-checkbox h-5 w-5 text-primary-600'
                    />
                  </div>
                </td>
                <td className="px-2 py-1 flex items-center text-sm">
                  <img
                    src={article.Article ? article.Article.photo_url || defaultPhotoUrl : defaultPhotoUrl}
                    alt={article.Article ? article.Article.nom || "Image non disponible" : "Image non disponible"}
                    className="w-16 h-16 object-cover mr-2 rounded-full"
                    onError={(e) => e.target.src = defaultPhotoUrl}
                  />
                  {`${article.quantite} x ${article.Article ? article.Article.nom || "Règlement Inscription" : "Règlement Inscription"}`}
                </td>
                <td className="px-4 pt-5 mt-2 text-sm">{`${article.prix_total} €`}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="bg-gray-100 dark:bg-gray-800">
              <td className="text-right px-4 py-2 dark:bg-gray-800" colSpan="2">Total à payer:</td>
              <td className="px-4 py-2 font-semibold dark:bg-gray-800">{`${total.toFixed(2)} €`}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="flex justify-center mt-4 gap-4">
        <Button text="Payer" icon={FaCreditCard} onClick={onPayer} className="ml-4 gap-2" />
        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="ml-4 gap-2" />
      </div>
    </div>
  );
};

export default PaymentModal;
