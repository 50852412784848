import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { updateInventaire } from '../../../services/inventaireServices';
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';

const EditInventaireModal = ({ isOpen, onClose, refreshList, inventaire }) => {
  const [formData, setFormData] = useState({
    categorie: '',
    nom: '',
    stock: '',
    note: '',
    photo_url: '',
  });

  useEffect(() => {
    if (inventaire) {
      setFormData({
        categorie: inventaire.categorie || '',
        nom: inventaire.nom || '',
        stock: inventaire.stock || '',
        note: inventaire.note || '',
        photo_url: inventaire.photo_url || '',
      });
    }
  }, [inventaire]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateInventaire(inventaire.id, formData);
      refreshList();
      onClose();
      toast.success('Inventaire mis à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'inventaire", error);
      toast.error("Erreur lors de la mise à jour de l'inventaire");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier le Stock
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="categorie" placeholder="Catégorie" value={formData.categorie} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="nom" placeholder="Nom" value={formData.nom} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="number" name="stock" placeholder="Stock" value={formData.stock} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="note" placeholder="Note" value={formData.note} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="photo_url" placeholder="URL de la Photo" value={formData.photo_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditInventaireModal;
