import React, { useEffect, useState } from 'react';
import { getGroupName } from '../../../services/groupServices';
import getCategory from "../../../utils/getCategory";
import getWeightCategory from "../../../utils/getWeightCategory";
import { getAdherentById } from '../../../services/adherentServices';
import { getLicenceAssuranceUrl } from '../../../services/dossierServices';

function AdherentGroupCategory({ adherentId }) {
  const [groupNames, setGroupNames] = useState(['Chargement...']);
  const [weightCategory, setWeightCategory] = useState('');
  const [ageCategory, setAgeCategory] = useState('');
  const [adherent, setAdherent] = useState({});
  const [licenceUrl, setLicenceUrl] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (adherentId) {
        const adherentData = await getAdherentById(adherentId);
        setAdherent(adherentData);

        const groupNames = await getGroupName(adherentId);
        console.log('Group Names Received:', groupNames); 
        setGroupNames(groupNames.length > 0 ? groupNames : ['N/A']);

        const currentAgeCategory = getCategory(adherentData.date_naissance);
        setAgeCategory(currentAgeCategory);

        const currentWeightCategory = getWeightCategory(adherentData.genre, currentAgeCategory, adherentData.poids);
        setWeightCategory(currentWeightCategory);

        const licenceLink = await getLicenceAssuranceUrl(adherentId);
        setLicenceUrl(licenceLink);
      }
    }

    fetchData();
  }, [adherentId]);

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-2 pb-2 shadow-lg max-w-[800px] mx-auto font-montserrat">
      <div className='bg-gray-300 dark:bg-gray-600 p-4 rounded'>
        <h3 className="text-2xl font-light uppercase mb-4">Groupe & Catégories</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-800 text-sm">
          <p className='dark:text-gray-200'><strong className='dark:text-gray-200'>Saison Active :</strong> {adherent.saison_active || 'N/A'}</p>
          <p className='dark:text-gray-200'><strong className='dark:text-gray-200'>Groupes :</strong> {groupNames.join(", ")}</p>
          <p className='dark:text-gray-200'><strong className='dark:text-gray-200'>N° Licence :</strong> {adherent.licence_number || 'N/A'}</p>
          <p className='dark:text-gray-200'><strong className='dark:text-gray-200'>Lien Licence :</strong> {licenceUrl ? <a href={licenceUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 dark:text-primary">Lien Licence</a> : 'N/A'}</p>
          <p className='dark:text-gray-200'><strong className='dark:text-gray-200'>Catégorie de poids :</strong> {weightCategory}</p>
          <p className='dark:text-gray-200'><strong className='dark:text-gray-200'>Catégorie d'âge :</strong> {ageCategory}</p>
        </div>
      </div>
    </div>
  );
}

export default AdherentGroupCategory;
