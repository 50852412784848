import React, { useState, useEffect } from "react";
import EditDossierModal from "./EditDossierModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getSettings } from "../../../services/settingsServices";
import { getReglementInscriptionByIdAndSaison } from "../../../services/reglementinscriptionServices";
import {
  FaUserCircle,
  FaUsers,
  FaFileMedical,
  FaFileSignature,
  FaEuroSign,
  FaGoogleDrive,
  FaCalendar,
  FaBell,
  FaRedo,
  FaFilePdf,
} from "react-icons/fa";
import { sendNotificationToAdmin } from "./../../../services/notificationServices";
import RenewalModal from "./RenewalModal";
import { getGroupsList } from "./../../../services/groupServices";

const AdherentCard = ({ adherent, dossier, user, refreshAdherents }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [autorisationRenouvellement, setAutorisationRenouvellement] =
    useState(false);
  const [activeSeason, setActiveSeason] = useState("");
  const [isRenewalModalOpen, setRenewalModalOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isDossierComplete, setIsDossierComplete] = useState(false);
  const [settings, setSettings] = useState(null);
  const [reglement, setReglement] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
        setAutorisationRenouvellement(data[0].autorisation_renouvellement);
        setActiveSeason(data[0].saison_active);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsData = await getGroupsList();
      setGroups(groupsData);
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    if (dossier) {
      setIsDossierComplete(
        dossier.validate_cm &&
          dossier.validate_licence &&
          dossier.validate_reglement
      );
    }
  }, [dossier]);

  useEffect(() => {
    const fetchReglement = async () => {
      if (isDossierComplete) {
        try {
          const reglementData = await getReglementInscriptionByIdAndSaison(
            adherent.id,
            activeSeason
          );
          setReglement(reglementData);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des règlements:",
            error
          );
        }
      }
    };

    if (activeSeason && isDossierComplete) {
      fetchReglement();
    }
  }, [adherent.id, activeSeason, isDossierComplete]);

  const adherentNextSeason = adherent.saison_active
    ? `${parseInt(adherent.saison_active.split("-")[0]) + 1}-${
        parseInt(adherent.saison_active.split("-")[1]) + 1
      }`
    : "";

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const openRenewalModal = () => setRenewalModalOpen(true);
  const closeRenewalModal = async (updated, selectedGroup) => {
    setRenewalModalOpen(false);
    if (updated) {
      await sendRenewalNotification(
        adherent,
        user,
        selectedGroup,
        adherentNextSeason
      );
      refreshAdherents();
    }
  };

  const handleDriveClick = () => {
    if (dossier && dossier.lien_drive) {
      window.open(dossier.lien_drive, "_blank");
    }
  };

  const handleNotifyClick = async () => {
    if (dossier && dossier.lien_drive) {
      const notificationAdmin = `${user.prenom} ${user.nom} a déposé des documents pour ${adherent.prenom} ${adherent.nom} dans Google Drive.`;
      try {
        await sendNotificationToAdmin(notificationAdmin, user.token);
        toast.success("Notification envoyée aux administrateurs.");
      } catch (error) {
        console.error("Erreur lors de l'envoi de la notification:", error);
        toast.error("Erreur lors de l'envoi de la notification.");
      }
    }
  };

  const sendRenewalNotification = async (adherent, user, group, season) => {
    const notificationAdmin = `${user.prenom} ${user.nom} vient de renouveler l'adhésion de ${adherent.prenom} ${adherent.nom} pour la saison ${season} dans le groupe ${group.nom}.`;
    try {
      await sendNotificationToAdmin(notificationAdmin, user.token);
      toast.success(
        "Notification de renouvellement envoyée aux administrateurs."
      );
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi de la notification de renouvellement:",
        error
      );
      toast.error(
        "Erreur lors de l'envoi de la notification de renouvellement."
      );
    }
  };

  const handleAttestationClick = () => {
    if (!settings || !reglement) {
      toast.error(
        "Les données du club ou de règlement ne sont pas disponibles."
      );
      return;
    }

    navigate("/attestation", {
      state: {
        prenom: adherent.prenom,
        nom: adherent.nom,
        dateNaissance: new Date(adherent.date_naissance).toLocaleDateString(),
        adresse: adherent.adresse,
        cp: adherent.cp,
        commune: adherent.commune,
        cotisation: reglement.montant_cotisation,
        licence: reglement.montant_lic_assurance,
        reductionFoyer: reglement.reduction_foyer,
        passSport: reglement.pass_sport,
        reductionSaison: reglement.reduction_saison,
        typeReglement: reglement.type_reglement,
        nbReglement: reglement.nb_reglement,
        statutReglement: reglement.statut_reglement,
        observations: reglement.observations,
        datePaiement: new Date().toLocaleDateString(),
        montant:
          parseFloat(reglement.montant_cotisation) +
          parseFloat(reglement.montant_lic_assurance),
        president: settings.responsable_club,
        association: settings.nom_club,
        logo: settings.logo_club,
        adresse_club: settings.adresse_club,
        tel_club: settings.tel_club,
        mail_club: settings.mail_club,
        siteweb_club: settings.siteweb_club,
        siret_club: settings.siret_club,
        saison_active: activeSeason,
      },
    });
  };

  const defaultImageUrl =
    adherent.genre === "Masculin"
      ? "/img/judoka_avatar.png"
      : "/img/judokate_avatar.png";

  const getAdjustedStatus = (status, genre) => {
    if (genre === "Féminin") {
      switch (status) {
        case "Licencié":
          return "Licenciée";
        case "Inscrit":
          return "Inscrite";
        default:
          return status;
      }
    }
    return status;
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Licencié":
      case "Licenciée":
        return "bg-green-600";
      case "Inscrit":
      case "Inscrite":
        return "bg-yellow-600";
      case "Cours d'essai":
        return "bg-blue-600";
      case "Licencié - Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié et Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié":
        return "bg-slate-400";
      default:
        return "bg-gray-600";
    }
  };

  if (!adherent) return <div>Loading adherent details...</div>;
  if (!dossier)
    return (
      <div>
        Pas de Dossier d'inscription pour {adherent.nom} {adherent.prenom}
      </div>
    );

  const getValidationStatus = () => {
    if (!dossier) return null;
    return dossier.validate_cm &&
      dossier.validate_licence &&
      dossier.validate_reglement
      ? { text: "Complet", bgColor: "bg-green-600" }
      : { text: "Incomplet", bgColor: "bg-gray-600" };
  };

  const validationStatus = getValidationStatus();

  if (!validationStatus) return <div>Loading...</div>;

  const shouldShowRenewalButton =
    autorisationRenouvellement && adherent.saison_active !== activeSeason;

  return (
    <div className="flex flex-col shadow-md border bg-base-100 dark:bg-gray-300 rounded-lg overflow-hidden h-full">
      <div className="relative">
        <img
          src={adherent.photo_url || defaultImageUrl}
          alt={`${adherent.nom} ${adherent.prenom}`}
          className="object-cover object-center w-full h-64 lg:h-80"
        />
        <div
          className={`absolute bottom-0 left-0 ${validationStatus.bgColor} py-1 px-3`}
        >
          <span className="text-lg ml-1 font-bold text-white block">
            {validationStatus.text}
          </span>
        </div>
      </div>
      <div className="flex-grow p-4">
        <h3 className="dark:text-black font-bold uppercase text-lg mb-2">{`${adherent.nom} ${adherent.prenom}`}</h3>
        <span
          className={`badge ${getStatusBadgeClass(
            adherent.statut_inscription
          )} text-white`}
        >
          {getAdjustedStatus(adherent.statut_inscription, adherent.genre)}
        </span>
        <div className="mt-3 flex items-center text-sm text-gray-600">
          <FaCalendar className="mr-2" /> Saison Active:{" "}
          {adherent.saison_active}
        </div>
        <div className="flex items-center text-sm text-gray-600">
          <FaUserCircle className="mr-2" /> Licence: {adherent.licence_number}
        </div>
        <div className="flex items-center text-sm text-gray-600">
          <FaUsers className="mr-2" /> Inscrit(e) le :{" "}
          {new Date(adherent.createdAt).toLocaleDateString()}
        </div>
        <div className="flex items-center text-sm text-gray-600">
          <FaCalendar className="mr-2" /> Date dernier Certificat Médical :{" "}
          {dossier.date_cm
            ? new Date(dossier.date_cm).toLocaleDateString()
            : "Non renseignée"}
        </div>
        <div className="flex items-center text-sm text-gray-600">
          <FaFileSignature className="mr-2" />
          Formulaire de licence à signer &gt;{" "}
          {dossier.formulaire_licence_a_signer ? (
            <a
              href={dossier.formulaire_licence_a_signer}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-400 text-white px-3 py-1 rounded-md hover:bg-blue-500"
            >
              Télécharger
            </a>
          ) : (
            "Non disponible"
          )}
        </div>

        <div className="mt-3 grid grid-cols-3 gap-4">
          {dossier && <ValidationStatus dossier={dossier} />}
        </div>
      </div>
      <div className="mx-auto w-full flex flex-col items-center gap-2">
        <button
          onClick={handleDriveClick}
          className={`flex items-center justify-center w-full text-white font-medium text-sm px-4 py-2 ${
            dossier && dossier.lien_drive
              ? "bg-primary hover:bg-gray-800"
              : "bg-gray-400 cursor-not-allowed"
          }`}
          disabled={!(dossier && dossier.lien_drive)}
        >
          <FaGoogleDrive className="mr-2 text-xl" />
          Déposer les documents dans Google Drive
        </button>

        <button
          onClick={handleNotifyClick}
          className={`flex items-center justify-center w-full text-white font-medium text-sm px-4 py-2 ${
            dossier && dossier.lien_drive
              ? "bg-primary hover:bg-gray-800"
              : "bg-gray-400 cursor-not-allowed"
          }`}
          disabled={!(dossier && dossier.lien_drive)}
        >
          <FaBell className="mr-2 text-xl" />
          Signalez que vous avez déposé des documents
        </button>

        {shouldShowRenewalButton && (
          <button
            onClick={openRenewalModal}
            className="flex items-center justify-center w-full text-white font-medium text-sm px-4 py-2 bg-blue-600 hover:bg-blue-800"
          >
            <FaRedo className="mr-2 text-xl" />
            Renouvellement de l'adhésion
          </button>
        )}

        <button
          onClick={handleAttestationClick}
          className={`flex items-center justify-center w-full text-white font-medium text-sm px-4 py-2 ${
            validationStatus.text === "Complet"
              ? "bg-green-600 hover:bg-green-800"
              : "bg-gray-400 cursor-not-allowed"
          }`}
          disabled={validationStatus.text !== "Complet"}
        >
          <FaFilePdf className="mr-2 text-xl" />
          Générer une attestation d'inscription
        </button>

        <RenewalModal
          isOpen={isRenewalModalOpen}
          onClose={closeRenewalModal}
          adherent={adherent}
          groups={groups}
          nextSeason={adherentNextSeason}
          user={user}
        />

        <EditDossierModal
          isOpen={isModalOpen}
          onClose={closeModal}
          dossier={dossier}
          refreshDossiers={() => {
            /* logic to refresh dossiers here */
          }}
        />
      </div>
    </div>
  );
};

const ValidationStatus = ({ dossier }) => (
  <>
    <ValidationItem
      condition={dossier.validate_cm}
      icon={<FaFileMedical className="text-white text-xl" />}
      label="C. Méd."
    />
    <ValidationItem
      condition={dossier.validate_licence}
      icon={<FaFileSignature className="text-white text-xl" />}
      label="Licence"
    />
    <ValidationItem
      condition={dossier.validate_reglement}
      icon={<FaEuroSign className="text-white text-xl" />}
      label="Règlement"
    />
  </>
);

const ValidationItem = ({ condition, icon, label }) => (
  <div
    className={`flex items-center justify-center rounded-lg p-2 ${
      condition ? "bg-green-600" : "bg-gray-600"
    }`}
  >
    {icon}
    <span className="ml-2 text-white">{label}</span>
  </div>
);

export default AdherentCard;
