import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getAdherentsByUser } from "../../../services/adherentServices";
import { getEventsByAdherent } from "../../../services/eventServices";
import { FaInfoCircle, FaTrophy, FaCalendarCheck } from "react-icons/fa";
import { IoCalendarNumberSharp } from "react-icons/io5";
import EventDetailsModal from "./EventDetailsModal";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";

const CompetitionsDisplay = ({ userId }) => {
  const [adherentsCompetitions, setAdherentsCompetitions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalEvent, setModalEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompetitionsData = async () => {
      try {
        const adherents = await getAdherentsByUser(userId);
        let competitionsData = [];

        for (const adherent of adherents) {
          const response = await getEventsByAdherent(adherent.id);
          const competitions = response.Events
            ? response.Events.map((event) => ({
                ...event,
                adherentNom: `${adherent.nom} ${adherent.prenom}`,
                adherentPhoto: adherent.photo_url,
                resultat: event.AdherentEvent.resultats,
                classement: event.AdherentEvent.classement,
                observations: event.AdherentEvent.observations,
              }))
            : [];
          competitionsData = [...competitionsData, ...competitions];
        }

        competitionsData.sort((a, b) => new Date(a.start) - new Date(b.start)); // Tri par date
        setAdherentsCompetitions(competitionsData);
      } catch (error) {
        console.error("Error loading competitions or adherents:", error);
        toast.error("Erreur lors du chargement des compétitions");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompetitionsData();
  }, [userId]);

  const handleOpenModal = (event) => {
    setModalEvent(event);
  };

  const handleCloseModal = () => {
    setModalEvent(null);
  };

  if (isLoading) {
    return <p>Chargement des compétitions...</p>;
  }

  return (
    <div className="container mx-auto px-4 py-2">
      <div className="flex justify-between mb-2">
        <h3 className="card-title mb-2 ml-3 ">
          <FaCalendarCheck className="icon" />
          Inscriptions & Résultats
        </h3>
        <Button
          onClick={() => navigate("/calendrier")}
          icon={IoCalendarNumberSharp}
          className="bg-primary text-white mb-2"
          hoverLabel="Voir Calendrier des Compétitions"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        {adherentsCompetitions.map((competition) => (
          <div
            key={competition.id}
            className="bg-white dark:bg-slate-400 shadow-lg rounded-lg overflow-hidden flex"
          >
            <div className="w-1/2">
              <img
                src={competition.photo_url}
                alt={competition.titre}
                className="object-cover h-full w-full"
              />
              <div className=" text-black px-3 py-1 dark:text-white">
                {new Date(competition.start).toLocaleDateString("fr-FR", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </div>
            </div>
            <div className="w-1/2 p-4 flex flex-col justify-between">
              <div className="flex justify-between items-center">
                <img
                  src={competition.adherentPhoto}
                  alt="Adhérent"
                  className="w-10 h-10 rounded-full"
                />
                <h3 className="ml-2 text-sm font-semibold font-montserrat">
                  {competition.titre}
                </h3>
              </div>
              {/* Date formatée français*/}
              <div className="text-xs text-gray-600 dark:text-white">
                {new Date(competition.start).toLocaleDateString("fr-FR", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </div>
              {competition.resultat ||
              competition.classement ||
              competition.observations ? (
                <button
                  onClick={() => handleOpenModal(competition)}
                  className="flex items-center justify-center space-x-2 bg-green-600 text-white px-4 py-1 rounded-full text-sm"
                >
                  <FaTrophy />
                  <span>Info & Résultats</span>
                </button>
              ) : (
                <button
                  onClick={() => handleOpenModal(competition)}
                  className="flex items-center justify-center space-x-2 bg-blue-600 text-white px-4 py-1 rounded-full text-sm"
                >
                  <FaInfoCircle />
                  <span>Info</span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {modalEvent && (
        <EventDetailsModal
          event={modalEvent}
          isOpen={!!modalEvent}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default CompetitionsDisplay;
