import React, { useEffect } from "react";
import { FaTrash, FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { toast } from "react-toastify";
import { createReglement } from "../../../services/achatsServices";
import Button from "../../../components/Button/Button";
import { sendNotification } from "./../../../services/notificationServices";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import { getAdminsIds } from "./../../../services/userServices";

function CartModal({
  cart,
  setCart,
  closeModal,
  updateQuantity,
  removeFromCart,
  isModalOpen,
}) {
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const isAdminOrInstructor = [1, 3].includes(user?.role_id);

  // Ajoutez un console.log pour vérifier les articles du panier
  useEffect(() => {
    console.log(cart);
  }, [cart]);

  // Calcul du total général
  const totalPrice = cart.reduce(
    (acc, item) => acc + item.prix * item.quantity,
    0
  );

  const handleQuantityChange = (id, increment) => {
    const newQuantity =
      cart.find((item) => item.id === id).quantity + increment;

    if (newQuantity >= 1) {
      updateQuantity(id, newQuantity);
    }
  };

  // Fonction pour passer la commande
  const handleOrder = async () => {
    if (cart.length === 0) {
      toast.warn("Pas d'articles dans le panier.");
      return;
    }

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const user_achat_id = userInfo.userId;
    const date_achat = new Date().toISOString();

    try {
      const orderDetails = [];

      for (const item of cart) {
        const reglementData = {
          user_achat_id,
          article_id: item.id,
          quantite: item.quantity,
          prix_total: item.prix * item.quantity,
          date_achat,
          message_achat: item.message || "",
          statut_reglement: "Non Réglé",
          type_reglement: "En ligne",
        };
        await createReglement(reglementData);
        orderDetails.push(
          `${item.quantity}x ${item.nom} (€${item.prix.toFixed(2)} chacun)`
        );
      }

      // Message pour l'utilisateur
      const userMessage = `Vous avez commandé les articles suivants à la boutique : ${orderDetails.join(
        ", "
      )}.`;
      await sendNotification(userMessage, user_achat_id, userInfo.token);

      // Récupération des IDs des administrateurs seulement
      const adminIds = await getAdminsIds(); // Adaptez cette ligne si nécessaire pour obtenir seulement les admin IDs
      const adminMessage = `${userInfo.nom} ${
        userInfo.prenom
      } a commandé les articles suivants à la boutique: ${orderDetails.join(
        ", "
      )}.`;
      adminIds.forEach(async (adminId) => {
        if (adminId.role_id === 1) {
          // Assurez-vous que la condition de filtrage est correcte
          try {
            await sendNotification(adminMessage, adminId.id, userInfo.token);
          } catch (error) {
            console.error(
              "Erreur lors de l'envoi de la notification pour l'ID:",
              adminId,
              error
            );
          }
        }
      });

      toast.success("Commande passée avec succès !");
      closeModal();
      setCart([]);
    } catch (error) {
      console.error("Erreur lors de la commande :", error);
      toast.error("Erreur lors de la commande.");
    }
  };

  const handleMessageChange = (e, index) => {
    // Assurez-vous que 'index' est un paramètre ici
    const newMessage = e.target.value;
    setCart((currentItems) =>
      currentItems.map(
        (
          item,
          idx // 'idx' est une référence locale à l'index dans 'map'
        ) => (idx === index ? { ...item, message: newMessage } : item)
      )
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 font-montserrat">
      <div className="modal-box bg-blue-100 dark:bg-gray-600 p-4 rounded-lg shadow-lg max-w-3xl w-full">
        <h3 className="font-bold text-lg">
          <FaShoppingCart className="inline-block mr-2" />
          Votre Panier
        </h3>
        <button
          onClick={closeModal}
          className="btn btn-sm btn-circle btn-error absolute right-2 top-2"
        >
          ✕
        </button>
        <div className="overflow-x-auto mt-4">
          {cart.map((item, index) => (
            <div
              key={item.id}
              className="bg-white dark:bg-gray-400 shadow-md rounded-lg mb-4 p-4 flex flex-col md:flex-row items-center"
            >
              <img
                src={item.photo_url}
                alt={item.nom}
                className="w-36 h-48 object-cover rounded-md md:mr-4"
              />
              <div className="flex-1 text-center md:text-left mt-4 md:mt-0">
                <p className="text-lg font-semibold">{item.nom}</p>
                <p className="text-sm text-gray-500 dark:text-slate-200">{item.description}</p>
                <div className="flex items-center justify-center md:justify-start mt-2">
                  <Button
                    text="-"
                    onClick={() => handleQuantityChange(item.id, -1)}
                    className="btn-sm text-xl"
                  />
                  <input
                    type="number"
                    className="w-16 text-center mx-2 text-xl border-gray-300 rounded-md font-montserrat font-bold bg-blue-100 dark:bg-slate-400"
                    value={item.quantity}
                    readOnly
                  />
                  <Button
                    text="+"
                    onClick={() => handleQuantityChange(item.id, 1)}
                    className="btn-sm text-xl"
                  />
                  <span className="ml-4 text-sm font-medium">
                    {`${item.prix.toFixed(2)} € par unité`}
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Ajoutez un message ici..."
                  className="input input-bordered input-sm w-full mt-2"
                  value={item.message || ""}
                  onChange={(e) => handleMessageChange(e, index)}
                />
                <p className="mt-2 font-bold">
                  Total : {(item.prix * item.quantity).toFixed(2)} €
                </p>
              </div>
              <button
                className="btn btn-error btn-sm mt-4 md:mt-0 md:ml-4"
                onClick={() => removeFromCart(item.id)}
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
        <div className="text-right font-bold mt-4 mr-4">
          Total Général : {totalPrice.toFixed(2)} €
        </div>
        <div className="modal-action">
          <Button
            text="Passer la commande"
            onClick={handleOrder}
            className="w-full gap-2"
            icon={FaCartPlus}
          />
        </div>
      </div>
    </div>
  );
}

export default CartModal;
