import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/photos`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les photos
export const getAllPhotos = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllPhotos`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des photos:", error);
  }
};

// Fonction pour récupérer une photo par son id
export const getPhotoById = async (photoId) => {
  try {
    const response = await axios.get(`${apiUrl}/getPhotoById/${photoId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de la photo:", error);
  }
};

// Fonction pour récupérer les photos d'une gallerie par son id
export const getPhotosByGalleryId = async (galleryId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getPhotosByGallerieId/${galleryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des photos:", error);
  }
};

/// POST
// Fonction pour ajouter une nouvelle photo
export const addPhoto = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addPhoto`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de la photo:", error);
  }
};

/// PUT
// Fonction pour mettre à jour une photo
export const updatePhoto = async (photoId, data) => {
  try {
    const response = await axios.put(`${apiUrl}/editPhoto/${photoId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la photo:", error);
  }
};

// Fonction pour mettre à jour l'image d'une photo
export const updatePhotoImage = async (photoId, photo, token) => {
  const formData = new FormData();
  formData.append('image', photo);

  try {
    const response = await axios.patch(`${apiUrl}/${photoId}/photo`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'image:", error);
  }
};

/// DELETE
// Fonction pour supprimer une photo
export const deletePhoto = async (photoId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deletePhoto/${photoId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la photo:", error);
  }
};
