import React, { useState, useEffect } from "react";
import { getPalmaresByAdherentId } from "./../../../services/palmaresServices";

const AdherentPalmares = ({ adherentId }) => {
  const [palmaress, setPalmaress] = useState([]);

  useEffect(() => {
    const getPalmaresByAdherent = async () => {
      try {
        const data = await getPalmaresByAdherentId(adherentId);
        // Vérification que data est un tableau avant de continuer
        if (Array.isArray(data)) {
          setPalmaress(data);
        } else {
          throw new Error("Data received is not an array");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des palmarès:", error);
      }
    };

    getPalmaresByAdherent();
  }, [adherentId]);

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-2 pb-2 shadow-lg max-w-[800px] mx-auto font-montserrat">
      <div className="max-w-[800px]">
        <table className="table-auto bg-white dark:bg-slate-300 font-montserrat mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                colSpan="2"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-2xl font-light uppercase tracking-wider text-white"
              >
                Palmarès
              </th>
            </tr>
            <tr className="border-collapse border-2 border-slate-300 text-white">
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                Animations & Résultats
              </th>
            </tr>
          </thead>
          <tbody>
            {palmaress.length > 0 ? (
              palmaress.map((palmares, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm"
                >
                  <td className="px-5 py-3">{new Date(palmares.date_palmares).toLocaleDateString()}</td>
                  <td className="px-5 py-3">
                    <div className="font-semibold">{palmares.titre_palmares || "Inconnu"}</div>
                    {palmares.resultats_palmares && <div>Résultats: {palmares.resultats_palmares}</div>}
                    {palmares.classement_palmares && <div>Classement: {palmares.classement_palmares}</div>}
                    {palmares.observations_palmares && <div>Observations: {palmares.observations_palmares}</div>}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center py-4 px-6 text-gray-800">Aucun palmarès.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdherentPalmares;
