import React, { useState, useEffect } from 'react';
import { getSettings } from '../services/settingsServices';
import { FaFutbol, FaFootballBall, FaTableTennis, FaBicycle, FaRunning, FaSwimmer, FaBasketballBall, FaVolleyballBall, FaGolfBall, FaHockeyPuck, FaMountain, FaSkiing } from "react-icons/fa";
import { GiKimono, GiFencer, GiTennisRacket, GiHorseHead, GiHighKick, GiBoxingGlove } from 'react-icons/gi';
import { MdOutlineSportsHandball, MdRowing } from "react-icons/md";

const icons = {
  FaFutbol: FaFutbol,
  FaFootballBall: FaFootballBall,
  FaTableTennis: FaTableTennis,
  FaBicycle: FaBicycle,
  FaRunning: FaRunning,
  FaSwimmer: FaSwimmer,
  FaBasketballBall: FaBasketballBall,
  MdOutlineSportsHandball: MdOutlineSportsHandball,
  GiKimono: GiKimono,
  GiFencer: GiFencer,
  GiTennisRacket: GiTennisRacket,
  GiBoxingGlove: GiBoxingGlove,
  FaVolleyballBall: FaVolleyballBall,
  GiHorseHead: GiHorseHead,
  FaGolfBall: FaGolfBall,
  FaHockeyPuck: FaHockeyPuck,
  GiHighKick: GiHighKick,
  FaMountain: FaMountain,
  MdRowing: MdRowing,
  FaSkiing: FaSkiing
};

const Loader = () => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getSettings();
      if (settings && settings[0] && icons[settings[0].discipline_icone]) {
        setIconComponent(() => icons[settings[0].discipline_icone]);
      } else {
        setIconComponent(GiKimono);
      }
    };

    fetchSettings();
  }, []);

  if (!IconComponent) {
    return null; // ou un loader par défaut
  }

  return (
    <div className="flex justify-start items-left h-full mt-2">
      <IconComponent className="text-3xl animate-spin-slow text-primary" />
      <p className="text-primary ml-4 italic">Chargement des données...</p>
      <style jsx>{`
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .animate-spin-slow {
          animation: spin 2s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default Loader;
