import { CiMail } from 'react-icons/ci'; // Importing an example icon for the button
import Button from '../../components/Button/Button'; // Ensure this import path matches your Button component's location
import useScrollToTop from "../../hooks/useScollToTop";

const FaqPage = () => {
  // Scroll top top
  useScrollToTop();
  return (
    <div className='flex flex-col w-full max-w-[1280px] mx-auto p-6 px-10 mt-[120px] bg-backgroundMain dark:bg-backgroundMainDark dark:text-white'>
      <h1 className='text-xl tracking-widest text-gray-800 text-center dark:text-white'>FAQ - Questions Fréquemment Posées</h1>
      <h2 className='text-4xl font-bold uppercase text-center my-6 mb-10'>Avez-vous des questions ? Nous avons des réponses.</h2>

      {/* Header image */}
      <div className="text-center mb-4">
        <img src="/img/inscription.png" alt="Inscription" className="mx-auto" />
      </div>

      {/* Collapsible FAQ Items */}
      <div className='space-y-4'>
        {/* Question 1 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>Comment puis-je m'inscrire afin de créer un compte ?</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            Pour vous inscrire, visitez notre page{' '}
            <a href='/register' className='text-primary underline'>
              Inscription
            </a>
            . Après cette étape, vous pouvez vous connecter à votre compte en utilisant votre adresse e-mail et votre mot de passe.
            sur notre page{' '}
            <a href='/login' className='text-primary underline'>
              Connexion
            </a>
          </p>
        </details>

        {/* Question 2 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>Comment créer un dossier d'adhésion pour une inscription ou un cours d'essai ?</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            En étant connecté, vous pouvez créer un dossier d'adhésion pour une inscription ou un cours d'essai en visitant notre page{' '}
            <a href='/ajouter-inscription-adherent' className='text-primary underline'>
              Adhésion
            </a>
            . Suivez les instructions pour ajouter un nouvel adhérent et remplir les informations nécessaires. Un Dossier d'Inscription sera généré automatiquement.
          </p>
        </details>

        {/* Question 3 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>Que dois-je fournir pour le dossier d'inscription ?</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            Le Dossier d'Inscription est composé de 3 éléments : <br />
            - un certificat médical de moins de 3 mois de non contre-indication à la pratique du judo, <br />
            - le formulaire de licence assurance France Judo signé, <br />
            - le règlement de la cotisation annuelle effectué ou en cours.
          </p>
        </details>

        {/* Question 4 */}
        <details className='group'>
          <summary className='text-lg font-medium cursor-pointer'>Comment inscrire un adhérent à une compétition ?</summary>
          <p className='mt-2 p-4 bg-base-300 rounded-lg'>
            Sur la page {' '}
            <a href='/calendrier' className='text-primary underline'>
              Calendrier
            </a>
            , vous pouvez consulter les compétitions à venir. Pour inscrire un adhérent à une compétition, cliquez sur le bouton "S'inscrire" à côté de l'événement souhaité. Vous avez la possibilité de désinscrire un adhérent à tout moment.
          </p>
        </details>
      </div>

      {/* More Information Button */}
      <div className='flex justify-center mt-10'>
        <Button
          text="Plus d'informations"
          onClick={() => (window.location.href = '/')} // Redirects to the Contact page on click
          icon={CiMail} // Example icon
          className='mt-4 gap-2'
        />
      </div>
    </div>
  );
};

export default FaqPage;
