import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaSave, FaTimes, FaPlus, FaTrash } from 'react-icons/fa';
import Button from '../../../components/Button/Button';
import { updateEvent } from '../../../services/eventServices'; // Assurez-vous que ce service est correctement défini
import { getSettings } from '../../../services/settingsServices'; // Importer le service getSettings

const EditEventModal = ({ isOpen, onClose, refreshList, event }) => {
  const [formData, setFormData] = useState({
    titre: '',
    description: '',
    start: '',
    end: '',
    type_event: '',
    level_event: '',
    cate_event: [],
    lieu_event: '',
    horaire_event: '',
    photo_url: '',
    agenda_url: '',
    is_active: true
  });
  const [selectedCate, setSelectedCate] = useState('');
  const [shortnameClub, setShortnameClub] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          setShortnameClub(data[0].shortname_club);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres', error);
        toast.error('Erreur lors de la récupération des paramètres.');
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (event) {
      setFormData({
        ...event,
        start: event.start ? new Date(event.start).toISOString().slice(0, 10) : '',
        end: event.end ? new Date(event.end).toISOString().slice(0, 10) : '',
        cate_event: event.cate_event ? event.cate_event.split(',') : [],
        is_active: event.is_active !== undefined ? event.is_active : true
      });
    }
  }, [event]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddCategory = () => {
    if (selectedCate && !formData.cate_event.includes(selectedCate)) {
      setFormData((prev) => ({
        ...prev,
        cate_event: [...prev.cate_event, selectedCate],
      }));
      setSelectedCate('');
    }
  };

  const handleRemoveCategory = (category) => {
    setFormData((prev) => ({
      ...prev,
      cate_event: prev.cate_event.filter((cate) => cate !== category),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const eventData = {
        ...formData,
        cate_event: formData.cate_event.join(','),
      };
      await updateEvent(event.id, eventData);
      refreshList();
      onClose();
      toast.success('Événement mis à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'événement', error);
      toast.error('Erreur lors de la mise à jour de l\'événement.');
    }
  };

  // Liste des types d'événements (à ajuster selon vos données)
  const typeEvents = [
    shortnameClub || "Votre Club",
    "Tournoi Interclubs",
    "Cours commun club",
    "Stage Club",
    "Compétition",
    "Animation",
    "Stage & Regroupement Technique",
    "Passage de Grade",
    "Entraînement Départemental",
    "Stage Sportif Départemental",
    "Autre",
  ];

  // Liste des niveaux d'événements (à ajuster selon vos données)
  const levelEvents = ["Club", "Départemental", "Régional", "National", "International"];

  // Liste des catégories d'événements
  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier un Événement
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="titre" placeholder="Titre de l'événement" value={formData.titre} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <textarea name="description" placeholder="Description" value={formData.description} onChange={handleChange} className="textarea textarea-bordered w-full mb-3" />
          <input type="date" name="start" value={formData.start} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="date" name="end" value={formData.end} onChange={handleChange} className="input input-bordered w-full mb-3" />
          {/* Type_event : select option avec typeEvents */}
          <select name="type_event" value={formData.type_event} onChange={handleChange} className="input input-bordered w-full mb-3" required>
            <option value="">Sélectionner le type de l'événement</option>
            {typeEvents.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          {/* Level_event : select option avec levelEvents */}
          <select name="level_event" value={formData.level_event} onChange={handleChange} className="input input-bordered w-full mb-3" required>
            <option value="">Sélectionner le niveau de l'événement</option>
            {levelEvents.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
          {/* Cate_event : select option avec cateEvents et bouton pour ajouter */}
          <div className="flex items-center mb-3">
            <select name="cate_event" value={selectedCate} onChange={(e) => setSelectedCate(e.target.value)} className="input input-bordered w-full mr-2">
              <option value="">Sélectionner une catégorie</option>
              {cateEvents.map((cate) => (
                <option key={cate} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
            <button type="button" onClick={handleAddCategory} className="btn btn-primary">
              <FaPlus />
            </button>
          </div>
          {/* Afficher les catégories sélectionnées avec possibilité de supprimer */}
          <div className="flex flex-wrap mb-3">
            {formData.cate_event.map((cate, index) => (
              <div key={index} className="bg-gray-200 dark:bg-gray-700 text-black dark:text-white p-2 rounded-lg m-1 flex items-center">
                <span>{cate}</span>
                <button type="button" onClick={() => handleRemoveCategory(cate)} className="ml-2 text-red-500">
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
          <input type="text" name="lieu_event" placeholder="Lieu de l'événement" value={formData.lieu_event} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <textarea name="horaire_event" placeholder="Horaire de l'événement" value={formData.horaire_event} onChange={handleChange} className="textarea textarea-bordered w-full mb-3" />
          <input type="url" name="photo_url" placeholder="URL de la photo de l'événement" value={formData.photo_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="url" name="agenda_url" placeholder="URL de l'agenda de l'événement" value={formData.agenda_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <label className="flex items-center space-x-2">
            <input type="checkbox" name="is_active" checked={formData.is_active} onChange={handleChange} />
            <span>Actif</span>
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEventModal;
