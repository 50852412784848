import React from 'react';

const AdherentDetails = ({ adherent }) => {
  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md flex flex-col items-center mb-2">
      <img 
        src={adherent.photo_url || '/img/user_avatar.jpg'} 
        alt={`${adherent.prenom} ${adherent.nom}`} 
        className="w-32 h-32 rounded-full object-cover mb-4"
      />
      <h2 className="text-2xl font-semibold mb-2">{adherent.prenom} {adherent.nom}</h2>
      <p className="text-gray-600 dark:text-gray-300 mb-2">{adherent.email}</p>
      <div className="w-full">
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Téléphone :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.tel}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Adresse :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.adresse}, {adherent.cp} {adherent.commune}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Genre :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.genre}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Statut d'inscription :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.statut_inscription}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Date de naissance :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{new Date(adherent.date_naissance).toLocaleDateString()}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Poids :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.poids} Kg</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Numéro de licence :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.licence_number}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Saison active :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.saison_active}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Autorisation photo :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.autorisation_photo ? 'Oui' : 'Non'}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Notes adhérent :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.notes_adherent || 'Aucune'}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Notes enseignant :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{adherent.notes_enseignant || 'Aucune'}</p>
        </div>
      </div>
    </div>
  );
};

export default AdherentDetails;
