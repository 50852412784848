import React, { useState, useEffect } from "react";
import regrouperParTranchesDages from "./../../../utils/tranchesDages";
import "./Dashboard.css";
import { getCommunityStats } from "../../../services/dashboardServices";
import { getSettings } from "../../../services/settingsServices";

const CommunityStatisticsTable = () => {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [totals, setTotals] = useState({
    selectedCommune: { F: 0, M: 0 },
    HorsCommune: { F: 0, M: 0 },
  });
  const [selectedCommune, setSelectedCommune] = useState("");
  const [communeList, setCommuneList] = useState([]);

  useEffect(() => {
    const fetchCountsAndSettings = async () => {
      try {
        const [communityStats, settings] = await Promise.all([
          getCommunityStats(),
          getSettings(),
        ]);
        const dataRegroupee = regrouperParTranchesDages(communityStats);
        setData(dataRegroupee);

        const uniqueCommunes = [
          ...new Set(communityStats.map((item) => item.commune)),
        ];
        setCommuneList(uniqueCommunes);

        const defaultCommune = settings[0]?.commune_club || uniqueCommunes[0];
        setSelectedCommune(defaultCommune);
        filterData(dataRegroupee, defaultCommune);
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
      }
    };

    fetchCountsAndSettings();
  }, []);

  const calculateTotals = (data, selectedCommune) => {
    let newTotals = {
      selectedCommune: { F: 0, M: 0 },
      HorsCommune: { F: 0, M: 0 },
    };
    Object.values(data).forEach((tranche) => {
      ["F", "M"].forEach((genre) => {
        if (tranche[selectedCommune]) {
          newTotals.selectedCommune[genre] +=
            tranche[selectedCommune][genre] || 0;
        }
        Object.keys(tranche).forEach((commune) => {
          if (commune !== selectedCommune) {
            newTotals.HorsCommune[genre] += tranche[commune][genre] || 0;
          }
        });
      });
    });
    setTotals(newTotals);
  };

  const filterData = (data, commune) => {
    const filtered = Object.keys(data).reduce((acc, tranche) => {
      acc[tranche] = {
        selectedCommune: data[tranche][commune] || { F: 0, M: 0 },
        HorsCommune: Object.keys(data[tranche]).reduce(
          (total, comm) => {
            if (comm !== commune) {
              total.F += data[tranche][comm].F || 0;
              total.M += data[tranche][comm].M || 0;
            }
            return total;
          },
          { F: 0, M: 0 }
        ),
      };
      return acc;
    }, {});
    setFilteredData(filtered);
    calculateTotals(data, commune);
  };

  const handleCommuneChange = (e) => {
    const commune = e.target.value;
    setSelectedCommune(commune);
    filterData(data, commune);
  };

  return (
    <div>
      <label htmlFor="commune-select">Sélectionner une commune:</label>
      <select
        id="commune-select"
        className="mb-3 ml-3 p-2 border border-gray-300 rounded-md"
        value={selectedCommune}
        onChange={handleCommuneChange}
      >
        {communeList.map((commune) => (
          <option key={commune} value={commune}>
            {commune}
          </option>
        ))}
      </select>

      <div className="overflow-x-auto shadow-xl table-scrollable">
        <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr className="border-collapse border-2 border-slate-300">
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider sticky-col">
                Tranche d'Âge
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Féminin
                <br />({selectedCommune})
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Masculin
                <br />({selectedCommune})
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Féminin
                <br /> (Hors {selectedCommune})
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Masculin
                <br /> (Hors {selectedCommune})
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Total par
                <br /> Tranche d'Âge
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {Object.entries(filteredData).map(([tranche, details]) => (
              <tr
                key={tranche}
                className="hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <td className="py-4 px-6 sticky-col bg-white/90">{`${tranche} ans`}</td>
                <td className="py-4 px-6">{details.selectedCommune.F}</td>
                <td className="py-4 px-6">{details.selectedCommune.M}</td>
                <td className="py-4 px-6">{details.HorsCommune.F}</td>
                <td className="py-4 px-6">{details.HorsCommune.M}</td>
                <td className="py-4 px-6 font-medium">
                  {details.selectedCommune.F +
                    details.selectedCommune.M +
                    details.HorsCommune.F +
                    details.HorsCommune.M}
                </td>
              </tr>
            ))}
            {/* Ligne des totaux */}
            <tr className="bg-gray-100 font-semibold border-t-2">
              <th className="py-4 px-6">Total</th>
              <td className="py-4 px-6">{totals.selectedCommune.F}</td>
              <td className="py-4 px-6">{totals.selectedCommune.M}</td>
              <td className="py-4 px-6">{totals.HorsCommune.F}</td>
              <td className="py-4 px-6">{totals.HorsCommune.M}</td>
              <td className="py-4 px-6">
                {totals.selectedCommune.F +
                  totals.selectedCommune.M +
                  totals.HorsCommune.F +
                  totals.HorsCommune.M}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommunityStatisticsTable;
