import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./../../../components/Button/Button";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import { getAdherentsByUser } from "./../../../services/adherentServices";
import { getDossierInscription } from "./../../../services/dossierServices";
import { getGroupName } from "./../../../services/groupServices";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import AdherentCards from "./AdherentCard";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer } from "react-toastify";
import {
  FaFileDownload,
  FaFileSignature,
  FaFilePdf,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

const Dropdown = ({ title, children, isOpen, toggle }) => (
  <div className="bg-blue-100 rounded-lg shadow mb-4 p-4 max-w-[800px] mx-auto w-full">
    <button
      onClick={toggle}
      className="w-full flex justify-between items-center text-left text-lg font-semibold dark:text-gray-800"
    >
      {title}
      {isOpen ? <FaChevronUp /> : <FaChevronDown />}
    </button>
    {isOpen && <div className="mt-2">{children}</div>}
  </div>
);

const InscriptionAdherent = () => {
  useScrollToTop();
  const [adherents, setAdherents] = useState([]);
  const [dossiers, setDossiers] = useState({});
  const [groupNames, setGroupNames] = useState({});
  const { user } = useUser();
  const navigate = useNavigate();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [showDownloadPanel, setShowDownloadPanel] = useState(false);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);

  const toggleDropdown1 = () => setIsOpen1(!isOpen1);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleDropdown3 = () => setIsOpen3(!isOpen3);
  const toggleDropdown4 = () => setIsOpen4(!isOpen4);
  const toggleDropdown5 = () => setIsOpen5(!isOpen5);
  const toggleDropdown6 = () => setIsOpen6(!isOpen6);
  const toggleDropdown7 = () => setIsOpen7(!isOpen7);

  const loadData = async () => {
    if (!user || !user.userId) {
      console.error("Utilisateur non identifié");
      return;
    }
    try {
      const adherentsData = await getAdherentsByUser(user.userId);
      setAdherents(adherentsData);

      const dossierPromises = adherentsData.map((adherent) =>
        getDossierInscription(adherent.id)
      );
      const dossiersData = await Promise.all(dossierPromises);
      const dossiersObj = adherentsData.reduce((acc, adherent, index) => {
        acc[adherent.id] = dossiersData[index];
        return acc;
      }, {});
      setDossiers(dossiersObj);

      // Load group names for each adherent
      const groupNamesPromises = adherentsData.map((adherent) =>
        getGroupName(adherent.id)
      );
      const groupNamesData = await Promise.all(groupNamesPromises);
      const groupNamesObj = adherentsData.reduce((acc, adherent, index) => {
        acc[adherent.id] = groupNamesData[index];
        return acc;
      }, {});
      setGroupNames(groupNamesObj);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, [user]);

  const gridClass =
    adherents.length === 1
      ? "grid-cols-1 place-content-center"
      : "grid-cols-1 md:grid-cols-2 lg:grid-cols-2";

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Dossier d'Inscription
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="text-center p-4 bg-blue-100 rounded-lg shadow max-w-[800px] mx-auto mb-4 dark:text-gray-800 font-montserrat">
        <p className="text-lg font-semibold mb-2">
          Bienvenue sur la page : Dossier d'Inscription
        </p>
        <p className="text-sm text-left">
          Sur cette page, vous pouvez consulter les dossiers d'inscription de
          vos adhérents, mais également créer un nouvel adhérent.
        </p>
        <p className="text-lg font-semibold mt-4">
          Vous gérez actuellement {adherents.length} adhérent(s).
        </p>
      </div>

      {adherents.length > 0 && (
        <div className="overflow-x-auto max-w-[800px] mx-auto bg-blue-100 mb-5 shadow-md">
          <table className="table-auto min-w-full bg-blue-100 dark:bg-gray-800 divide-y divide-gray-200 font-montserrat text-sm">
            <thead className="bg-blue-200 dark:bg-gray-700">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Photo
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nom Prénom
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Statut Inscription
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Saison Active
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Groupe
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {adherents.map((adherent) => {
                const defaultImageUrl =
                  adherent.genre === "Masculin"
                    ? "/img/judoka_avatar.png"
                    : "/img/judokate_avatar.png";
                return (
                  <tr key={adherent.id}>
                    <td className="px-4 py-2">
                      <img
                        src={adherent.photo_url || defaultImageUrl}
                        alt="Photo de l'adhérent"
                        className="w-12 h-12 rounded-full object-cover"
                      />
                    </td>
                    <td className="px-4 py-2">
                      {adherent.nom} {adherent.prenom}
                    </td>
                    <td className="px-4 py-2">{adherent.statut_inscription}</td>
                    <td className="px-4 py-2">{adherent.saison_active}</td>
                    <td className="px-4 py-2">
                      {groupNames[adherent.id]?.join(", ") || "Non attribué"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex flex-col items-center md:flex-row justify-center text-black max-w-[1280px] mx-auto w-full gap-4 mb-4">
        <Button
          text="Création d'un nouvel adhérent"
          onClick={() => navigate("/ajouter-inscription-adherent")}
          hoverLabel="Créer un nouvel adhérent"
        />
      </div>

      <Dropdown
        title="Éléments du dossier d'inscription"
        isOpen={isOpen1}
        toggle={toggleDropdown1}
      >
        <p className="text-sm text-left font-montserrat">
          Le <b>Dossier d'Inscription</b> se compose de plusieurs documents à
          fournir pour toute nouvelle inscription :
          <br />✅ <b>Certificat médical</b> ou <b>Attestation Santé</b> déposé
          <br />✅ <b>Formulaire de Licence Assurance</b> signé et déposé.
          <br />✅ <b>Règlement</b> de la licence-assurance et cotisation
          effectué.
        </p>
      </Dropdown>

      <Dropdown
        title="Pour un Cours d'Essai"
        isOpen={isOpen2}
        toggle={toggleDropdown2}
      >
        <p className="text-sm text-left font-montserrat">
          Pour un <b>cours d'essai</b>, il est possible de venir tester un ou
          deux cours gratuitement. Les documents ainsi que le règlement seront à
          fournir après le cours d'essai.
        </p>
      </Dropdown>

      <Dropdown
        title="Certificats Médicaux & Questionnaires Santé"
        isOpen={isOpen3}
        toggle={toggleDropdown3}
      >
        <p className="text-sm text-left font-montserrat">
          Nous conseillons de faire établir un <b>certificat médical</b> de non
          contre-indication à la pratique du judo pour toute nouvelle
          inscription. <br />
          Ce certificat médical est valable 3 ans. <br />
          Pour les mineurs, il est possible de remplir un{" "}
          <b>questionnaire de santé</b> à la place du certificat médical si le
          celui-ci est encore valable. <br />
          Il faudra déposer le certificat médical ou l'attestation santé signée
          sur le dossier partagé <b>Google Drive</b> (lien unique pour chaque
          adhérent). Vous pouvez télécharger les questionnaires et attestations
          de santé ci-dessous.
        </p>
        <div className="mt-4 flex flex-col items-center">
          <Button
            text="Télécharger Questionnaire/Attestation Santé"
            onClick={() => setShowDownloadPanel(!showDownloadPanel)}
            hoverLabel="Télécharger les documents nécessaires"
          />
          {showDownloadPanel && (
            <div className="text-center p-4 bg-blue-100 rounded-lg shadow max-w-[800px] mx-auto mb-4">
              <div className="flex flex-col gap-4">
                <div className="flex flex-row justify-center gap-4">
                  <Button
                    text="Questionnaire Santé pour Mineur"
                    icon={FaFilePdf}
                    onClick={() =>
                      handleDownload("/doc/QUESTIONNAIRE_MINEUR.pdf")
                    }
                    className="gap-2"
                  />
                  <Button
                    text={"Attestation Santé pour Mineur"}
                    icon={FaFileSignature}
                    onClick={() =>
                      handleDownload("/doc/ATTESTATION_QS_sport_mineurs.pdf")
                    }
                    className="gap-2"
                  />
                </div>
                <div className="flex flex-row justify-center gap-4">
                  <Button
                    text="Questionnaire Santé pour Majeur"
                    icon={FaFilePdf}
                    onClick={() =>
                      handleDownload("/doc/QS-SPORT_cerfa_15699.pdf")
                    }
                    className="gap-2"
                  />
                  <Button
                    text={"Attestation Santé pour Majeur"}
                    icon={FaFileSignature}
                    onClick={() =>
                      handleDownload("/doc/ATTESTATION_QS_sport_majeurs.pdf")
                    }
                    className="gap-2"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Dropdown>

      <Dropdown
        title="Formulaire de Licence-Assurance"
        isOpen={isOpen4}
        toggle={toggleDropdown4}
      >
        <p className="text-sm text-left font-montserrat">
          La Licence-Assurance de 41€ est obligatoire pour tous les adhérents.{" "}
          <br />
          Le <b>formulaire de licence-assurance</b> pré-rempli est à
          télécharger, signer et à déposer dans votre dossier partagé{" "}
          <b>Google Drive</b>. <br />
          Vous pouvez télécharger le formulaire de licence-assurance dans la
          carte de l'adhérent ci-dessous.
        </p>
      </Dropdown>

      <Dropdown
        title="Déposer les éléments dans Google Drive"
        isOpen={isOpen5}
        toggle={toggleDropdown5}
      >
        <p className="text-sm text-left font-montserrat">
          <b>Le Certificat Médical (ou attestation santé)</b> ainsi que le{" "}
          <b>formulaire de licence-assurance signé</b> sont à déposer sur votre
          dossier partagé <b>Google Drive</b>. <br />
          Le lien se trouve dans la carte de l'adhérent ci-dessous. <br />
          N'oubliez pas de signaler que vous avez déposé les documents dans le
          dossier partagé pour que l'administrateur puisse valider
          l'inscription. <br />
        </p>
      </Dropdown>

      <Dropdown
        title="Faire le règlement en ligne"
        isOpen={isOpen6}
        toggle={toggleDropdown6}
      >
        <p className="text-sm text-left font-montserrat">
          Concernant le <b>règlement</b>, vous pourrez régler la
          licence-assurance et cotisation sur la page{" "}
          <a
            href="/commandes-&-reglements"
            className="text-blue-500 hover:text-blue-700"
          >
            Commandes & Règlements
          </a>
          , à partir du premier cours de la saison ou après les cours d'essais.{" "}
          <br />
          Vous pourrez régler par carte bancaire, chèque ou espèces. <br />
          Le paiement en plusieurs fois est possible. <br />
          N'oubliez pas de signaler si vous bénéficiez d'une réduction tel que
          le <b>Pass'Sport</b>. <br />
        </p>
      </Dropdown>

      <Dropdown
        title="Renouvellement de l'adhésion pour les anciens adhérents"
        isOpen={isOpen7}
        toggle={toggleDropdown7}
      >
        <p className="text-sm text-left font-montserrat">
          Pour les anciens adhérents, il est possible de renouveler l'adhésion
          pour la saison suivante à partir du mois de Juin. <br />
          Les documents à déposer et le règlement se feront à la rentrée de
          Septembre. <br />
          Vous pouvez renouveler votre adhésion depuis la carte de l'adhérent,
          en choisissant le prochain groupe pour la saison suivante. Attention
          aux années de naissance.
        </p>
      </Dropdown>

      <div className={`flex flex-col w-full mx-auto mb-4 p-2 dark:text-white`}>
        <div className={`grid ${gridClass} gap-6 max-w-[1280px] mx-auto`}>
          {adherents.map((adherent) => (
            <div
              key={adherent.id}
              className="flex flex-col gap-4 bg-base-100 dark:bg-gray-800 p-4 rounded-lg shadow-md"
            >
              <AdherentCards
                adherent={adherent}
                dossier={dossiers[adherent.id]}
                user={user}
                refreshAdherents={loadData} // Passer loadData comme prop pour rafraîchir les adhérents
              />
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InscriptionAdherent;
