import React, { useState } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { addDiscipline } from "../../../services/disciplinesServices";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateDisciplineModal = ({ isActive, onClose, refreshDisciplines }) => {
  const [formData, setFormData] = useState({
    nom_discipline: "",
    description_discipline: "",
    image_discipline: "",
    contenu_discipline: "",
    ordre_discipline: "",
  });

  const resetForm = () => {
    setFormData({
      nom_discipline: "",
      description_discipline: "",
      image_discipline: "",
      contenu_discipline: "",
      ordre_discipline: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      contenu_discipline: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newDiscipline = await addDiscipline(formData);
      toast.success("Discipline ajoutée avec succès");
      resetForm();
      onClose();
      refreshDisciplines();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la discipline", error);
      toast.error("Erreur lors de l'ajout de la discipline");
    }
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Ajouter une Nouvelle Discipline
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            className="input input-bordered w-full"
            name="nom_discipline"
            value={formData.nom_discipline}
            onChange={handleChange}
            required
            placeholder="Nom de la discipline"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="description_discipline"
            value={formData.description_discipline}
            onChange={handleChange}
            required
            placeholder="Description de la discipline"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="image_discipline"
            value={formData.image_discipline}
            onChange={handleChange}
            required
            placeholder="URL de l'image de la discipline"
          />
          <ReactQuill
            value={formData.contenu_discipline}
            onChange={handleContentChange}
            placeholder="Contenu de la discipline"
          />
          <input
            type="number"
            className="input input-bordered w-full"
            name="ordre_discipline"
            value={formData.ordre_discipline}
            onChange={handleChange}
            required
            placeholder="Ordre de la discipline"
          />
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={() => {
                onClose();
                resetForm();
              }}
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateDisciplineModal;
