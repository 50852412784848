import React from "react";

const CardComptaNumber = ({ title, value, color = "is-primary" }) => {
  // Formater la valeur en entier positif
  const formattedValue = Math.abs(Math.round(value));

  return (
    <div
      className={`notification flex-1 p-6 m-2 rounded-lg shadow-md max-w-sm min-w-[280px] flex flex-col items-center justify-center bg-gray-600 dark:bg-gray-800`}
    >
      <p className="text-4xl font-bold">{formattedValue}</p>
      <p className="text-xl mt-2 text-center">{title}</p>
    </div>
  );
};

export default CardComptaNumber;
