import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/dashboard`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour obtenir les statistiques par groupe
export const getGroupStats = async () => {
  try {
    const response = await axios.get(`${apiUrl}/stats_groupes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des statistiques par groupe:",
      error
    );
    return [];
  }
};

// Fonction pour obtenir les 9 derniers inscrits
export const getLatestMembers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/last_adherents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const members = response.data.map((member) => ({
      ...member,
      photo_url: member.photo_url || "/img/default_avatar.png",
      groupNames: member.Groups.map((group) => group.nom).join(", "),
    }));
    return members; // Retourne les données transformées
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des derniers adhérents:",
      error
    );
    return [];
  }
};

// Fonction pour compter le nombre d'adhérents Licenciés
export const comptageStatutLicencie = async () => {
  try {
    const response = await axios.get(`${apiUrl}/comptageStatutLicencie`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // retourne l'objet complet
  } catch (error) {
    console.error("Erreur lors du comptage des adhérents:", error);
    return { nombreAdherents: 0 }; // assurez-vous de retourner un objet
  }
};



// Comptage du nombre d'inscrits Total
export const comptageInscriptionsTotales = async () => {
  try {
    const response = await axios.get(`${apiUrl}/comptageInscriptionsTotales`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // retourne l'objet complet
  } catch (error) {
    console.error("Erreur lors du comptage des inscrits:", error);
    return { nombreInscrits: 0 }; // assurez-vous de retourner un objet
  }
};

// Nombre d'adhérents par groupe
export const getAdherentsByGroup = async () => {
  try {
    const response = await axios.get(`${apiUrl}/adherentsByGroup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // retourne l'objet complet
  }
  catch (error) {
    console.error("Erreur lors de la récupération des adhérents par groupe:", error);
    return [];
  }
}

// Répartition par genre
export const getGenderDistribution = async () => {
  try {
    const response = await axios.get(`${apiUrl}/repartitionGenre`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // retourne l'objet complet
  }
  catch (error) {
    console.error("Erreur lors de la récupération de la répartition par genre:", error);
    return [];
  }
}

// Répartition par âge
export const getAgeDistribution = async () => {
  try {
    const response = await axios.get(`${apiUrl}/repartitionAge`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // retourne l'objet complet
  }
  catch (error) {
    console.error("Erreur lors de la récupération de la répartition par âge:", error);
    return [];
  }
}

// Fonction pour obtenir les statistiques par Commune, genre, tranche d'âge
export const getCommunityStats = async () => {
  try {
    const response = await axios.get(`${apiUrl}/statistiquesAdherents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // retourne l'objet complet
  }
  catch (error) {
    console.error("Erreur lors de la récupération des statistiques par Commune, genre, tranche d'âge:", error);
    return [];
  }
}

