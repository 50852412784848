import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";
import { createHeures_enseignement } from "../../../services/heuresenseignementServices";
import { toast } from "react-toastify";

const AddHoursModal = ({
  isOpen,
  onClose,
  refreshData,
  enseignants,
  heuresEnseignement = [],
}) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [weekNumber, setWeekNumber] = useState("");
  const [adherentId, setAdherentId] = useState("");
  const [heures, setHeures] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleWeekNumberChange = (e) => {
    const week = e.target.value;
    setWeekNumber(week);
  };

  const handleHeuresChange = (e) => {
    const heuresValue = e.target.value;
    setHeures(heuresValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedWeek = `${year}-W${weekNumber.padStart(2, "0")}`;
      const date = new Date(year, 0, (weekNumber - 1) * 7);
      const mois = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;

      // Vérification des doublons
      const duplicate = heuresEnseignement.some(
        (item) =>
          item.semaine === formattedWeek &&
          item.adherent_id === parseInt(adherentId, 10)
      );

      if (duplicate) {
        setErrorMessage(
          "Cette semaine pour cet enseignant est déjà enregistrée."
        );
        return;
      }

      const formData = {
        semaine: formattedWeek,
        mois,
        adherent_id: adherentId,
        heures: parseFloat(heures),
      };
      console.log("Form data to be sent:", formData); // Vérification des données envoyées
      await createHeures_enseignement(formData);
      toast.success("Heures d'enseignement ajoutées avec succès.");
      onClose();
      refreshData();
    } catch (error) {
      toast.error("Erreur lors de l'ajout des heures d'enseignement.");
      console.error("Erreur lors de l'ajout des heures d'enseignement:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Ajouter des Heures d'Enseignement
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Année:</label>
            <input
              type="number"
              name="year"
              value={year}
              onChange={handleYearChange}
              className="input input-bordered w-full"
              min="2000"
              max="2100"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Semaine:</label>
            <input
              type="number"
              name="weekNumber"
              value={weekNumber}
              onChange={handleWeekNumberChange}
              className="input input-bordered w-full"
              placeholder="Numéro de la semaine"
              min="1"
              max="52"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Enseignant:
            </label>
            <select
              name="adherent_id"
              value={adherentId}
              onChange={(e) => setAdherentId(e.target.value)}
              className="input input-bordered w-full"
              required
            >
              <option value="">Sélectionner un enseignant</option>
              {enseignants.map((enseignant) => (
                <option key={enseignant.id} value={enseignant.id}>
                  {enseignant.nom} {enseignant.prenom}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Heures:</label>
            <input
              type="number"
              step="0.01"
              name="heures"
              value={heures}
              onChange={handleHeuresChange}
              className="input input-bordered w-full"
              min="0"
              required
            />
          </div>
          {errorMessage && (
            <div className="text-red-500 text-sm mb-4 text-center">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHoursModal;
