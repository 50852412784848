import React, { useState, useEffect } from "react";
import DisciplineCard from "./DisciplineCard";
import { getAllDisciplines } from "../../../services/disciplinesServices";

const Disciplines = () => {
  const [disciplines, setDisciplines] = useState([]);

  useEffect(() => {
    fetchDisciplines();
  }, []);

  const fetchDisciplines = async () => {
    try {
      const data = await getAllDisciplines();
      setDisciplines(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des disciplines", error);
    }
  };

  return (
    <div id="cours" className="py-12 bg-gray-100 dark:bg-gray-700 dark:text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-extrabold text-center mb-8 font-montserrat">
          NOS DISCIPLINES
        </h2>
        <div className="flex flex-wrap -mx-4 justify-center">
          {disciplines.map((discipline, index) => (
            <div className="w-full sm:w-1/2 md:w-1/4 p-4" key={index}>
              <DisciplineCard
                title={discipline.nom_discipline}
                ageRange={discipline.description_discipline}
                imageSrc={discipline.image_discipline}
                textDiscipline={discipline.contenu_discipline}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Disciplines;
