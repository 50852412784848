import React, { useState, useEffect } from "react";
import "./Dashboard.css"; // Assurez-vous que ce chemin est correct
import { getGroupStats } from "../../../services/dashboardServices";

const CourseStatisticsTable = () => {
  const [courses, setCourses] = useState([]);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    getGroupStats()
      .then((data) => {
        setCourses(data);

        let initialTotals = {
          Licencie: 0,
          LicencieHorsClub: 0,
          licenceetarret: 0,
          inscrit: 0,
          courssessai: 0,
          Prospect: 0,
          AnciensLicencieArrêt: 0,
        };

        data.forEach((course) => {
          Object.keys(initialTotals).forEach((key) => {
            if (course[key] !== undefined) {
              initialTotals[key] += Number(course[key]);
            }
          });
        });

        setTotals(initialTotals);
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des données", error)
      );
  }, []);

  return (
    <div className="overflow-x-auto table-scrollable">
      <table className="table bg-white dark:bg-slate-300 font-montserrat max-w-[1280px] mx-auto border-collapse border-2 border-slate-300">
        <thead className="bg-gray-800 text-white">
          <tr className="border-collapse  border-2 border-slate-300">
            <th className="px-2 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider sticky-col">
              Cours
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Licencié Club
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Licencié Hors Club
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Licencié - Arrêt
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Inscrit
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Cours d'essai
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Prospect
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Ancien Licencié et Arrêt
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {courses.map((course, index) => (
            <tr
              key={index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td className="px-2 text-left py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white sticky-col bg-gray-300">
                {course.nom}
              </td>
              {Object.keys(totals).map((key) => (
                <td
                  key={key}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white text-center"
                >
                  {course[key] ? course[key] : "—"}{" "}
                  {/* Utilisez un caractère de remplacement clair au lieu d'un espace */}
                </td>
              ))}
            </tr>
          ))}
          <tr className="bg-gray-200 font-bold text-center border-t-2">
            <td className="px-6 py-4 whitespace-nowrap text-sm">Total</td>
            {Object.values(totals).map((total, index) => (
              <td key={index} className="px-6 py-4 whitespace-nowrap text-sm">
                {total}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CourseStatisticsTable;
