import React, { useState, useEffect } from "react";
import { getEventsByAdherent } from "../../../services/eventServices";

const AdherentCompetitions = ({ adherentId }) => {
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    const fetchCompetitions = async () => {
      try {
        const data = await getEventsByAdherent(adherentId);
        if (Array.isArray(data.Events)) {
          setCompetitions(data.Events);
        } else {
          throw new Error("Data received is not an array");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des compétitions:", error);
      }
    };

    fetchCompetitions();
  }, [adherentId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Inscriptions aux Compétitions</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2">Validation</th>
              <th className="py-2 px-4 border-b-2">Date</th>
              <th className="py-2 px-4 border-b-2">Titre</th>
              <th className="py-2 px-4 border-b-2">Résultats & Classement & Observations</th>
            </tr>
          </thead>
          <tbody>
            {competitions.length > 0 ? (
              competitions.map((competition) => (
                <tr key={competition.AdherentEvent.event_id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="py-2 px-4 border-b text-center">{competition.AdherentEvent.is_validate ? '✅' : '❌'}</td>
                  <td className="py-2 px-4 border-b">{new Date(competition.start).toLocaleDateString("fr-FR", { day: "numeric", month: "long", year: "numeric" })}</td>
                  <td className="py-2 px-4 border-b">{competition.titre}</td>
                  <td className="py-2 px-4 border-b">
                    <div>{competition.AdherentEvent.resultats}</div>
                    <div>{competition.AdherentEvent.classement}</div>
                    <div>{competition.AdherentEvent.observations}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4">Aucune inscription aux compétitions.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdherentCompetitions;
