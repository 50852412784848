import React, { useEffect, useState } from "react";
import { getGradesByAdherentId } from "../../../services/gradCnServices";

function AdherentGradeCN({ adherentId }) {
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (adherentId) {
        const gradesData = await getGradesByAdherentId(adherentId);
        setGrades(gradesData);
      }
    }

    fetchData();
  }, [adherentId]);

  const renderUvIcon = (value) => {
    return value === 1 ? "✅" : "❌";
  };

  if (grades.length === 0) {
    return null; // Ne rien afficher si aucune donnée
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-2 pt-2 pb-2 shadow-lg max-w-[800px] mx-auto font-montserrat">
      <div className="overflow-x-auto">
        <table className="table-auto bg-white dark:bg-slate-300 font-montserrat max-w-[800px] mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                colSpan="7"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-2xl font-light uppercase tracking-wider text-white"
              >
                Grade CN
              </th>
            </tr>
            <tr className="border-collapse border-2 border-slate-300 text-white">
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Dominante
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                UV1
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                UV2
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                UV3 Points
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                UV3
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                UV4
              </th>
            </tr>
          </thead>
          <tbody>
            {grades.length > 0 ? (
              grades.map((grade, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm"
                >
                  <td className="px-5 py-3">{grade.dominante}</td>
                  <td className="px-5 py-3">{renderUvIcon(grade.uv1)}</td>
                  <td className="px-5 py-3">{renderUvIcon(grade.uv2)}</td>
                  <td className="px-5 py-3">{grade.uv3_points} pts</td>
                  <td className="px-5 py-3">{renderUvIcon(grade.uv3)}</td>
                  <td className="px-5 py-3">{renderUvIcon(grade.uv4)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4 px-6 text-gray-800">
                  Aucun grade CN.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdherentGradeCN;
