import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getCommunityStats } from "../../../services/dashboardServices";

const CommuneDistributionChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCommunityStats();
        const transformedData = response.reduce((acc, item) => {
          const existingCommune = acc.find(commune => commune.name === item.commune);
          if (existingCommune) {
            existingCommune.value += item.count;
          } else {
            acc.push({ name: item.commune, value: item.count });
          }
          return acc;
        }, []);
        setData(transformedData);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de répartition par commune",
          error
        );
      }
    };

    fetchData();
  }, []);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF69B4", "#AF19FF"];

  return (
    <div className="w-full" style={{ height: "50vh" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, percent }) =>
              `${name}: ${(percent * 100).toFixed(0)}%`
            }
            outerRadius="80%"
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend verticalAlign="bottom" height={40} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CommuneDistributionChart;
