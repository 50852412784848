import React, { useEffect, useState } from "react";
import { verifyPayment } from "../../../services/paymentServices"; // Assurez-vous que le chemin est correct

const PaymentSuccess = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get("session_id");

    if (sessionId) {
      const checkPaymentStatus = async () => {
        try {
          const response = await verifyPayment(sessionId);
          setPaymentStatus(
            response.success
              ? "Paiement vérifié avec succès!"
              : "Erreur de vérification du paiement"
          );
        } catch (error) {
          console.error("Erreur lors de la vérification du paiement:", error);
          setPaymentStatus("Erreur lors de la vérification du paiement");
        }
      };

      checkPaymentStatus();
    }
  }, []);

  return (
    <div>
      <h1>Paiement réussi</h1>
      {paymentStatus ? (
        <p>{paymentStatus}</p>
      ) : (
        <p>
          Merci pour votre achat. Votre paiement est en cours de vérification.
        </p>
      )}
    </div>
  );
};

export default PaymentSuccess;
