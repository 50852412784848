import React from "react";

const CardCompta = ({ title, value, color = "blue" }) => {
  // Color classes based on the 'color' prop
  const colorClasses = {
    blue: "bg-blue-500 text-white",
    green: "bg-green-500 text-white",
    red: "bg-red-500 text-white",
    yellow: "bg-yellow-400 text-gray-800",
    purple: "bg-purple-500 text-white",
  };

  const selectedColorClass = colorClasses[color] || colorClasses.blue;

  const formattedValue = value.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Responsive grid adjustments
  return (
    <div
      className={`flex-1 p-6 m-2 rounded-lg shadow-md ${selectedColorClass} max-w-sm min-w-[280px] flex flex-col items-center justify-center`}
    >
      <p className="text-4xl font-bold">{formattedValue}</p>
      <p className="text-xl mt-2 text-center">{title}</p>
    </div>
  );
};

export default CardCompta;
