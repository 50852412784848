import React from 'react';

const ModalFicheAdherent = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-75 flex justify-center items-center z-50 overflow-y-auto">
      <div className="bg-slate-200 dark:bg-slate-600 p-5 rounded-lg shadow-lg max-w-4xl w-full m-4 relative overflow-y-auto" style={{ maxHeight: '90vh' }}>
        <button onClick={onClose} className="absolute top-3 right-3 text-lg font-bold bg-primary rounded-full w-12 h-12 text-white">✖</button>
        {children}
      </div>
    </div>
  );
};

export default ModalFicheAdherent;
