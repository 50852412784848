import React from 'react';
import { FaUsers, FaChalkboardTeacher, FaRegListAlt } from 'react-icons/fa';
import { GiDiploma } from "react-icons/gi";

const StatisticsSection = ({ licencies, groupes, enseignants, inscritsEtCoursEssai }) => {
  return (
    <div className="relative text-white h-64 lg:h-96 w-full bg-cover bg-top" style={{ backgroundImage: 'url("/img/HeaderCount2.png")' }}>

      <div className="absolute inset-0 flex items-center justify-end mt-12">
        <div className="w-1/2 h-full flex flex-col justify-center">
          <div className="grid grid-cols-2 gap-4 md:flex md:justify-around items-center py-12">
            <div className="text-center">
              <FaUsers className="text-4xl mx-auto" />
              <p className="text-xl lg:text-4xl font-bold">{licencies || '0'}</p>
              <p className="text-sm lg:text-2xl">Licenciés</p>
            </div>
            <div className="text-center">
              <FaRegListAlt className="text-4xl mx-auto" />
              <p className="text-xl lg:text-4xl font-bold">{inscritsEtCoursEssai || '0'}</p>
              <p className="text-sm lg:text-2xl">Inscrits</p>
            </div>
            <div className="text-center">
              <FaChalkboardTeacher className="text-4xl mx-auto" />
              <p className="text-xl lg:text-4xl font-bold">{groupes || '0'}</p>
              <p className="text-sm lg:text-2xl">Groupes</p>
            </div>
            <div className="text-center">
              <GiDiploma className="text-4xl mx-auto" />
              <p className="text-xl lg:text-4xl font-bold">{enseignants || '0'}</p>
              <p className="text-sm lg:text-2xl">Enseignants</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSection;
