import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa";
import { createReglementInscription } from "../../../services/reglementinscriptionServices";
import { getAllAdherentsList } from "../../../services/adherentServices";
import { getSettings } from "../../../services/settingsServices";
import Button from "../../../components/Button/Button";

const CreateReglementInscriptionModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchReglements,
  onClose,
}) => {
  const [newReglement, setNewReglement] = useState({
    adherent_id: "",
    saison: "",
    montant_lic_assurance: "",
    montant_cotisation: "",
    reduction_foyer: "",
    pass_sport: "",
    reduction_saison: "",
    type_reglement: "",
    nb_reglement: "",
    observations: "",
    statut_reglement: "",
  });
  const [adherents, setAdherents] = useState([]);
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const adherentsList = await getAllAdherentsList();
        setAdherents(adherentsList);
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents", error);
        toast.error("Erreur lors de la récupération des adhérents");
      }
    };
    fetchAdherents();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          const fetchedSettings = settingsData[0];
          const previousSeason = getPreviousSeason(
            fetchedSettings.saison_active
          );
          const nextSeason = getNextSeason(fetchedSettings.saison_active);
          setSeasons([
            previousSeason,
            fetchedSettings.saison_active,
            nextSeason,
          ]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des paramètres:", error);
      }
    };

    fetchSettings();
  }, []);

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReglement((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createReglementInscription(newReglement);
      fetchReglements();
      onClose();
      setNewReglement({
        adherent_id: "",
        saison: "",
        montant_lic_assurance: "",
        montant_cotisation: "",
        reduction_foyer: "",
        pass_sport: "",
        reduction_saison: "",
        type_reglement: "",
        nb_reglement: "",
        observations: "",
        statut_reglement: "",
      });
      toast.success("Règlement créé avec succès");
    } catch (error) {
      console.error("Erreur lors de la création du règlement", error);
      toast.error("Erreur lors de la création du règlement");
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 dark:text-black font-montserrat">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Créer un Réglement d'inscription
        </h3>
        <form onSubmit={handleSubmit}>
          <p className="text-sm">Choisir un Adhérent</p>
          <select
            name="adherent_id"
            value={newReglement.adherent_id}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3"
            required
          >
            <option value="">Sélectionnez un adhérent</option>
            {adherents.map((adherent) => (
              <option key={adherent.id} value={adherent.id}>
                {adherent.nom} {adherent.prenom}
              </option>
            ))}
          </select>
          <p className="text-sm">Saison</p>
          <select
            name="saison"
            value={newReglement.saison}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3"
            required
          >
            <option value="">Sélectionnez une saison</option>
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>
          <p className="text-sm">Montant Licence et Assurance</p>
          <input
            type="number"
            name="montant_lic_assurance"
            value={newReglement.montant_lic_assurance}
            onChange={handleChange}
            placeholder="Montant Licence et Assurance"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Montant Cotisation</p>
          <input
            type="number"
            name="montant_cotisation"
            value={newReglement.montant_cotisation}
            onChange={handleChange}
            placeholder="Montant Cotisation"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Réduction Foyer</p>
          <input
            type="number"
            name="reduction_foyer"
            value={newReglement.reduction_foyer}
            onChange={handleChange}
            placeholder="Réduction Foyer"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Pass Sport</p>
          <input
            type="number"
            name="pass_sport"
            value={newReglement.pass_sport}
            onChange={handleChange}
            placeholder="Pass Sport"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Réduction Saison</p>
          <input
            type="number"
            name="reduction_saison"
            value={newReglement.reduction_saison}
            onChange={handleChange}
            placeholder="Réduction Saison"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Type de Règlement</p>
          <select
            name="type_reglement"
            value={newReglement.type_reglement}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3"
          >
            <option value="">Sélectionnez le type de règlement</option>
            <option value="En ligne">En ligne</option>
            <option value="Chèques">Chèques</option>
            <option value="Espèces">Espèces</option>
            <option value="Autres">Autres</option>
          </select>
          <p className="text-sm">Nombre de Règlements</p>
          <input
            type="number"
            name="nb_reglement"
            value={newReglement.nb_reglement}
            onChange={handleChange}
            placeholder="Nombre de Réglements"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Observations / N° Pass'Sport</p>
          <textarea
            name="observations"
            value={newReglement.observations}
            onChange={handleChange}
            placeholder="Observations / N° Pass'Sport"
            className="input input-bordered w-full mb-3"
          />
          <p className="text-sm">Statut du Règlement</p>
          <select
            name="statut_reglement"
            value={newReglement.statut_reglement}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3"
            required
          >
            <option value="">Sélectionnez le statut du règlement</option>
            <option value="Réglé">Réglé</option>
            <option value="En cours">En cours</option>
            <option value="Non réglé">Non réglé</option>
            <option value="Problème">Problème</option>
          </select>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateReglementInscriptionModal;
