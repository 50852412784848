import React, { useState, useEffect } from "react";
import { getBeltHistoryByAdherent } from "../../../services/beltServices";

const AdherentPassageCeinture = ({ adherentId }) => {
  const [ceintures, setCeintures] = useState([]);

  useEffect(() => {
    const fetchCeintures = async () => {
      try {
        const data = await getBeltHistoryByAdherent(adherentId);
        setCeintures(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des ceintures:", error);
      }
    };

    fetchCeintures();
  }, [adherentId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Historique de Passage de Ceintures</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2">Date de Passage</th>
              <th className="py-2 px-4 border-b-2">Ceinture</th>
            </tr>
          </thead>
          <tbody>
            {ceintures.length > 0 ? (
              ceintures.map((ceinture, index) => (
                <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="py-2 px-4 border-b">{new Date(ceinture.date_passage).toLocaleDateString()}</td>
                  <td className="py-2 px-4 border-b">{ceinture.BeltColor.nom || "Inconnu"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center py-4">Aucun historique de passage de ceinture disponible.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdherentPassageCeinture;
