import React, { useState } from 'react';
import Button from "./../../../components/Button/Button";
import { LuMessageSquarePlus } from "react-icons/lu";  // Assurez-vous que cette icône est bien importée ou choisissez-en une autre
import CreateMessageGroupForm from "./CreateMessageGroupForm";  // Assurez-vous que le chemin est correct
import { FaLayerGroup } from "react-icons/fa";

const GroupMessages = ({ userId }) => {
  const [showGroupForm, setShowGroupForm] = useState(false);

  const handleNewGroupMessage = () => {
    setShowGroupForm(true);  // Ouverture du formulaire pour les messages groupés
  };

  const handleGroupFormClose = () => {
    setShowGroupForm(false);  // Fermeture du formulaire
  };

  return (
    <div className="overflow-x-auto shadow-xl">
      <div className="flex justify-between">
        <div>
          <h3 className="card-title mb-2 ml-2">
            <FaLayerGroup /> Messages Groupés
          </h3>
        </div>
        <Button
          text="Messages Groupés"
          onClick={handleNewGroupMessage}
          icon={LuMessageSquarePlus}
          className="mb-4 gap-2"
          hoverLabel="Nouveau Message Groupé"
        />
        
      </div>
      {showGroupForm && (
          <CreateMessageGroupForm
            userId={userId}
            onHide={handleGroupFormClose}
          />
        )}
    </div>
  );
};

export default GroupMessages;
