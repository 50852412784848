import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/usersTechniques`;
const token = localStorage.getItem("token");


/// GET
// Fonction pour obtenir toutes les techniques favorites d'un utilisateur
export const getUserFavorites = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des techniques:", error);
    return [];
  }
};

/// POST
// Fonction pour ajouter une technique aux favoris d'un utilisateur
export const addFavorite = async (user_id, technique_id) => {
  try {
    console.log("Sending to addFavorite:", { user_id, technique_id }); // Ajout de console.log
    const response = await axios.post(
      `${apiUrl}/add`,
      { user_id, technique_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de la technique favorite:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour retirer une technique des favoris d'un utilisateur
export const removeFavorite = async (user_id, technique_id) => {
  try {
    console.log("Sending to removeFavorite:", { user_id, technique_id }); // Ajout de console.log
    const response = await axios.delete(`${apiUrl}/delete`, {
      data: { user_id, technique_id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de la technique favorite:",
      error
    );
    throw error;
  }
};