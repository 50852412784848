import { useState, useEffect } from "react";

const calculateHoursPerMonth = (events) => {
  const hoursPerMonth = {};

  events.forEach(event => {
    const monthYear = new Date(event.start).toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    const hours = event.AdherentEvent.coach_hour || 0;

    if (hoursPerMonth[monthYear]) {
      hoursPerMonth[monthYear] += hours;
    } else {
      hoursPerMonth[monthYear] = hours;
    }
  });

  return Object.entries(hoursPerMonth).map(([date, hours]) => ({ date, hours }));
};

const CoachingSummary = ({ events }) => {
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    setSummary(calculateHoursPerMonth(events));
  }, [events]);

  return (
    <div className="overflow-x-auto font-montserrat mt-4 max-w-[800px] mx-auto mb-4">
      <h2 className="mb-2 uppercase p-4 text-xl font-bold text-primary">Récapitulatif des Heures de Coaching par Mois</h2>
      <table className="bg-white divide-y divide-gray-200 dark:text-black mx-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="px-6 py-3">Mois Année</th>
            <th className="px-6 py-3">Heures Cumulées</th>
          </tr>
        </thead>
        <tbody>
          {summary.map(({ date, hours }, index) => (
            <tr key={index} className="dark:bg-slate-200">
              <td className="px-6 py-4">{date}</td>
              <td className="px-6 py-4">{hours}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoachingSummary;
