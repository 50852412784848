import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/payment`;
const token = localStorage.getItem("token");

export const createCheckoutSessionForArticles = async (items) => {
  try {
    const response = await axios.post(
      `${apiUrl}/create-checkout-session/articles`,
      { items },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.url;
  } catch (error) {
    console.error(
      "Erreur lors de la création de la session de paiement pour les articles:",
      error
    );
    throw error;
  }
};

export const createCheckoutSessionForInscriptions = async (
  items,
  paymentPlan
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/create-checkout-session/inscriptions`,
      { items, paymentPlan },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.url;
  } catch (error) {
    console.error(
      "Erreur lors de la création de la session de paiement pour les inscriptions:",
      error
    );
    throw error;
  }
};

export const verifyPayment = async (sessionId) => {
  try {
    const response = await axios.post(
      `${apiUrl}/verify-payment`,
      { sessionId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la vérification du paiement:", error);
    throw error;
  }
};
