// components/ReglementInterieur.js
import React, { useState, useEffect } from "react";
import { getSettings } from "../../services/settingsServices";
import useScrollToTop from "../../hooks/useScollToTop";

const ReglementInterieur = () => {
  useScrollToTop();
  const [settings, setSettings] = useState({
    reglement_interieur: "",
    reglement_interieur_url_header: "",
    reglement_interieur_url_firstimage: "",
    reglement_interieur_url_secondimage: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <div className="container mx-auto pt-[150px] px-4 mb-10 font-montserrat">
      <h2 className="text-2xl uppercase font-bold mb-4 max-w-[1024px] mx-auto">
        Règlement Intérieur
      </h2>
      {/* Image en en-tête */}
      {settings.reglement_interieur_url_header && (
        <div className="text-center mb-4">
          <img src={settings.reglement_interieur_url_header} alt="En-tête du Règlement Intérieur" className="mx-auto" />
        </div>
      )}

      <div className="mb-8 max-w-[1024px] mx-auto">
        <div dangerouslySetInnerHTML={{ __html: settings.reglement_interieur }} />
      </div>

      <div className="flex justify-center flex-col sm:flex-row w-full">
        {settings.reglement_interieur_url_firstimage && (
          <img
            src={settings.reglement_interieur_url_firstimage}
            alt="Première image facultative"
            className="w-full mb-4 sm:mb-0 sm:mr-4"
          />
        )}
        {settings.reglement_interieur_url_secondimage && (
          <img
            src={settings.reglement_interieur_url_secondimage}
            alt="Deuxième image facultative"
            className="w-full ml-0 sm:ml-4"
          />
        )}
      </div>
    </div>
  );
};

export default ReglementInterieur;
