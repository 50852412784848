import React from 'react';
import { updateAdherentGroup } from './../../../services/adherentServices';
import Button from '../../../components/Button/Button';
import { FaSave, FaTimes } from 'react-icons/fa';

const ChangeGroupModal = ({ isOpen, onClose, adherentId, groups }) => {
  const [selectedGroupId, setSelectedGroupId] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedGroupId) {
      alert("Veuillez sélectionner un groupe.");
      return;
    }
    
    // Convertir selectedGroupId en nombre si ce n'est pas déjà le cas
    const groupId = parseInt(selectedGroupId, 10);
    if (isNaN(groupId)) {
      alert("L'ID de groupe sélectionné n'est pas valide.");
      return;
    }

    await updateAdherentGroup(adherentId, groupId);
    console.log(adherentId, groupId); // Vérifiez que les types sont corrects
    onClose(true); // Passer true pour indiquer que la mise à jour a eu lieu
};

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-gray-400">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Changer de Groupe
        </h3>
        <form onSubmit={handleSubmit}>
          <select
            value={selectedGroupId}
            onChange={(e) => setSelectedGroupId(e.target.value)}
            className="select select-bordered w-full dark:bg-slate-200 dark:text-gray-800"
          >
            <option value="">Sélectionnez un groupe</option>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.nom}</option>
            ))}
          </select>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeGroupModal;
