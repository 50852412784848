import React, { createContext, useState, useEffect, useContext } from "react";
import { getUnreadNotificationsCountByUser } from "./../services/notificationServices";
import { useUser } from "./../pages/Home/UserContext";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { user } = useUser();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const loadUnreadCount = async () => {
      if (user && user.userId) {
        const count = await getUnreadNotificationsCountByUser();
        setUnreadCount(count);
      }
    };

    if (user && user.userId) {
      loadUnreadCount();
    }
  }, [user]);

  return (
    <NotificationContext.Provider value={{ unreadCount, setUnreadCount }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
