import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";
import { updateHeures_enseignement } from "../../../services/heuresenseignementServices";
import { toast } from "react-toastify";

const UpdateHoursModal = ({ isOpen, onClose, heure, refreshData }) => {
  const [heures, setHeures] = useState(heure.heures);

  const handleHeuresChange = (e) => {
    setHeures(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { heures: parseFloat(heures) };
      await updateHeures_enseignement(heure.id, formData);
      toast.success("Heures d'enseignement mises à jour avec succès.");
      onClose();
      refreshData();
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des heures d'enseignement.");
      console.error(
        "Erreur lors de la mise à jour des heures d'enseignement:",
        error
      );
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Mettre à jour les Heures d'Enseignement
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Heures:</label>
            <input
              type="number"
              step="0.01"
              name="heures"
              value={heures}
              onChange={handleHeuresChange}
              className="input input-bordered w-full"
              min="0"
              required
            />
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateHoursModal;
