import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/palmares`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les palmares d'un adhérent par son adherentID
export const getPalmaresByAdherentId = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/palmaresAdherent/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des palmares:", error);
  }
};

// Fonction pour récupérer tous les palmarès de tous les adhérents
export const getAllPalmares = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allPalmares`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des palmares:", error);
  }
};

// Fonction pour vérifier si un palmares existe déjà
export const checkPalmaresExists = async (adherentId, eventId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/checkPalmaresExists/${adherentId}/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la vérification de l'existence du palmares:", error);
  }
};


/// POST
// Fonction pour ajouter un nouveau palmares
export const addPalmares = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addPalmares`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout du palmares:", error);
  }
}

/// PUT
// Fonction pour mettre à jour un palmares
export const updatePalmares = async (palmaresId, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/editPalmares/${palmaresId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du palmares:", error);
  }
};

/// DELETE
// Fonction pour supprimer un palmares
export const deletePalmares = async (palmaresId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deletePalmares/${palmaresId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du palmares:", error);
  }
};
