import React, { useState, useEffect } from "react";
import {
  getAllEvents,
  getAdherentsByEvent,
  getAdherentsCountByEvent,
  registerAdherent,
  getAdherentResults,
  unregisterAdherent,
  saveCoachHour,
  getCoachHour,
} from "../../../services/eventServices";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { FaPlus, FaMinus, FaInfoCircle, FaSave, FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import formatDate from "../../../utils/formatDate";
import getCategory from "../../../utils/getCategory";
import getWeightCategory from "../../../utils/getWeightCategory";
import {
  getAllAdherentsList,
  getAllEnseignants,
} from "../../../services/adherentServices";
import EditResultCompetitorEvent from "./EditResultCompetitorEvent";
import EventDetailsModalEnseignants from "../../PagesEnseignants/EventsPage/EventDetailsModalEnseignants";
import EditHoursModal from "./EditHoursModal";
import Loader from "../../../utils/Loader";

const SuiviCompetitions = () => {
  useScrollToTop();
  const [events, setEvents] = useState([]);
  const [allAdherents, setAllAdherents] = useState([]);
  const [eventAdherents, setEventAdherents] = useState({});
  const [loading, setLoading] = useState(true);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const [resultsModalData, setResultsModalData] = useState({});
  const [selectedAdherent, setSelectedAdherent] = useState("");
  const [adherents, setAdherents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [adherentCounts, setAdherentCounts] = useState({});
  const [filterDate, setFilterDate] = useState("upcoming");
  const [selectedAdherentId, setSelectedAdherentId] = useState(null);
  const [tempSelectedEvent, setTempSelectedEvent] = useState(null);
  const [unregisterModalOpen, setUnregisterModalOpen] = useState(false);
  const [selectedAdherentForUnregister, setSelectedAdherentForUnregister] =
    useState("");
  const [currentEvent, setCurrentEvent] = useState(null);
  const [ModalOpen, setModalOpen] = useState(false);
  const [enseignants, setEnseignants] = useState([]);
  const [editHoursModalOpen, setEditHoursModalOpen] = useState(false);
  const [selectedCoachEvent, setSelectedCoachEvent] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [filterCategory, setFilterCategory] = useState("");
  const [filterType, setFilterType] = useState("");
  const [showOnlyRegisteredEvents, setShowOnlyRegisteredEvents] =
    useState(false);

  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;

  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  useEffect(() => {
    reloadData();
  }, [filterDate, filterCategory, filterType, showOnlyRegisteredEvents]);

  const reloadData = async () => {
    setLoading(true);
    try {
      const eventsData = await getAllEvents();
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      setEvents(eventsData);

      const allAdherentsList = await getAllAdherentsList();
      setAllAdherents(allAdherentsList);

      const adherentsByEvent = {};
      for (let event of eventsData) {
        const adherents = await getAdherentsByEvent(event.id);
        adherentsByEvent[event.id] = adherents;
      }
      setEventAdherents(adherentsByEvent);

      const enseignantsList = await getAllEnseignants();
      setEnseignants(enseignantsList);
    } catch (error) {
      console.error("Erreur lors du chargement des données :", error);
      toast.error("Erreur lors du chargement des données.");
    }
    setLoading(false);
  };

  const openResultsModal = async (event, adherent) => {
    const isEnseignant = enseignants.some(
      (enseignant) => enseignant.id === adherent.id
    );

    if (isEnseignant) {
      setSelectedCoachEvent(event);
      setSelectedCoach(adherent);
      setEditHoursModalOpen(true);
      return;
    }

    if (!event || !event.id || !adherent) {
      toast.error(
        "Les informations sur l'événement ou l'adhérent sont incomplètes."
      );
      return;
    }

    try {
      const results = await getAdherentResults(adherent.id, event.id);

      const ageCategory = getCategory(adherent.date_naissance);
      const weightCategory = getWeightCategory(
        adherent.genre,
        ageCategory,
        adherent.poids
      );

      const modalData = {
        eventId: event.id,
        eventTitle: event.titre,
        eventDate: formatDate(event.start),
        adherentDetails: {
          ...adherent,
          ageCategory,
          weightCategory,
        },
        results: results || {},
      };

      setResultsModalData(modalData);
      setIsResultsModalOpen(true);
    } catch (error) {
      console.error("Error loading results:", error);
      toast.error("Erreur lors du chargement des résultats.");
    }
  };

  const openRegisterModal = (eventId) => {
    setTempSelectedEvent(eventId);
    setRegisterModalOpen(true);
  };

  const handleRegisterAdherent = async () => {
    if (!selectedAdherent) {
      toast.error("Veuillez sélectionner un adhérent.");
      return;
    }
    try {
      await registerAdherent(tempSelectedEvent, selectedAdherent);
      toast.success("Adherent enregistré avec succès.");
      setRegisterModalOpen(false);
      reloadData();
    } catch (error) {
      toast.error("Erreur lors de l'inscription de l'adhérent.");
    }
  };

  const openUnregisterModal = (eventId) => {
    setTempSelectedEvent(eventId);
    setUnregisterModalOpen(true);
  };

  const handleUnregisterAdherent = async () => {
    try {
      await unregisterAdherent(
        selectedAdherentForUnregister,
        tempSelectedEvent
      );
      toast.success("Adhérent désinscrit avec succès.");
      setUnregisterModalOpen(false);
      reloadData();
    } catch (error) {
      toast.error("Erreur lors de la désinscription de l'adhérent.");
    }
  };

  const AdherentTag = ({ adherent }) => {
    const isEnseignant = enseignants.some(
      (enseignant) => enseignant.id === adherent.id
    );

    if (isEnseignant) {
      return (
        <div className="absolute top-0 left-0 bg-red-500 text-white p-1 rounded-br-lg text-xs">
          Coach
        </div>
      );
    }

    const ageCategory = getCategory(adherent.date_naissance);
    const weightCategory = getWeightCategory(
      adherent.genre,
      ageCategory,
      adherent.poids
    );

    return (
      <div className="absolute top-0 left-0 bg-blue-500 text-white p-1 rounded-br-lg text-xs">
        {ageCategory} | {weightCategory}
      </div>
    );
  };

  const getDefaultPhoto = (genre) => {
    return genre === "Masculin"
      ? "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judoka_avatar_pi37iq.png"
      : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judokate_avatar_odsr9l.png";
  };

  const handleEventFilterChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    setFilterDate(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredEvents = events
  .filter((event) => {
    const isEventMatch = selectedEvent ? event.id.toString() === selectedEvent : true;

    const categories = event.cate_event ? event.cate_event.split(",") : [];
    const isCategoryMatch = filterCategory ? categories.includes(filterCategory) : true;

    const isTypeMatch = filterType ? event.type_event === filterType : true;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const eventDate = new Date(event.start);

    let isDateMatch = true;
    switch (filterDate) {
      case "past":
        isDateMatch = eventDate < today;
        break;
      case "upcoming":
        isDateMatch = eventDate >= today;
        break;
      default:
        isDateMatch = true;
        break;
    }

    const hasRegisteredAdherents = eventAdherents[event.id]?.length > 0;
    const isRegisteredMatch = showOnlyRegisteredEvents ? hasRegisteredAdherents : true;

    return isEventMatch && isCategoryMatch && isTypeMatch && isDateMatch && isRegisteredMatch;
  })
  .map((event) => {
    const filteredAdherents =
      eventAdherents[event.id]?.filter(
        (adherent) =>
          adherent.nom.toLowerCase().includes(searchTerm) ||
          adherent.prenom.toLowerCase().includes(searchTerm)
      ) || [];

    return {
      ...event,
      adherents: filteredAdherents,
    };
  });


  const handleInfo = (event) => {
    setCurrentEvent(event);
    setModalOpen(true);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Suivi des compétitions
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mx-auto p-4 mb-4 font-montserrat text-black dark:text-gray-800">
        <input
          type="text"
          placeholder="Trouver par adhérent..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full max-w-xs mb-4 font-montserrat dark:bg-slate-200"
        />
        <select
          className="select select-bordered w-full max-w-xs mb-4 font-montserrat dark:bg-slate-200"
          value={selectedEvent}
          onChange={handleEventFilterChange}
        >
          <option value="">Tous les événements</option>
          {events.map((event) => (
            <option key={event.id} value={event.id.toString()}>
              {event.titre}
            </option>
          ))}
        </select>
        <select
          className="select select-bordered w-full max-w-xs font-montserrat dark:bg-slate-200 mb-4"
          value={filterDate}
          onChange={handleDateFilterChange}
        >
          <option value="all">Toutes les dates</option>
          <option value="past">Passées</option>
          <option value="upcoming">A venir</option>
        </select>

        <select
          className="select select-bordered w-full max-w-xs mb-4 font-montserrat dark:bg-slate-200"
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
        >
          <option value="">Toutes les catégories</option>
          {cateEvents.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>

        <select
          className="select select-bordered w-full max-w-xs font-montserrat dark:bg-slate-200 mb-4"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
        >
          <option value="">Tous les types</option>
          {Array.from(new Set(events.map((event) => event.type_event))).map(
            (type) => (
              <option key={type} value={type}>
                {type}
              </option>
            )
          )}
        </select>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={showOnlyRegisteredEvents}
            onChange={() =>
              setShowOnlyRegisteredEvents(!showOnlyRegisteredEvents)
            }
            className="mr-2"
          />
          <label className="font-montserrat">
            Voir uniquement les événements avec des adhérents inscrits
          </label>
        </div>
      </div>

      {loading && <Loader />}
      <div className="mx-auto p-4 w-full max-w-[1280px]">
        {filteredEvents.map((event) => (
          <div key={event.id}>
            <div className="flex justify-between items-center bg-slate-800 text-white p-2">
              <h3 className="text-2xl font-light">
                {event.titre} - {formatDate(event.start)}
              </h3>
              <FaInfoCircle
                onClick={() => handleInfo(event)}
                className="text-white text-3xl mr-4 cursor-pointer w-6 h-6 max-w-6 min-w-6 max-h-6 min-h-6"
              />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {event.adherents.map((adherent) => (
                <div
                  key={adherent.id}
                  className="bg-base-100 dark:bg-slate-400 shadow-xl rounded-lg p-2 text-center relative mb-4 max-h-48 overflow-hidden flex flex-col items-center justify-center h-48"
                >
                  <div className="relative h-48 w-full">
                    <img
                      src={
                        adherent.photo_url || getDefaultPhoto(adherent.genre)
                      }
                      alt={`${adherent.nom} ${adherent.prenom}`}
                      className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                      onClick={() => openResultsModal(event, adherent)}
                    />
                    <AdherentTag adherent={adherent} />
                  </div>
                  <p className="text-xs font-medium mt-2 text-black dark:text-base-200 font-montserrat">
                    {adherent.nom} {adherent.prenom}
                  </p>
                </div>
              ))}
              <div
                onClick={() => openRegisterModal(event.id)}
                className="bg-base-100 dark:bg-slate-400 shadow-xl rounded-lg p-4 text-center relative mb-4 flex flex-col items-center justify-center h-65"
              >
                <FaPlus className="text-6xl bg-primary text-white rounded-full p-4" />
                <p className="text-sm font-medium mt-2">Inscrire un adhérent</p>
              </div>
              {eventAdherents[event.id] &&
                eventAdherents[event.id].length > 0 && (
                  <div
                    onClick={() => openUnregisterModal(event.id)}
                    className="bg-base-100 dark:bg-slate-400 shadow-xl rounded-lg p-4 text-center relative mb-4 flex flex-col items-center justify-center h-65"
                  >
                    <FaMinus className="text-6xl bg-primary text-white rounded-full p-4" />
                    <p className="text-sm font-medium mt-2">
                      Désinscrire un adhérent
                    </p>
                  </div>
                )}
            </div>
          </div>
        ))}
        {registerModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-gray-600 p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                Inscription à l'événement
              </h2>
              <h3 className="text-lg font-semibold mb-2 text-center">
                {events.find((event) => event.id === tempSelectedEvent)?.titre}
              </h3>
              <p className="text-sm mb-2 text-center italic">
                {formatDate(
                  events.find((event) => event.id === tempSelectedEvent)?.start
                )}
              </p>
              <p className="text-center mb-4">
                Choisissez un adhérent pour l'ajouter à l'événement.
              </p>
              <select
                className="border p-2 rounded w-full mb-3 dark:bg-slate-200 dark:text-gray-800"
                value={selectedAdherent}
                onChange={(e) => setSelectedAdherent(e.target.value)}
              >
                <option value="">Choisissez un adhérent</option>
                {allAdherents.map((adherent) => (
                  <option key={adherent.id} value={adherent.id}>
                    {adherent.nom} {adherent.prenom}
                  </option>
                ))}
              </select>
              <div className="flex justify-center">
                <Button
                  text="Enregistrer"
                  onClick={handleRegisterAdherent}
                  className="w-sm mr-4"
                />
                <Button
                  text="Annuler"
                  onClick={() => setRegisterModalOpen(false)}
                  className="w-sm"
                />
              </div>
            </div>
          </div>
        )}
        {unregisterModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-gray-600 p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
                Désinscription de l'événement
              </h2>
              <p className="text-center mb-4">
                Attention à bien sauvegarder les résultats dans le Palmarès
                avant suppression.
              </p>
              <select
                className="border p-2 rounded w-full mb-3 dark:bg-slate-200 dark:text-gray-800"
                value={selectedAdherentForUnregister}
                onChange={(e) =>
                  setSelectedAdherentForUnregister(e.target.value)
                }
              >
                <option value="">Choisissez un adhérent</option>
                {eventAdherents[tempSelectedEvent]?.map((adherent) => (
                  <option key={adherent.id} value={adherent.id}>
                    {adherent.nom} {adherent.prenom}
                  </option>
                ))}
              </select>
              <div className="flex justify-center">
                <Button
                  text="Désinscrire"
                  onClick={handleUnregisterAdherent}
                  className="w-sm mr-4"
                />
                <Button
                  text="Annuler"
                  onClick={() => setUnregisterModalOpen(false)}
                  className="w-sm"
                />
              </div>
            </div>
          </div>
        )}
        {isResultsModalOpen && (
          <EditResultCompetitorEvent
            isOpen={isResultsModalOpen}
            onClose={() => setIsResultsModalOpen(false)}
            event={{
              id: resultsModalData.eventId,
              titre: resultsModalData.eventTitle,
              start: resultsModalData.eventDate,
            }}
            adherentDetails={resultsModalData.adherentDetails}
            results={resultsModalData.results}
          />
        )}
        {currentEvent && (
          <EventDetailsModalEnseignants
            isOpen={ModalOpen}
            onClose={() => setModalOpen(false)}
            event={currentEvent}
            adherents={eventAdherents[currentEvent.id] || []}
          />
        )}
        {editHoursModalOpen && (
          <EditHoursModal
            isOpen={editHoursModalOpen}
            onClose={() => setEditHoursModalOpen(false)}
            event={selectedCoachEvent}
            adherent={selectedCoach}
            refreshEvents={reloadData}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default SuiviCompetitions;
