import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import { getAllPalmares, deletePalmares } from "./../../../services/palmaresServices";
import { getSettings } from "./../../../services/settingsServices";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { ToastContainer, toast } from 'react-toastify';
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import EditPalmaresModal from './EditPalmaresModal';
import 'react-toastify/dist/ReactToastify.css';

const PalmaresPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [palmaresData, setPalmaresData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentPalmares, setCurrentPalmares] = useState(null);
  const [settings, setSettings] = useState({});
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [palmaresData, settingsData] = await Promise.all([
          getAllPalmares(),
          getSettings(),
        ]);
        setPalmaresData(palmaresData);
        const fetchedSettings = settingsData[0];
        setSettings(fetchedSettings);
        setSelectedSeason(fetchedSettings?.saison_active);

        const previousSeason = getPreviousSeason(fetchedSettings.saison_active);
        const nextSeason = getNextSeason(fetchedSettings.saison_active);
        setSeasons([previousSeason, fetchedSettings.saison_active, nextSeason]);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        toast.error("Erreur lors de la récupération des données");
      }
    };

    fetchData();
  }, []);

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e.target.value);
  };

  const handleEditClick = (palmares) => {
    setCurrentPalmares(palmares);
    setShowEditModal(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await deletePalmares(id);
      setPalmaresData((prevPalmares) =>
        prevPalmares.filter((palmares) => palmares.id !== id)
      );
      toast.success("Palmarès supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression du palmarès:", error);
      toast.error("Erreur lors de la suppression du palmarès");
    }
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const isWithinSeason = (date, season) => {
    const [startYear, endYear] = season.split("-").map(Number);
    const seasonStart = new Date(`${startYear}-08-01`);
    const seasonEnd = new Date(`${endYear}-07-31`);
    return new Date(date) >= seasonStart && new Date(date) <= seasonEnd;
  };

  const filteredPalmares = palmaresData.filter((palmares) => {
    const adherentName = `${palmares.adherent.nom} ${palmares.adherent.prenom}`.toLowerCase();
    const matchesSearchTerm = adherentName.includes(searchTerm.toLowerCase());
    const matchesSeason = selectedSeason === "" || isWithinSeason(palmares.date_palmares, selectedSeason);
    return matchesSearchTerm && matchesSeason;
  });

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Palmarès
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="flex flex-col items-center w-full px-4 mt-4 max-w-[1024px] mx-auto">
        <div className="form-control w-full flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="w-full md:w-1/2 mb-3 md:mb-0">
            <label className="text-sm font-medium text-gray-700 mb-1 dark:text-white">Recherche par Nom Prénom</label>
            <input
              type="text"
              placeholder="Rechercher par nom ou prénom"
              value={searchTerm}
              onChange={handleSearchChange}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white dark:placeholder-gray-500"
            />
          </div>
          <div className="w-full md:w-1/2 mb-3 md:mb-0">
            <label className="text-sm font-medium text-gray-700 mb-1 dark:text-white">Saison</label>
            <select
              name="selectedSeason"
              value={selectedSeason}
              onChange={handleSeasonChange}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white dark:placeholder-gray-500"
            >
              {seasons.map((season) => (
                <option key={season} value={season}>
                  {season}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto shadow-xl w-full max-w-[1024px]">
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-slate-300 divide-y divide-gray-200">
              <thead className="bg-slate-500 text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Adhérent
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Animations, Résultats & Date
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredPalmares.map((palmares) => (
                  <tr key={palmares.id} className="hover:bg-gray-100 dark:text-gray-800">
                    <td className="px-5 py-3 flex items-center">
                      <img
                        src={palmares.adherent.photo_url}
                        alt={palmares.adherent.nom}
                        className="w-12 h-12 rounded-full mr-2 object-cover"
                      />
                      <div>
                        <div className="font-semibold">{palmares.adherent.nom}</div>
                        <div>{palmares.adherent.prenom}</div>
                      </div>
                    </td>
                    <td className="px-5 py-3 text-sm">
                      <div className="font-semibold">{palmares.titre_palmares || "Inconnu"}</div>
                      <div className="text-gray-600">{new Date(palmares.date_palmares).toLocaleDateString()}</div>
                      {palmares.resultats_palmares && (
                        <div>Résultats: {palmares.resultats_palmares}</div>
                      )}
                      {palmares.classement_palmares && (
                        <div>Classement: {palmares.classement_palmares}</div>
                      )}
                      {palmares.observations_palmares && (
                        <div>Observations: {palmares.observations_palmares}</div>
                      )}
                    </td>
                    <td className="px-5 py-3 flex items-center justify-center space-x-2">
                      <ButtonEdit onClick={() => handleEditClick(palmares)} />
                      <ButtonDelete onClick={() => handleDeleteClick(palmares.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="block sm:hidden">
            {filteredPalmares.map((palmares) => (
              <div key={palmares.id} className="bg-slate-300 dark:text-gray-800 shadow-md rounded-lg p-4 mb-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <img
                      src={palmares.adherent.photo_url}
                      alt={palmares.adherent.nom}
                      className="w-12 h-12 rounded-full mr-2 object-cover"
                    />
                    <div>
                      <div className="font-semibold">{palmares.adherent.nom}</div>
                      <div>{palmares.adherent.prenom}</div>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <ButtonEdit onClick={() => handleEditClick(palmares)} />
                    <ButtonDelete onClick={() => handleDeleteClick(palmares.id)} />
                  </div>
                </div>
                <div className="text-sm text-gray-800 mb-2">
                  <div className="font-semibold">{palmares.titre_palmares || "Inconnu"}</div>
                  <div className="text-gray-600">{new Date(palmares.date_palmares).toLocaleDateString()}</div>
                  {palmares.resultats_palmares && (
                    <div>Résultats: {palmares.resultats_palmares}</div>
                  )}
                  {palmares.classement_palmares && (
                    <div>Classement: {palmares.classement_palmares}</div>
                  )}
                  {palmares.observations_palmares && (
                    <div>Observations: {palmares.observations_palmares}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
      {showEditModal && (
        <EditPalmaresModal
          isOpen={showEditModal}
          onClose={handleCloseModal}
          palmares={currentPalmares}
          refreshList={() => {
            const fetchPalmares = async () => {
              try {
                const data = await getAllPalmares();
                setPalmaresData(data);
              } catch (error) {
                console.error("Erreur lors de la récupération des palmarès:", error);
                toast.error("Erreur lors de la récupération des palmarès");
              }
            };
            fetchPalmares();
          }}
        />
      )}
    </div>
  );
};

export default PalmaresPage;
