import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/reglements`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les réglements
export const getReglements = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allreglements`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des réglements:", error);
  }
};

// Fonction pour récupérer les réglements d'un utilisateur
export const getReglementsByUser = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/achats/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des réglements de l'utilisateur:",
      error
    );
  }
};

/// POST
// Fonction pour créer un réglement
export const createReglement = async (reglementData) => {
  try {
    await axios.post(`${apiUrl}/createreglement`, reglementData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la création du réglement:", error);
  }
};

// Fonction pour créer un achat spécifique
export const createSpecialAchat = async (achatData) => {
  try {
    await axios.post(`${apiUrl}/special`, achatData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la création de l'achat spécifique:", error);
  }
};

/// PUT
// Fonction pour modifier un réglement
export const updateReglement = async (id, reglementData) => {
  try {
    await axios.put(`${apiUrl}/updatereglement/${id}`, reglementData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la modification du réglement:", error);
  }
};

// Fonction pour mettre à jour le statur d'un réglement après paiement
export const updateStatutReglements = async (ids, statut_reglement) => {
  try {
    await axios.put(
      `${apiUrl}/updatestatutreglements`,
      {
        ids, // Tableau d'IDs des règlements à mettre à jour
        statut_reglement, // Nouveau statut pour les règlements
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du statut du réglement:",
      error
    );
  }
};

/// DELETE
// Fonction pour supprimer un réglement
export const deleteReglement = async (id) => {
  try {
    await axios.delete(`${apiUrl}/deletereglement/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la suppression du réglement:", error);
  }
};
