import React, { useState, useEffect } from "react";
import { getBeltHistoryByAdherent } from "../../../services/beltServices";
import ButtonDiplome from "../../../components/Button/ButtonDiplome";

const AdherentPassageCeinture = ({ adherentId }) => {
  const [ceintures, setCeintures] = useState([]);

  useEffect(() => {
    const fetchCeintures = async () => {
      try {
        const data = await getBeltHistoryByAdherent(adherentId);
        setCeintures(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des ceintures:", error);
      }
    };

    fetchCeintures();
  }, [adherentId]);

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-2 pt-2 pb-2 shadow-lg max-w-[800px] mx-auto font-montserrat">
      <div className="max-w-[800px]">
        <table className="table-auto bg-white dark:bg-slate-300 dark:text-black font-montserrat mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                colSpan="4"
                className="px-2 py-3 border-b-2 border-gray-200 text-center text-2xl font-light uppercase tracking-wider text-white"
              >
                Historique de Passage de Ceinture
              </th>
            </tr>
            <tr className="border-collapse border-2 border-slate-300 text-white">
              <th
                scope="col"
                className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Date de Passage
              </th>
              <th
                scope="col"
                className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                Image
              </th>
              <th
                scope="col"
                className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                Ceinture
              </th>
              <th
                scope="col"
                className="px-2 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                Diplôme
              </th>
            </tr>
          </thead>
          <tbody>
            {ceintures.length > 0 ? (
              ceintures.map((ceinture, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 dark:hover:bg-gray-400 dark:hover:text-white"
                >
                  <td className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:text-black px-2 py-3 dark:text-black text-sm">
                    {new Date(ceinture.date_passage).toLocaleDateString()}
                  </td>
                  <td className="hover:bg-gray-100 dark:hover:bg-gray-400 px-2 py-3">
                    <img
                      src={`/img/belt/${ceinture.BeltColor.belt_url}`}
                      alt={ceinture.BeltColor.nom || "Inconnu"}
                      className="w-36 h-auto object-cover mx-auto dark:text-black dark:hover:text-white dark:hover:bg-gray-400"
                    />
                  </td>
                  <td className=" dark:hover:bg-gray-400 hover:text-black px-2 py-3 dark:text-black text-sm">
                    {ceinture.BeltColor.nom || "Inconnu"}
                  </td>
                  <td className="px-2 py-3 text-center">
                    {ceinture.BeltColor.ordre >= 2 && ceinture.BeltColor.ordre <= 13 && (
                      <ButtonDiplome
                        adherentId={adherentId}
                        datePassage={ceinture.date_passage}
                        beltImage={ceinture.BeltColor.belt_url}
                        beltName={ceinture.BeltColor.nom}
                        adherentNom={ceinture.Adherent.nom}
                        adherentPrenom={ceinture.Adherent.prenom}
                        dateNaissance={ceinture.Adherent.date_naissance}
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4 px-6 text-gray-800">
                  Aucun historique de passage de ceinture disponible.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdherentPassageCeinture;
