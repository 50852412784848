import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/news`;
const token = localStorage.getItem("token");

//GET
// Fonction pour afficher toutes les actualités
export const getNews = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllNews`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des actualités:", error);
    return [];
  }
};

// Fonction pour afficher les 3 dernières actualités
export const getLatestNews = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getLastThreeNews`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des actualités:", error);
    return [];
  }
};

/// POST
// Fonction pour créer une actualité
export const createNews = async (news) => {
  try {
    const response = await axios.post(`${apiUrl}/addNews`, news, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'actualité:", error);
    return null;
  }
};

/// PUT
// Fonction pour modifier une actualité
export const updateNews = async (news) => {
  try {
    const response = await axios.put(`${apiUrl}/updateNews/${news.id}`, news, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'actualité:", error);
    return null;
  }
};

// Fonction pour uploader une image de l'actualité
export const uploadNewsImage = async (newsId, photo, token) => {
  const formData = new FormData();
  formData.append("image", photo);
  try {
    const response = await axios.patch(`${apiUrl}/${newsId}/photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload de l'image:", error);
  }
};

// Fonction pour toggle l'état de visibilité d'une actualité
export const updateNewsVisibility = async (newsId) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/updateNewsVisibility/${newsId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'actualité:", error);
    return null;
  }
};

/// DELETE
// Fonction pour supprimer une actualité
export const deleteNews = async (newsId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteNews/${newsId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'actualité:", error);
    return null;
  }
};
