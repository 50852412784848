import React, { useState, useEffect } from "react";
import {
  getSettings,
  updateSettings,
  updateReglementHeaderImage,
  updateReglementFirstImage,
  updateReglementSecondImage,
} from "../../../services/settingsServices";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classnames from 'classnames';

const ReglementInterieurForm = () => {
  const [settings, setSettings] = useState({
    reglement_interieur: "",
    reglement_interieur_url_header: "",
    reglement_interieur_url_firstimage: "",
    reglement_interieur_url_secondimage: "",
  });
  const [loading, setLoading] = useState(true);
  const [isUploadingHeader, setIsUploadingHeader] = useState(false);
  const [isUploadingFirstImage, setIsUploadingFirstImage] = useState(false);
  const [isUploadingSecondImage, setIsUploadingSecondImage] = useState(false);
  const [selectedFileHeader, setSelectedFileHeader] = useState(null);
  const [selectedFileFirstImage, setSelectedFileFirstImage] = useState(null);
  const [selectedFileSecondImage, setSelectedFileSecondImage] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
      setLoading(false);
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleContentChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings(settings);
      toast.success("Paramètres mis à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des paramètres");
    }
  };

  const handleFileChangeHeader = (e) => {
    setSelectedFileHeader(e.target.files[0]);
  };

  const handleFileChangeFirstImage = (e) => {
    setSelectedFileFirstImage(e.target.files[0]);
  };

  const handleFileChangeSecondImage = (e) => {
    setSelectedFileSecondImage(e.target.files[0]);
  };

  const handleUploadSubmitHeader = async (e) => {
    e.preventDefault();
    if (!selectedFileHeader) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploadingHeader(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateReglementHeaderImage(selectedFileHeader, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        reglement_interieur_url_header: imageUrl,
      }));
      toast.success("Image d'en-tête mise à jour avec succès!");
      setSelectedFileHeader(null);
      setIsUploadingHeader(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image d'en-tête :", error);
      toast.error("Erreur lors de l'upload de l'image.");
      setIsUploadingHeader(false);
    }
  };

  const handleUploadSubmitFirstImage = async (e) => {
    e.preventDefault();
    if (!selectedFileFirstImage) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploadingFirstImage(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateReglementFirstImage(selectedFileFirstImage, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        reglement_interieur_url_firstimage: imageUrl,
      }));
      toast.success("Première image facultative mise à jour avec succès!");
      setSelectedFileFirstImage(null);
      setIsUploadingFirstImage(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de la première image facultative :", error);
      toast.error("Erreur lors de l'upload de l'image.");
      setIsUploadingFirstImage(false);
    }
  };

  const handleUploadSubmitSecondImage = async (e) => {
    e.preventDefault();
    if (!selectedFileSecondImage) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploadingSecondImage(true);

    try {
      const token = localStorage.getItem("token");
      const imageUrl = await updateReglementSecondImage(selectedFileSecondImage, token);
      setSettings((prevSettings) => ({
        ...prevSettings,
        reglement_interieur_url_secondimage: imageUrl,
      }));
      toast.success("Deuxième image facultative mise à jour avec succès!");
      setSelectedFileSecondImage(null);
      setIsUploadingSecondImage(false);
    } catch (error) {
      console.error("Erreur lors de l'upload de la deuxième image facultative :", error);
      toast.error("Erreur lors de l'upload de l'image.");
      setIsUploadingSecondImage(false);
    }
  };

  if (loading) return <div>Chargement...</div>;

  const renderImageCard = (title, imageUrl, onChangeImage, isUploading, handleUploadSubmit, handleFileChange) => (
    <div className="bg-gray-200 shadow-md rounded-lg p-2 flex items-center mb-4">
      <div className="flex-1">
        <label className="text-sm font-medium text-gray-700 mb-1 block">{title}</label>
        <input
          type="text"
          value={imageUrl}
          readOnly
          className="input input-bordered w-full mb-2"
        />
        <Button text="Changer l'image" onClick={onChangeImage} className="mt-2" />
      </div>
      <div className="ml-4">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Aperçu"
            className="w-32 h-32 object-cover rounded-md"
          />
        )}
      </div>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-2 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Changer l'image
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={() => onChangeImage(false)}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-white dark:bg-slate-300 shadow-md rounded-lg p-4 max-w-4xl">
      <h3 className="text-xl font-semibold mb-6">Règlement Intérieur</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Texte du règlement intérieur
          </label>
          <ReactQuill
            value={settings.reglement_interieur}
            onChange={(value) => handleContentChange('reglement_interieur', value)}
            className="mb-3 bg-slate-200 "
          />
        </div>
        {renderImageCard(
          "URL de l'image d'en-tête",
          settings.reglement_interieur_url_header,
          setIsUploadingHeader,
          isUploadingHeader,
          handleUploadSubmitHeader,
          handleFileChangeHeader
        )}
        {renderImageCard(
          "URL de la première image facultative",
          settings.reglement_interieur_url_firstimage,
          setIsUploadingFirstImage,
          isUploadingFirstImage,
          handleUploadSubmitFirstImage,
          handleFileChangeFirstImage
        )}
        {renderImageCard(
          "URL de la deuxième image facultative",
          settings.reglement_interieur_url_secondimage,
          setIsUploadingSecondImage,
          isUploadingSecondImage,
          handleUploadSubmitSecondImage,
          handleFileChangeSecondImage
        )}
        <div className="flex justify-end">
          <Button
            text="Mettre à jour"
            type="submit"
            className="btn-primary gap-2"
          />
        </div>
      </form>
    </div>
  );
};

export default ReglementInterieurForm;
