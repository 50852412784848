import React, { useState, useEffect } from 'react';
import { FaTrash, FaTimes } from 'react-icons/fa';
import Button from './../../../components/Button/Button';
import { getPhotosByGalleryId, deletePhoto } from './../../../services/photoServices'; // Assurez-vous que ces fonctions sont bien définies
import { toast } from 'react-toastify';

const RemovePhotoFromAlbumModal = ({ isOpen, onClose, galleryId }) => {
    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState('');

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const data = await getPhotosByGalleryId(galleryId);
                setPhotos(data);
                if (data.length > 0) {
                    setSelectedPhoto(data[0].id);
                }
            } catch (error) {
                console.error('Failed to fetch photos:', error);
            }
        };
        if (galleryId) {
            fetchPhotos();
        }
    }, [galleryId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await deletePhoto(selectedPhoto);
            onClose();
            toast.success("Photo supprimée avec succès.");
        } catch (error) {
            console.error("Erreur lors de la suppression de la photo:", error);
            toast.error("Erreur lors de la suppression de la photo.");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Supprimer une Photo de l'Album</h3>
                <form onSubmit={handleSubmit}>
                    <select
                        value={selectedPhoto}
                        onChange={e => setSelectedPhoto(e.target.value)}
                        className="select select-bordered w-full mb-4 dark:text-black dark:bg-slate-200"
                    >
                        {photos.map((photo) => (
                            <option key={photo.id} value={photo.id}>{photo.description}</option>
                        ))}
                    </select>
                    <div className="flex flex-row justify-center gap-4">
                        <Button text="Supprimer" icon={FaTrash} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RemovePhotoFromAlbumModal;
