import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa";
import { updateReglementInscription } from "../../../services/reglementinscriptionServices";
import { getAllAdherentsList } from "../../../services/adherentServices";
import Button from "../../../components/Button/Button";

const EditReglementInscriptionModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchReglements,
  reglement,
  onClose,
}) => {
  const [editReglement, setEditReglement] = useState(reglement);
  const [adherents, setAdherents] = useState([]);

  useEffect(() => {
    setEditReglement(reglement); // Mettre à jour l'état local avec le règlement à éditer chaque fois que le règlement change
  }, [reglement]);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const adherentsList = await getAllAdherentsList();
        setAdherents(adherentsList);
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents", error);
        toast.error("Erreur lors de la récupération des adhérents");
      }
    };
    if (isModalOpen) {
      fetchAdherents();
    }
  }, [isModalOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditReglement((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateReglementInscription(editReglement);
      fetchReglements();
      onClose();
      toast.success("Règlement mis à jour avec succès");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du règlement", error);
      toast.error("Erreur lors de la mise à jour du règlement");
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier le Règlement d'inscription
        </h3>
        <form onSubmit={handleSubmit}>
          {/* Liste déroulante des adhérents */}
          <p className="text-sm">Choisir un Adhérent</p>
          <select
            name="adherent_id"
            value={editReglement.adherent_id}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
            required
          >
            <option value="">Sélectionnez un adhérent</option>
            {adherents.map((adherent) => (
              <option key={adherent.id} value={adherent.id}>
                {adherent.nom} {adherent.prenom}
              </option>
            ))}
          </select>
          <p className="text-sm">Saison</p>
          <input
            type="text"
            name="saison"
            value={editReglement.saison}
            onChange={handleChange}
            placeholder="Saison"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
            required
          />
          <p className="text-sm">Montant Licence Assurance</p>
          <input
            type="number"
            name="montant_lic_assurance"
            value={editReglement.montant_lic_assurance}
            onChange={handleChange}
            placeholder="Montant Licence Assurance"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Montant Cotisation</p>
          <input
            type="number"
            name="montant_cotisation"
            value={editReglement.montant_cotisation}
            onChange={handleChange}
            placeholder="Montant Cotisation"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Réduction Foyer</p>
          <input
            type="number"
            name="reduction_foyer"
            value={editReglement.reduction_foyer}
            onChange={handleChange}
            placeholder="Réduction Foyer"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Pass Sport</p>
          <input
            type="number"
            name="pass_sport"
            value={editReglement.pass_sport}
            onChange={handleChange}
            placeholder="Pass Sport"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Réduction Saison</p>
          <input
            type="number"
            name="reduction_saison"
            value={editReglement.reduction_saison}
            onChange={handleChange}
            placeholder="Réduction Saison"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Type de Règlement</p>
          <select
            name="type_reglement"
            value={editReglement.type_reglement}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          >
            <option value="">Sélectionnez le type de règlement</option>
            <option value="En ligne">En ligne</option>
            <option value="Chèques">Chèques</option>
            <option value="Espèces">Espèces</option>
            <option value="Autres">Autres</option>
          </select>
          <p className="text-sm">Nombre de Règlements</p>
          <input
            type="number"
            name="nb_reglement"
            value={editReglement.nb_reglement}
            onChange={handleChange}
            placeholder="Nombre de règlements"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Observations / N° Pass'Sport</p>
          <textarea
            name="observations"
            value={editReglement.observations}
            onChange={handleChange}
            placeholder="Observations / N° Pass'Sport"
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
          />
          <p className="text-sm">Statut du Règlement</p>
          <select
            name="statut_reglement"
            value={editReglement.statut_reglement}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded mb-3 dark:text-black"
            required
          >
            <option value="">Sélectionnez le statut du règlement</option>
            <option value="Réglé">Réglé</option>
            <option value="En cours">En cours</option>
            <option value="Non réglé">Non réglé</option>
            <option value="Problème">Problème</option>
          </select>

          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Mettre à jour"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditReglementInscriptionModal;
