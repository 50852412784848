import React, { useState, useEffect } from "react";
import { getDossierInscription } from "../../../services/dossierServices";
import { FaCheckCircle, FaTimesCircle, FaFileAlt, FaGoogleDrive } from "react-icons/fa";

const DossierInscription = ({ adherentId }) => {
  const [dossier, setDossier] = useState(null);

  useEffect(() => {
    const fetchDossier = async () => {
      const data = await getDossierInscription(adherentId);
      setDossier(data);
    };

    fetchDossier();
  }, [adherentId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Dossier d'Inscription</h3>
      {dossier ? (
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2">Champ</th>
              <th className="py-2 px-4 border-b-2">Valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Validation Certificat Médical</td>
              <td className="py-2 px-4 border-b text-center">
                {dossier.validate_cm ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-red-500" />}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Lien Certificat Médical</td>
              <td className="py-2 px-4 border-b text-center">
                <a href={dossier.cm_url} target="_blank" rel="noopener noreferrer"><FaFileAlt className="text-blue-500" /></a>
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Date Certificat Médical</td>
              <td className="py-2 px-4 border-b">{new Date(dossier.date_cm).toLocaleDateString("fr-FR")}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Formulaire Licence à Signer</td>
              <td className="py-2 px-4 border-b text-center">
                <a href={dossier.formulaire_licence_a_signer} target="_blank" rel="noopener noreferrer"><FaFileAlt className="text-blue-500" /></a>
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Formulaire Licence Signé</td>
              <td className="py-2 px-4 border-b text-center">
                <a href={dossier.formulaire_licence_signe} target="_blank" rel="noopener noreferrer"><FaFileAlt className="text-blue-500" /></a>
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Validation Licence</td>
              <td className="py-2 px-4 border-b text-center">
                {dossier.validate_licence ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-red-500" />}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Validation Règlement</td>
              <td className="py-2 px-4 border-b text-center">
                {dossier.validate_reglement ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-red-500" />}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Lien Google Drive</td>
              <td className="py-2 px-4 border-b text-center">
                <a href={dossier.lien_drive} target="_blank" rel="noopener noreferrer"><FaGoogleDrive className="text-blue-500" /></a>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p>Chargement des données...</p>
      )}
    </div>
  );
};

export default DossierInscription;
