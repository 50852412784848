import React, { useState, useEffect } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import { getArticles } from "../../../services/articlesServices";
import { getAllUsers } from "../../../services/userServices";
import { updateReglement } from "../../../services/achatsServices";

const EditAchatModal = ({ isOpen, onClose, reglement, refreshAchats }) => {
  const [users, setUsers] = useState([]);
  const [articles, setArticles] = useState([]);
  const [formData, setFormData] = useState({
    user_achat_id: "",
    article_id: "",
    quantite: 1,
    date_achat: "",
    message_achat: "",
    statut_reglement: "",
    type_reglement: "",
    type_achat: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await getAllUsers();
        setUsers(usersData);
        const articlesData = await getArticles();
        setArticles(articlesData);
      } catch (error) {
        console.error("Error loading data:", error.message);
        toast.error("Error loading data.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (reglement) {
      setFormData({
        user_achat_id: reglement.user_achat_id,
        article_id: reglement.article_id || "", // Handle null article_id
        quantite: reglement.quantite,
        date_achat: reglement.date_achat.slice(0, 10),
        message_achat: reglement.message_achat,
        statut_reglement: reglement.statut_reglement,
        type_reglement: reglement.type_reglement,
        type_achat: reglement.type_achat,
      });
    }
  }, [reglement]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateReglement(reglement.id, formData);
      onClose();
      refreshAchats();
      toast.success("Achat mis à jour avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'achat", error.message);
      toast.error("Erreur lors de la mise à jour de l'achat.");
    }
  };

  if (!isOpen) return null;

  // Determine if the type of purchase is 'licence-assurance-cotisation'
  const isLicenceAssuranceCotisation = formData.type_achat === "licence-assurance-cotisation";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Modifier l'Achat
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4 dark:text-black">
          <select
            name="user_achat_id"
            className="select select-bordered w-full"
            value={formData.user_achat_id}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un utilisateur</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.nom} {user.prenom}
              </option>
            ))}
          </select>
          
          {!isLicenceAssuranceCotisation && (
            <select
              name="article_id"
              className="select select-bordered w-full"
              value={formData.article_id}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionnez un article</option>
              {articles.map((article) => (
                <option key={article.id} value={article.id}>
                  {article.nom}
                </option>
              ))}
            </select>
          )}
          
          <input
            type="number"
            name="quantite"
            className="input input-bordered w-full"
            value={formData.quantite}
            min="1"
            onChange={handleChange}
            required
          />
          <input
            type="date"
            name="date_achat"
            className="input input-bordered w-full"
            value={formData.date_achat}
            onChange={handleChange}
            required
          />
          <textarea
            name="message_achat"
            className="textarea textarea-bordered w-full"
            value={formData.message_achat}
            onChange={handleChange}
            placeholder="Message d'achat"
          />
          <select
            name="statut_reglement"
            className="select select-bordered w-full"
            value={formData.statut_reglement}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un statut de réglement</option>
            <option value="Non Réglé">Non Réglé</option>
            <option value="Réglé">Réglé</option>
            <option value="En Attente">En Attente</option>
            <option value="Annulé">Annulé</option>
            <option value="Remboursé">Remboursé</option>
            <option value="Partiellement Réglé">Partiellement Réglé</option>
          </select>
          <select
            name="type_reglement"
            className="select select-bordered w-full"
            value={formData.type_reglement}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un type de réglement</option>
            <option value="En ligne">En ligne</option>
            <option value="Chèque">Chèque</option>
            <option value="Espèces">Espèces</option>
            <option value="Virement">Virement</option>
            <option value="Autre">Autre</option>
          </select>

          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAchatModal;
