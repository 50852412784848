function regrouperParTranchesDages(donnees) {
  const tranches = {
    "0-3": {},
    "4-5": {},
    "6-11": {},
    "12-17": {},
    "18-25": {},
    "26-45": {},
    "46-59": {},
    "60-74": {},
    "75-100": {},
    // ... Autres tranches d'âge ...
  };

  donnees.forEach((adherent) => {
    const tranche = determinerTranche(adherent.age);
    const commune = adherent.commune || "HorsCommune";
    const genre = adherent.genre === "Masculin" ? "M" : "F";

    if (!tranches[tranche][commune]) {
      tranches[tranche][commune] = { F: 0, M: 0 };
    }

    if (tranche && tranches[tranche][commune]) {
      tranches[tranche][commune][genre] += parseInt(adherent.count, 10);
    }
  });

  return tranches;
}

const determinerTranche = (age) => {
  if (age <= 3) return "0-3";
  if (age <= 5) return "4-5";
  if (age <= 11) return "6-11";
  if (age <= 17) return "12-17";
  if (age <= 25) return "18-25";
  if (age <= 45) return "26-45";
  if (age <= 59) return "46-59";
  if (age <= 74) return "60-74";
  if (age <= 100) return "75-100";
  // ... Autres conditions pour déterminer la tranche d'âge ...
};

export default regrouperParTranchesDages;
