import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/reglement-inscription`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les reglements d'inscription
export const getAllReglementInscription = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allreglementsinscriptions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Assurez-vous que cela renvoie les données attendues
  } catch (error) {
    console.error(
      "Erreur lors du chargement des règlements d'inscription:",
      error
    );
    return []; // Retourner un tableau vide en cas d'erreur pour maintenir la cohérence du type de données
  }
};

// Fonction pour récupérer tous les reglements d'inscription par saison
export const getAllReglementInscriptionBySaison = async (saison) => {
  try {
    const response = await axios.get(
      `${apiUrl}/allreglementsinscriptions/${saison}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assurez-vous que cela renvoie les données attendues
  }
  catch (error) {
    console.error(
      "Erreur lors du chargement des règlements d'inscription:",
      error
    );
    return []; // Retourner un tableau vide en cas d'erreur pour maintenir la cohérence du type de données
  }
};

// Fonction pour obtenir le détail d'un reglement d'inscription par id de l'adhérent
export const getReglementInscriptionById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/reglement-inscription/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Assurez-vous que cela renvoie les données attendues
  } catch (error) {
    console.error(
      "Erreur lors du chargement du règlement d'inscription:",
      error
    );
    return null; // Retourner null en cas d'erreur pour maintenir la cohérence du type de données
  }
}

// Fonction pour obtenir le détail d'un reglement d'inscription par id de l'adhérent et saison
export const getReglementInscriptionByIdAndSaison = async (adherentId, saison) => {
  try {
    const response = await axios.get(`${apiUrl}/${adherentId}/${saison}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Assurez-vous que cela renvoie les données attendues
  } catch (error) {
    console.error(
      "Erreur lors du chargement du règlement d'inscription:",
      error
    );
    return null; // Retourner null en cas d'erreur pour maintenir la cohérence du type de données
  }
};

// Fonction pour obtenir le comptage des statuts de règlement
export const getStatutReglementsCount = async () => {
  try {
    const response = await axios.get(`${apiUrl}/statut-reglements-count`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fonction pour obtenir le comptage des statuts de règlement par saison
export const getStatutReglementsCountBySaison = async (saison) => {
  try {
    const response = await axios.get(
      `${apiUrl}/statut-reglements-count/${saison}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// Fonction pour obtenir la répartition des types de règlement
export const getTypeReglementsCount = async () => {
  try {
    const response = await axios.get(`${apiUrl}/repartition-types-reglements`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/// POST
// Fonction pour créer un nouveau reglement d'inscription
export const createReglementInscription = async (newReglement) => {
  try {
    const response = await axios.post(
      `${apiUrl}/add-reglement-inscription`,
      newReglement,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assurez-vous que cela renvoie les données attendues
  } catch (error) {
    console.error("Erreur lors de la création du règlement", error);
    return null; // Retourner null en cas d'erreur pour maintenir la cohérence du type de données
  }
};

/// PUT
// Fonction pour mettre à jour un reglement d'inscription
export const updateReglementInscription = async (reglement) => {
   const { id, ...data } = reglement;
  try {
    const response = await axios.put(
      `${apiUrl}/update-reglement-inscription/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assurez-vous que cela renvoie les données attendues
  } catch (error) {
    console.error("Erreur lors de la mise à jour du règlement", error);
    return null; // Retourner null en cas d'erreur pour maintenir la cohérence du type de données
  }
};

/// DELETE
// Fonction pour supprimer un reglement d'inscription
export const deleteReglementInscription = async (id) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/delete-reglement-inscription/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Assurez-vous que cela renvoie les données attendues
  } catch (error) {
    console.error("Erreur lors de la suppression du règlement", error);
    return null; // Retourner null en cas d'erreur pour maintenir la cohérence du type de données
  }
};
