import React, { useState, useEffect } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { updateGroup } from "../../../services/groupServices";
import { getAllEnseignants } from "../../../services/adherentServices";
import { toast } from "react-toastify";
import Select from "react-select";

const colors = [
  { label: "Bleu", value: "bg-blue-400" },
  { label: "Vert", value: "bg-green-400" },
  { label: "Jaune", value: "bg-yellow-400" },
  { label: "Rouge", value: "bg-red-400" },
  { label: "Indigo", value: "bg-indigo-400" },
  { label: "Violet", value: "bg-purple-400" },
  { label: "Rose", value: "bg-pink-400" },
  { label: "Orange", value: "bg-orange-400" },
  { label: "Cyan", value: "bg-teal-400" },
];

const EditGroupModal = ({ isActive, onClose, groupData, refreshGroups }) => {
  const [formData, setFormData] = useState({
    id: groupData.id,
    nom: groupData.nom,
    horaire1: groupData.horaire1,
    horaire2: groupData.horaire2,
    annees_naissance: groupData.annees_naissance,
    tarif_licence: groupData.tarif_licence,
    tarif_cotisation: groupData.tarif_cotisation,
    is_active: groupData.is_active,
    ordre_groupe: groupData.ordre_groupe,
    quota_groupe: groupData.quota_groupe,
    enseignant1: groupData.enseignant1 || "",
    enseignant2: groupData.enseignant2 || "",
    color_groupe: groupData.color_groupe || colors[0].value,
  });

  const [enseignants, setEnseignants] = useState([]);

  useEffect(() => {
    const fetchEnseignants = async () => {
      try {
        const data = await getAllEnseignants();
        setEnseignants(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des enseignants", error);
        toast.error("Erreur lors de la récupération des enseignants");
      }
    };

    fetchEnseignants();
  }, []);

  useEffect(() => {
    setFormData({
      id: groupData.id,
      nom: groupData.nom,
      horaire1: groupData.horaire1,
      horaire2: groupData.horaire2,
      annees_naissance: groupData.annees_naissance,
      tarif_licence: groupData.tarif_licence,
      tarif_cotisation: groupData.tarif_cotisation,
      is_active: groupData.is_active,
      ordre_groupe: groupData.ordre_groupe,
      quota_groupe: groupData.quota_groupe,
      enseignant1: groupData.enseignant1 || "",
      enseignant2: groupData.enseignant2 || "",
      color_groupe: groupData.color_groupe || colors[0].value,
    });
  }, [groupData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleColorChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      color_groupe: selectedOption.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateGroup(formData);
      onClose(); // Fermer la modale
      refreshGroups(); // Rafraîchir la liste des groupes
      toast.success("Groupe mis à jour avec succès");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du groupe", error);
      toast.error("Erreur lors de la mise à jour du groupe");
    }
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Editer un groupe
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4 flex flex-col">
          {/* Formulaire pour la mise à jour du groupe */}
          <input
            type="text"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            className="input input-bordered w-full"
            required
            placeholder="Nom du groupe"
          />
          {/* Répéter pour chaque champ, similaire à la modale de création */}
          <input
            type="text"
            name="horaire1"
            value={formData.horaire1}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Horaire 1"
          />
          <input
            type="text"
            name="horaire2"
            value={formData.horaire2}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Horaire 2"
          />
          <input
            type="text"
            name="annees_naissance"
            value={formData.annees_naissance}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Années de naissance"
          />
          <input
            type="number"
            name="tarif_licence"
            value={formData.tarif_licence}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Tarif de la licence"
          />
          <input
            type="number"
            name="tarif_cotisation"
            value={formData.tarif_cotisation}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Tarif de la cotisation"
          />
          <input
            type="number"
            name="quota_groupe"
            value={formData.quota_groupe}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Quota du groupe"
          />
          <input
            type="number"
            name="ordre_groupe"
            value={formData.ordre_groupe}
            onChange={handleChange}
            className="input input-bordered w-full"
            placeholder="Ordre du groupe"
          />
          <select
            name="enseignant1"
            value={formData.enseignant1}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            <option value="">Sélectionner Enseignant 1</option>
            {enseignants.map((enseignant) => (
              <option key={enseignant.id} value={`${enseignant.nom} ${enseignant.prenom}`}>
                {enseignant.nom} {enseignant.prenom}
              </option>
            ))}
          </select>
          <select
            name="enseignant2"
            value={formData.enseignant2}
            onChange={handleChange}
            className="input input-bordered w-full"
          >
            <option value="">Sélectionner Enseignant 2</option>
            {enseignants.map((enseignant) => (
              <option key={enseignant.id} value={`${enseignant.nom} ${enseignant.prenom}`}>
                {enseignant.nom} {enseignant.prenom}
              </option>
            ))}
          </select>
          <label className="flex items-center space-x-2">
            <span className="label-text">Groupe actif ?</span>
            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
              className="toggle toggle-primary"
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="label-text">Couleur du groupe</span>
            <Select
              name="color_groupe"
              value={colors.find(color => color.value === formData.color_groupe)}
              onChange={handleColorChange}
              options={colors}
              getOptionLabel={(option) => (
                <div className="flex items-center">
                  <span
                    className={`w-4 h-4 inline-block rounded-full ${option.value} mr-2`}
                  ></span>
                  {option.label}
                </div>
              )}
              getOptionValue={(option) => option.value}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  display: 'flex',
                  alignItems: 'center',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.data.value,
                  color: state.isSelected ? 'white' : 'black',
                }),
              }}
            />
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditGroupModal;
