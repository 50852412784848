import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../components/Button/Button';
import { updateUser, getUserById } from '../../../services/userServices';
import { useUser } from '../../Home/UserContext';
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import { toast } from 'react-toastify';

const EditUserModal = ({ isOpen, onClose, refreshList, userId }) => {
    const { user } = useUser();
    const userRole = getRoleFromRoleId(user?.role_id);
    const [userData, setUserData] = useState({
        nom: '',
        prenom: '',
        email: '',
        role_id: '',
    });

    useEffect(() => {
        if (isOpen && userId) {
            const fetchUserData = async () => {
                try {
                    const data = await getUserById(userId);
                    setUserData(data);
                } catch (error) {
                    console.error("Erreur lors du chargement de l'utilisateur:", error);
                    toast.error("Erreur lors du chargement des données de l'utilisateur");
                }
            };
            fetchUserData();
        }
    }, [isOpen, userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const message = await updateUser(userId, userData);
            toast.success("Utilisateur mis à jour avec succès");
            refreshList(); // Refresh the user list on success
            onClose(); // Close modal on success
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
            toast.error("Erreur lors de la mise à jour de l'utilisateur");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Éditer un Utilisateur</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-bold mb-2">Nom:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="nom"
                            value={userData.nom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Prénom:</label>
                        <input
                            className="input input-bordered w-full"
                            type="text"
                            name="prenom"
                            value={userData.prenom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Email:</label>
                        <input
                            className="input input-bordered w-full"
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-bold mb-2">Rôle:</label>
                        <select
                            className="select select-bordered w-full dark:text-black"
                            name="role_id"
                            value={userData.role_id}
                            onChange={handleChange}
                            required
                        >
                            <option value="1">Administrateur</option>
                            <option value="2">Utilisateur</option>
                            <option value="3">Enseignant</option>
                        </select>
                    </div>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Mettre à jour" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Fermer" icon={FaTimes} onClick={onClose} className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditUserModal;
