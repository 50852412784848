import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { updateDepenseRecetteClub } from '../../../services/depensesrecettesclubServices';
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';

const EditDepenseRecetteModal = ({ isOpen, onClose, refreshList, depenseRecette }) => {
  const [formData, setFormData] = useState({
    saison: '',
    date_operation: '',
    designation: '',
    categorie: '',
    beneficiaire: '',
    montant: '',
    type_reglement: '',
    type_operation: 'Dépense', // valeur par défaut
    photo_url: '',
    lien_url: ''
  });

  useEffect(() => {
    if (depenseRecette) {
      setFormData({
        saison: depenseRecette.saison || '',
        date_operation: depenseRecette.date_operation || '',
        designation: depenseRecette.designation || '',
        categorie: depenseRecette.categorie || '',
        beneficiaire: depenseRecette.beneficiaire || '',
        montant: depenseRecette.montant || '',
        type_reglement: depenseRecette.type_reglement || '',
        type_operation: depenseRecette.type_operation || 'Dépense',
        photo_url: depenseRecette.photo_url || '',
        lien_url: depenseRecette.lien_url || ''
      });
    }
  }, [depenseRecette]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDepenseRecetteClub(depenseRecette.id, formData);
      refreshList();
      onClose();
      toast.success('Dépense ou recette mise à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la dépense ou recette", error);
      toast.error("Erreur lors de la mise à jour de la dépense ou recette");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier la Dépense ou Recette
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="saison" placeholder="Saison" value={formData.saison} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="date" name="date_operation" placeholder="Date" value={formData.date_operation} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="designation" placeholder="Désignation" value={formData.designation} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="categorie" placeholder="Catégorie" value={formData.categorie} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="beneficiaire" placeholder="Bénéficiaire" value={formData.beneficiaire} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="number" name="montant" placeholder="Montant" value={formData.montant} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="type_reglement" placeholder="Type de Règlement" value={formData.type_reglement} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <select name="type_operation" value={formData.type_operation} onChange={handleChange} className="input input-bordered w-full mb-3" required>
            <option value="Dépense">Dépense</option>
            <option value="Recette">Recette</option>
          </select>
          <input type="text" name="photo_url" placeholder="URL de la Photo" value={formData.photo_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="lien_url" placeholder="Lien URL" value={formData.lien_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDepenseRecetteModal;
