import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/adherents`;
const token = localStorage.getItem("token");

///GET
// Fonction pour récupérer le nombre d'adhérents pour HeaderCount
export const getAdherentsCount = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countAdherents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement du nombre d'adhérents:", error);
  }
};

// Fonction pour récupérer le nombre d'enseignants pour HeaderCount
export const getEnseignantsCount = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countEnseignants`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement du nombre d'enseignants:", error);
  }
};

// Fonction pour récupérer les adhérents gérés par l'utilisateur connecté
export const getAdherentsByUser = async () => {
  const userItem = localStorage.getItem("userInfo");
  if (!userItem) {
    console.error("Aucun utilisateur enregistré dans localStorage");
    return [];
  }

  let user;
  try {
    user = JSON.parse(userItem);
  } catch (error) {
    console.error(
      "Erreur lors de la désérialisation des données de l'utilisateur",
      error
    );
    return [];
  }

  if (!user.userId) {
    console.error("Aucun userId trouvé pour l'utilisateur actuel");
    return [];
  }

  const token = localStorage.getItem("token"); // Assurez-vous que le token est bien stocké et récupéré
  if (!token) {
    console.error("Token d'authentification non trouvé");
    return [];
  }
  try {
    const response = await axios.get(`${apiUrl}/managedByUser/${user.userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des adhérents:", error);
    return [];
  }
};

// Fonction pour récupérer les adhérents par leur id
export const getAdherentById = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getAllAdherentsById/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des détails de l'adhérent:",
      error
    );
  }
};

// Fonction pour récupérer les utilisateurs par l'id de l'adhérent
export const getUserIdByAdherentId = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getUsersByAdherent/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des utilisateurs:", error);
  }
};

// Fonction pour récupérer la liste (nom-prénom) des adhérents
export const getAllAdherentsList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllAdherentsList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des adhérents:", error);
  }
};

// Fonction pour obtenir la liste (nom, prénom, photo_url ainsi que leur groupe) de tous les adhérents
export const getAllAdherentsListWithGroup = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllAdherentsListWithGroup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des adhérents:", error);
  }
};

// Fonction pour récupérer la liste des enseignants : id, nom, prenom
export const getAllEnseignants = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getEnseignants`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des enseignants:", error);
  }
};

// Fonction pour obtenir la liste (nom prenom date_naissance photo_url) de tous les adhérents qui sont nés aujourd'hui pour fêter leur anniversaire
export const getBirthdayAdherents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getBirthdayAdherents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des adhérents:", error);
  }
};

// Fonction pour obtenir la liste de des adhérents, avec groupe et users associés
export const getAllAdherentsWithGroupAndUsers = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/getAllAdherentsWithGroupAndUsers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des adhérents:", error);
  }
};

/// POST
// Fonction pour ajouter un nouvel adhérent
export const addNewAdherent = async (formData) => {
  try {
    const response = await axios.post(`${apiUrl}/addAdherent`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'adhérent:", error);
  }
};

/// PATCH
// Mise à jour de la photo d'un adhérent
export const updateAdherentPhoto = async (adherentId, photo, token) => {
  const formData = new FormData();
  formData.append("image", photo.get("image")); // Juste pour s'assurer que la photo est correctement ajoutée
  try {
    console.log(
      "Sending photo update request for",
      adherentId,
      "with",
      formData.get("image")
    );
    const response = await axios.patch(
      `${apiUrl}/${adherentId}/photo`, // Assurez-vous que l'URL est correcte
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Response from updateAdherentPhoto", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la modification de la photo de l'adhérent",
      error
    );
    return null;
  }
};

/// PUT
// Fonction pour mettre à jour le poids d'un adhérent
export const updatePoids = async (adherentId, poids) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updatePoids/${adherentId}`,
      { poids },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du poids de l'adhérent:",
      error
    );
  }
};

// Fonction pour mettre à jour les informations d'un adhérent
export const updateAdherent = async (adherentId, formData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateAdherent/${adherentId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'adhérent:", error);
  }
};

// Fonction pour mettre à jour les notes d'un adhérent
export const updateTeacherNotes = async (adherentId, notesData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateTeacherNotes/${adherentId}`,
      notesData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour des notes enseignant:", error);
    throw error;
  }
};

// Fonction pour mettre à jour le groupe d'un adhérent
export const updateAdherentGroup = async (adherentId, groupId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateAdherentGroup/${adherentId}`,
      { group_id: groupId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du groupe de l'adhérent:",
      error
    );
  }
};

/// POST
// Fonction pour ajouter un nouveau groupe à un adhérent
export const addAdherentToGroup = async (adherentId, groupId) => {
  try {
    await axios.post(
      `${apiUrl}/addGroupe/${adherentId}`,
      { group_id: groupId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Erreur lors de l'ajout du groupe à l'adhérent:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer un adhérent
export const deleteAdherent = async (adherentId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/removeAdherent/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'adhérent:", error);
  }
};

// Fonction pour supprimer un groupe d'un adhérent
export const removeAdherentFromGroup = async (adherentId, groupId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/removeGroupe/${adherentId}/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'adhérent du groupe:",
      error
    );
    throw error;
  }
};
