import React, { useState, useEffect, useCallback, useRef } from "react";
import { getGroupsList } from "./../../../services/groupServices";
import {
  getAllAdherentsListWithGroup,
  getBirthdayAdherents,
} from "./../../../services/adherentServices";
import { getSettings } from "./../../../services/settingsServices";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import useScrollToTop from "../../../hooks/useScollToTop";
import { FaCheck, FaTimes, FaBirthdayCake, FaTrashAlt } from "react-icons/fa";
import {
  saveAttendance,
  countAttendance,
  deleteAllAttendances,
} from "../../../services/attendanceServices"; // Importer la fonction deleteAllAttendances
import { ToastContainer, toast } from "react-toastify";
import Button from "../../../components/Button/Button";

const AttendancePage = () => {
  useScrollToTop();
  const [groups, setGroups] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const isAdmin = userRole === "admin";
  const [filterGroup, setFilterGroup] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [attendance, setAttendance] = useState({});
  const [assiduityInfo, setAssiduityInfo] = useState({});
  const [birthdayAdherents, setBirthdayAdherents] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [statutInscription, setStatutInscription] = useState("");
  const [hideLicencieArret, setHideLicencieArret] = useState(true);
  const [sortAssiduity, setSortAssiduity] = useState(false);
  const [loading, setLoading] = useState(true);

  const attendanceRef = useRef(attendance);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const [groupList, adherentList, birthdayData] = await Promise.all([
      getGroupsList(),
      getAllAdherentsListWithGroup(),
      getBirthdayAdherents(),
    ]);

    const activeGroups = groupList.filter((group) => group.is_active); // Filtrer les groupes actifs
    setGroups(activeGroups);

    setAdherents(adherentList);
    setBirthdayAdherents(birthdayData);
    initializeAttendance(adherentList);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchSettings();
    fetchData();
  }, [fetchData]);

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      if (settings && settings.length > 0) {
        const activeSeason = settings[0].saison_active;
        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);
        setSelectedSeason(activeSeason);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des paramètres:", error);
    }
  };

  const initializeAttendance = (adherentList) => {
    const initialAttendance = {};
    adherentList.forEach((adherent) => {
      initialAttendance[adherent.id] = 0;
    });
    setAttendance(initialAttendance);
    attendanceRef.current = initialAttendance;
  };

  const loadAssiduity = async (adherentId) => {
    try {
      const data = await countAttendance(adherentId);
      setAssiduityInfo((prev) => ({ ...prev, [adherentId]: data }));
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des statistiques de présence:",
        error
      );
    }
  };

  useEffect(() => {
    adherents.forEach((adherent) => {
      loadAssiduity(adherent.id);
    });
  }, [adherents, filterGroup]);

  const filteredAdherents = () => {
    let result = adherents.filter((adherent) => {
      const groupMatch =
        filterGroup === "" ||
        adherent.Groups.some((group) => group.nom === filterGroup);
      const seasonMatch = adherent.saison_active === selectedSeason;
      const statusMatch =
        statutInscription === "" ||
        adherent.statut_inscription === statutInscription;
      const licencieArretMatch =
        !hideLicencieArret ||
        adherent.statut_inscription !== "Licencié - Arrêt";
      return groupMatch && seasonMatch && statusMatch && licencieArretMatch;
    });

    if (sortAssiduity) {
      result.sort((a, b) => {
        const aAssiduity = assiduityInfo[a.id]?.assiduity || 0;
        const bAssiduity = assiduityInfo[b.id]?.assiduity || 0;
        return bAssiduity - aAssiduity;
      });
    } else {
      result.sort((a, b) => a.nom.localeCompare(b.nom));
    }

    return result;
  };

  const BirthdayTag = ({ adherentId }) => {
    const isBirthday = birthdayAdherents.some(
      (adherent) => adherent.id === adherentId
    );
    return isBirthday ? (
      <div className="absolute top-2 right-2 hover:scale-110 transition-transform duration-300">
        <FaBirthdayCake className="text-2xl text-white bg-primary rounded-badge px-2 py-2 w-10 h-10 shadow-lg" />
      </div>
    ) : null;
  };

  const AdherentTag = ({ adherentId }) => {
    const info = assiduityInfo[adherentId];

    const getBadgeColor = (percentage) => {
      if (percentage >= 75) return "bg-green-500";
      if (percentage >= 50) return "bg-yellow-500";
      if (percentage > 0) return "bg-red-500";
      return "bg-blue-500";
    };

    const assiduityPercentage =
      info && info.assiduity !== 0 ? Math.round(info.assiduity) : null;
    const badgeColor = info ? getBadgeColor(info.assiduity) : "bg-blue-500";

    return (
      <div
        className={`absolute top-2 left-2 ${badgeColor} text-white p-1 rounded-br-lg`}
      >
        {assiduityPercentage ? `${assiduityPercentage}%` : "N/A"}
      </div>
    );
  };

  const toggleAttendance = (id) => {
    if (loading) return;
    setAttendance((prev) => {
      const newAttendance = {
        ...prev,
        [id]: prev[id] === 2 ? 0 : prev[id] + 1,
      };
      attendanceRef.current = newAttendance;
      return newAttendance;
    });
  };

  const handleGroupChange = (e) => {
    setFilterGroup(e.target.value);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const getDefaultPhoto = (genre) => {
    return genre === "Masculin"
      ? "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judoka_avatar_pi37iq.png"
      : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judokate_avatar_odsr9l.png";
  };

  const handleValidateAttendance = async () => {
    const attendanceData = [];
    adherents.forEach((adherent) => {
      if (adherent.Groups.some((g) => g.nom === filterGroup)) {
        if (
          attendanceRef.current[adherent.id] === 1 ||
          attendanceRef.current[adherent.id] === 2
        ) {
          attendanceData.push({
            adherent_id: adherent.id,
            presence: attendanceRef.current[adherent.id] === 1,
            date_presence: selectedDate,
          });
        }
      }
    });

    try {
      const result = await saveAttendance(attendanceData);
      console.log("Résultat de l'enregistrement:", result);
      toast.success("Appel enregistré avec succès");
      initializeAttendance(adherents);
      adherents.forEach((adherent) => {
        loadAssiduity(adherent.id);
      });
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'appel:", error);
      toast.error("Erreur lors de l'enregistrement de l'appel");
    }
  };

  const handleDeleteAllAttendances = async () => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer tous les appels de tous les adhérents sur tous les groupes ? Cette action est irréversible."
      )
    ) {
      try {
        await deleteAllAttendances();
        toast.success("Tous les appels ont été supprimés avec succès");
        fetchData(); // Rafraîchir les données après la suppression
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de tous les appels:",
          error
        );
        toast.error("Erreur lors de la suppression de tous les appels");
      }
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Présence des Adhérents
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mx-auto p-4 mb-4 max-w-[1280px]">
        <div className="flex flex-wrap gap-4 w-full font-montserrat text-normal max-w-[1280px] ">
          <select
            onChange={handleGroupChange}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
            disabled={loading}
          >
            <option value="">Tous les groupes</option>
            {groups.map((group) => (
              <option key={group.id} value={group.nom}>
                {group.nom}
              </option>
            ))}
          </select>
          <select
            value={selectedSeason}
            onChange={handleSeasonChange}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
            disabled={loading}
          >
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>
          <select
            name="statut_inscription"
            value={statutInscription}
            onChange={(e) => setStatutInscription(e.target.value)}
            className="select select-bordered w-full mb-1 dark:bg-slate-200 dark:text-gray-800"
            disabled={loading}
          >
            <option value="">Sélectionnez un statut d'inscription</option>
            <option value="Licencié">Licencié</option>
            <option value="Inscrit">Inscrit</option>
            <option value="Cours d'essai">Cours d'essai</option>
            <option value="Licencié - Arrêt">Licencié - Arrêt</option>
            <option value="Licencié Hors Club">Licencié Hors Club</option>
            <option value="Ancien Licencié">Ancien Licencié</option>
          </select>
          <input
            type="date"
            className="input input-bordered w-full mb-1"
            value={selectedDate}
            onChange={handleDateChange}
            disabled={loading}
          />
          <div className="flex flex-wrap justify-center gap-4 mb-4 items-center">
            <label className="flex items-center cursor-pointer space-x-2">
              <span className="label-text dark:text-slate-200">
                Licenciés-Arrêt masqués
              </span>
              <input
                type="checkbox"
                checked={hideLicencieArret}
                onChange={() => setHideLicencieArret(!hideLicencieArret)}
                className="toggle toggle-primary"
                disabled={loading}
              />
            </label>
            <label className="flex items-center cursor-pointer space-x-2 mr-5">
              <span className="label-text dark:text-slate-200">
                Trier par assiduité
              </span>
              <input
                type="checkbox"
                checked={sortAssiduity}
                onChange={() => setSortAssiduity(!sortAssiduity)}
                className="toggle toggle-primary"
                disabled={loading}
              />
            </label>
            <Button
              text="Supprimer tous les appels"
              onClick={handleDeleteAllAttendances}
              className="w-auto max-h-10 mx-auto gap-2 flex items-center"
              icon={FaTrashAlt}
              disabled={loading}
            />
          </div>
        </div>
        {filterGroup && (
          <div>
            <h3 className="text-2xl font-light mb-4 bg-slate-800 text-white p-2">
              {filterGroup} ({filteredAdherents().length})
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
              {filteredAdherents().map((adherent) => (
                <div
                  key={adherent.id}
                  className="bg-base-100 shadow-xl rounded-lg p-2 text-center relative"
                  onClick={() => toggleAttendance(adherent.id)}
                >
                  <img
                    src={adherent.photo_url || getDefaultPhoto(adherent.genre)}
                    alt={`${adherent.nom} ${adherent.prenom}`}
                    className="w-full h-full rounded-lg object-cover min-h-[200px] max-h-[200px]"
                  />
                  <div className="absolute top-0 right-0">
                    <BirthdayTag adherentId={adherent.id} />
                  </div>
                  <div className="absolute top-0 left-0">
                    <AdherentTag adherentId={adherent.id} />
                  </div>
                  {attendance[adherent.id] === 1 && (
                    <div className="absolute inset-0 bg-green-500 bg-opacity-50 flex justify-center items-center">
                      <FaCheck className="text-white text-3xl" />
                    </div>
                  )}
                  {attendance[adherent.id] === 2 && (
                    <div className="absolute inset-0 bg-red-500 bg-opacity-50 flex justify-center items-center">
                      <FaTimes className="text-white text-3xl" />
                    </div>
                  )}
                  <p className="absolute bottom-2 left-2 right-2 text-sm font-medium bg-white py-1">
                    {adherent.nom} {adherent.prenom}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {filterGroup && (
          <Button
            text="Enregistrer l'appel"
            onClick={handleValidateAttendance}
            className="w-full max-w-xs mx-auto gap-2"
            icon={FaCheck}
            disabled={loading}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AttendancePage;
