import React, { useEffect, useState } from "react";
import NotificationsDisplay from "./NotificationsDisplay";
import MessagesDisplay from "./MessagesDisplay";
import CompetitionsDisplay from "./CompetitionsDisplay";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import ContactFormDisplay from "./ContactFormDisplay";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import ScrollIndicator from "./../../Home/ScrollIndicator";

const DashboardUser = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  // const userId = user?.userId;
  const [userId, setUserId] = useState(null);

  const getUserIdFromLocalStorage = () => {
    try {
      const userData = localStorage.getItem("userInfo");
      if (userData) {
        const userObj = JSON.parse(userData);
        return userObj.userId; // Assurez-vous que la clé est correcte
      }
    } catch (error) {
      console.error(
        "Erreur lors de la lecture des données de l'utilisateur du localStorage:",
        error
      );
      toast.error("Erreur lors de la lecture des données de l'utilisateur.");
      return null;
    }
  };

  // Vérification du userId et de l'utilisateur avec useEffect
  useEffect(() => {
    const id = getUserIdFromLocalStorage();
    if (!id) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      toast.error("L'ID de l'utilisateur n'est pas trouvé.");
    } else {
      setUserId(id);
    }
  }, []);

  if (!userId) {
    return (
      <div className="text-center">
        <h2>Vous devez être connecté pour accéder à cette page.</h2>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Tableau de Bord
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4 mt-4">
        {user?.role_id === 1 && (
          <div className="w-full max-w-4xl px-2 mb-4">
            <ContactFormDisplay userId={userId} />
          </div>
        )}
        <div className="w-full max-w-4xl px-2">
          <NotificationsDisplay userId={userId} />
        </div>
        <div className="w-full max-w-4xl px-2 my-8">
          <MessagesDisplay userId={userId} />
        </div>
        <div className="w-full max-w-4xl px-2 mb-4 mt-4">
          <CompetitionsDisplay userId={userId} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DashboardUser;
