import React, { createContext, useState, useEffect, useContext } from "react";
import { getUnreadMessagesCountByUser } from "./../services/messageServices";
import { useUser } from "./../pages/Home/UserContext";

// Création du contexte
const MessageContext = createContext();

// Fournisseur du contexte
export const MessageProvider = ({ children }) => {
  const { user } = useUser();
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  useEffect(() => {
    const loadUnreadMessageCount = async () => {
      if (user && user.userId) {
        const count = await getUnreadMessagesCountByUser(); // L'ID de l'utilisateur est géré dans la fonction maintenant
        setUnreadMessageCount(count);
      }
    };

    if (user && user.userId) {
      loadUnreadMessageCount();
    }
  }, [user]); // Écouter les changements sur `user`

  return (
    <MessageContext.Provider
      value={{ unreadMessageCount, setUnreadMessageCount }}
    >
      {children}
    </MessageContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useMessage = () => useContext(MessageContext);
