import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/dossier-inscription`;
const token = localStorage.getItem("token");

///GET
// Fonction pour récupérer le dossier d'inscription d'un adhérent
export const getDossierInscription = async (adherentId) => {
  try {
    const response = await axios.get(`${apiUrl}/adhesion/${adherentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement du dossier d'inscription:", error);
  }
};

// Fonction pour récupérer la liste des dossiers d'inscription
export const getAllDossiersInscription = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors du chargement des dossiers d'inscription:",
      error
    );
  }
};

// Fonction pour Obtenir le lien de la licence assurance (licence_url) d'un adhérent (inscription_adherent_id)
export const getLicenceAssuranceUrl = async (inscriptionAdherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/licence-url/${inscriptionAdherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération de la licence assurance:",
      error
    );
  }
};

/// POST
// Fonction pour créer un nouveau dossier d'inscription
export const createDossierInscription = async (formData) => {
  try {
    await axios.post(`${apiUrl}/createdossier`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Dossier d'inscription créé avec succès");
  } catch (error) {
    console.log("formData:", formData);
    console.error(
      "Erreur lors de la création du dossier d'inscription:",
      error
    );
  }
};

// Fonction pour créer un nouveau dossier d'inscription suite à une adhésion
export const createNewDossierInscription = async (inscriptionAdherentId) => {
  try {
    const response = await axios.post(
      `${apiUrl}/create-new-dossier-at-adhesion`,
      { inscription_adherent_id: inscriptionAdherentId },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Assurez-vous que le token est inclus si nécessaire
        },
      }
    );
    console.log("Dossier d'inscription créé avec succès:", response.data);
    return response.data; // Retourner les données pour confirmation
  } catch (error) {
    console.error(
      "Erreur lors de la création du dossier d'inscription:",
      error
    );
    throw error; // Propager l'erreur pour permettre une gestion ultérieure
  }
};

/// PATCH
// Fonction pour mettre à jour un dossier d'inscription
export const updateDossierInscription = async (dossierId, formData) => {
  try {
    await axios.patch(`${apiUrl}/updatedossier/${dossierId}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Dossier d'inscription mis à jour avec succès");
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du dossier d'inscription:",
      error
    );
  }
};

// Fonction pour upload le certificat médical d'un dossier d'inscription
export const uploadCertificatMedical = async (dossierId, formData) => {
  try {
    const response = await axios.patch(
      `${apiUrl}/${dossierId}/upload-certificat-medical`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Certificat médical uploadé avec succès", response);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload du certificat médical:", error);
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer un dossier d'inscription
export const deleteDossierInscription = async (dossierId) => {
  try {
    await axios.delete(`${apiUrl}/${dossierId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Dossier d'inscription supprimé avec succès");
  } catch (error) {
    console.error(
      "Erreur lors de la suppression du dossier d'inscription:",
      error
    );
  }
};
