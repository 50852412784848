import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { getAllEvents } from "./../../../services/eventServices";
import { getUsersEvents } from "./../../../services/usersEventsServices"; // Import des fonctions de gestion des favoris
import { getSettings } from "./../../../services/settingsServices"; // Import du service getSettings
import EventCardUser from "./EventCardUser";

const ListEvents = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterLevel, setFilterLevel] = useState("");
  const [filterStatus, setFilterStatus] = useState("A Venir");
  const [showFavorites, setShowFavorites] = useState(false); // État pour afficher les favoris
  const [favoriteEvents, setFavoriteEvents] = useState([]);
  const [shortnameClub, setShortnameClub] = useState("");

  // Récupérer userId depuis localStorage
  const getUserIdFromLocalStorage = () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      return userInfo?.userId;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de userId depuis localStorage:",
        error
      );
      return null;
    }
  };

  const userId = getUserIdFromLocalStorage();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          setShortnameClub(data[0].shortname_club);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres", error);
      }
    };

    const fetchEvents = async () => {
      try {
        const data = await getAllEvents();
        setEvents(data.filter((event) => event.is_active)); // Filtrer pour garder seulement les événements actifs

        if (userId) {
          // Charger les événements favoris
          const favorites = await getUsersEvents(userId);
          setFavoriteEvents(favorites.map((fav) => fav.event_id));
        } else {
          console.error("User ID is undefined");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des événements", error);
      }
    };

    fetchSettings();
    fetchEvents();
  }, [userId]);

  // Liste des types d'événements (à ajuster selon vos données)
  const typeEvents = [
    shortnameClub || "Votre Club",
    "Tournoi Interclubs",
    "Cours commun club",
    "Stage Club",
    "Compétition",
    "Animation",
    "Stage & Regroupement Technique",
    "Passage de Grade",
    "Entraînement Départemental",
    "Stage Sportif Départemental",
    "Autre",
  ];

  // Liste des niveaux d'événements (à ajuster selon vos données)
  const levelEvents = [
    "Club",
    "Départemental",
    "Régional",
    "National",
    "International",
  ];

  // Liste des catégories d'événements
  const cateEvents = [
    "Eveil",
    "Mini-Poussin(e)",
    "Poussin(e)",
    "Benjamin(e)",
    "Minime",
    "Cadet(te)",
    "Junior",
    "Sénior",
    "Vétéran",
  ];

  // Définir les statuts basés sur la date
  const statusEvents = ["A Venir", "Passé"];

  const filteredEvents = events.filter((event) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour la comparaison de date seulement

    const eventStartDate = new Date(event.start);
    eventStartDate.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour la comparaison de date seulement

    const isPast = eventStartDate < today;
    const isTodayOrFuture = eventStartDate >= today;

    const statusFilter =
      filterStatus === "A Venir"
        ? isTodayOrFuture
        : filterStatus === "Passé"
        ? isPast
        : true; // Si filterStatus n'est pas "A Venir" ou "Passé", tous les événements sont valides

    const categories = event.cate_event ? event.cate_event.split(",") : [];
    const categoryFilter = filterCategory
      ? categories.includes(filterCategory)
      : true;

    const isFavoriteEvent = showFavorites
      ? favoriteEvents.includes(event.id)
      : true;

    return (
      event.titre.toLowerCase().includes(searchTerm.toLowerCase()) &&
      categoryFilter &&
      (filterType ? event.type_event === filterType : true) &&
      (filterLevel ? event.level_event === filterLevel : true) &&
      statusFilter &&
      isFavoriteEvent
    );
  });

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Calendrier
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-wrap justify-center items-middle mb-4 gap-4 max-w-[1280px] mx-auto">
        <input
          type="text"
          placeholder="Recherche par titre"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="input input-bordered w-full"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="select select-bordered w-full dark:text-slate-800"
          >
            <option value="">Toutes catégories</option>
            {cateEvents.map((cate) => (
              <option key={cate} value={cate}>
                {cate}
              </option>
            ))}
          </select>
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="select select-bordered w-full dark:text-slate-800"
          >
            <option value="">Tous types</option>
            {typeEvents.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <select
            value={filterLevel}
            onChange={(e) => setFilterLevel(e.target.value)}
            className="select select-bordered w-full dark:text-slate-800"
          >
            <option value="">Tous niveaux</option>
            {levelEvents.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="select select-bordered w-full dark:text-slate-800"
          >
            <option value="">Tous statuts</option>
            {statusEvents.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <label className="label cursor-pointer space-x-2">
          <span className="label-text dark:text-slate-200">
            Voir uniquement les favoris
          </span>
          <input
            type="checkbox"
            checked={showFavorites}
            onChange={() => setShowFavorites(!showFavorites)}
            className="toggle toggle-primary ml-2"
          />
        </label>
      </div>

      <div className="text-center p-4 bg-blue-100 rounded-lg shadow max-w-[800px] mx-auto mb-4 dark:text-gray-800">
        <p className="text-lg font-semibold mb-2">
          Bienvenue sur la page Calendrier
        </p>
        <p className="text-sm text-left">
          Sur cette page, vous pouvez pré-inscrire ou désinscrire votre adhérent
          à un événement. Vous pouvez filtrer les événements par catégorie,
          type, niveau et recherche par titre. Vous pouvez également afficher
          uniquement les événements favoris.
        </p>
        <p className="text-sm text-left">
          Légende : 🔲 Pré-inscrit / ✅ Validation par l'enseignant / 🥋 Coach
          présent
        </p>
      </div>

      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-[1280px] mx-auto">
        {filteredEvents.map((event) => (
          <EventCardUser key={event.id} event={event} userId={userId} />
        ))}
      </div>
    </div>
  );
};

export default ListEvents;
