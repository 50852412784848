import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/events`;
const token = localStorage.getItem("token");

///GET
// Fonction pour récupérer tous les événements sur lequel un adherent est inscrit
export const getEventsByAdherent = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/adherent_events/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const sortedEvents = response.data.Events.sort((a, b) => {
      // Convertir les dates de type string en objets Date pour comparaison
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);
      return dateB - dateA; // Trier par ordre décroissant
    });
    return response.data;
  } catch (error) {}
};

// Fonction pour récupérer tous les événements
export const getAllEvents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allevents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

// Fonction pour afficher uniquement les 3 prochains événements si is-active est true
export const getUpcomingEvents = async () => {
  try {
    const response = await axios.get(`${apiUrl}/nextevents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

// Fonction pour récupérer les adhérents inscrits à un événement à partir de l'ID de l'événement
export const getAdherentsByEvent = async (eventId) => {
  try {
    const response = await axios.get(`${apiUrl}/competitors/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Assurez-vous que la réponse est un tableau
    if (Array.isArray(response.data.Adherents)) {
      return response.data.Adherents;
    } else {
      return []; // Retourne un tableau vide si la structure n'est pas celle attendue
    }
  } catch (error) {
    return []; // Retourne un tableau vide en cas d'erreur
  }
};

// Fonction pour récupérer les coachs inscrits à un événement à partir de l'ID de l'événement
export const getCoachesByEvent = async (eventId) => {
  try {
    const response = await axios.get(`${apiUrl}/coachs/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Assurez-vous que la réponse est un tableau
    if (Array.isArray(response.data.Adherents)) {
      return response.data.Adherents;
    } else {
      return []; // Retourne un tableau vide si la structure n'est pas celle attendue
    }
  } catch (error) {
    return []; // Retourne un tableau vide en cas d'erreur
  }
};

// Fonction pour récupérer les events sur lesquels un coach est inscrit
export const getTeacherEvents = async (teacherId) => {
  try {
    const response = await axios.get(`${apiUrl}/teacher_events/${teacherId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

// Fonction pour récupérer le nombre d'adhérents inscrits à un événement à partir de l'ID de l'événement
export const getAdherentsCountByEvent = async (eventId) => {
  try {
    const response = await axios.get(`${apiUrl}/countcompetitors/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Renvoie directement le nombre d'adhérents, en supposant que la réponse est bien structurée
    return response.data.competitors_count;
  } catch (error) {
    return 0; // Retourne 0 en cas d'erreur
  }
};

// Fonction pour afficher spécifiquement les résultats d'un adhérent sur un événement
export const getAdherentResults = async (adherentId, eventId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/adherent_results/${adherentId}/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
};

// Fonction pour afficher coach_hour d'un enseignant sur un événement
export const getCoachHour = async (teacherId, eventId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/coach_hour/${teacherId}/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {}
};

/// POST
// Fonction pour créer un événement
export const createEvent = async (formData) => {
  try {
    const response = await axios.post(`${apiUrl}/createevents`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'événement:", error);
  }
};

// Fonction pour inscrire un adhérent à un événement
export const registerAdherent = async (event_id, adherent_id) => {
  try {
    const response = await axios.post(
      `${apiUrl}/registerAdherentToEvent`,
      { event_id, adherent_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de l'inscription de l'adhérent à l'événement:",
      error
    );
  }
};

// Fonction pour uploader un fichier d'événements (Excel/CSV)
export const uploadEventsFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("eventsFile", file);

    const response = await axios.post(`${apiUrl}/upload-events`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload du fichier d'événements:", error);
  }
};

/// PUT
// Fonction pour mettre à jour les heures de coaching d'un adhérent sur un événement
export const saveCoachHour = async (adherentId, eventId, formData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateCoachPresence/${adherentId}/${eventId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour des heures de coaching:",
      error
    );
  }
};

/// PUT
// Fonction pour modifier un événement
export const updateEvent = async (eventId, formData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/editevents/${eventId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'événement:", error);
  }
};

// Fonction pour mettre à jour les résultats d'un adhérent sur un événement
export const updateAdherentResults = async (formData) => {
  try {
    const response = await axios.put(`${apiUrl}/editadherentevent`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour des résultats de l'adhérent:",
      error
    );
  }
};

// Fonction pour mettre à jour le statut de validation d'un adhérent sur un événement
export const toggleAdherentValidation = async (adherent_id, event_id) => {
  try {
    const response = await axios.put(
      `${apiUrl}/validateAdherentToEvent`,
      {
        adherent_id,
        event_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du changement de la validation:", error);
  }
};

// Fonction pour toggle le statut is_active de l'événement
export const toggleEventActive = async (eventId) => {
  try {
    const response = await axios.put(
      `${apiUrl}/activeevents/${eventId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du changement de l'état de l'événement:", error);
  }
};

/// PATCH
// Fonction pour mettre à jour l'image d'un événement
export const updateEventImage = async (eventId, file, token) => {
  const formData = new FormData();
  formData.append("image", file);
  try {
    const response = await axios.patch(`${apiUrl}/${eventId}/photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to update data");
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'image de l'article:",
      error
    );
    return "";
  }
};

/// DELETE
// Fonction pour supprimer un événement
export const deleteEvent = async (eventId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteevents/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'événement:", error);
  }
};

// Fonction pour désinscrire un adhérent d'un événement
export const unregisterAdherent = async (adherentId, eventId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/unregisterAdherentToEvent/${adherentId}/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la désinscription de l'adhérent:", error);
  }
};

/////////////////////////////////////////////////////////////////////////////////////
