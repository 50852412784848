const MessageBubble = ({ message, onDeleteMessage, canDelete }) => {
  if (!message.Users) {
    return null; // ou un indicateur de chargement
  }
  return (
    <div className={`chat ${message.user_id === message.currentUserId ? 'chat-end' : 'chat-start'}`}>
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img alt="User" src={message.Users.photoURL || "https://res.cloudinary.com/dy5kblr32/image/upload/v1715177107/images/utilisateurs/user_avatar_ecd77h.jpg"} />
        </div>
      </div>
      <div className="chat-header">
        {`${message.Users.prenom} ${message.Users.nom}`}
      </div>
      <div className="chat-bubble flex flex-col">
        {message.message}
        <time className="text-[10px] opacity-50">{new Date(message.createdAt).toLocaleTimeString()}</time>
        {canDelete && (
          <button onClick={onDeleteMessage} className="text-slate-300 text-xs mt-2 text-left">Supprimer</button>
        )}
      </div>
    </div>
  );
};

export default MessageBubble;
