import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/depensesrecettesclub`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour afficher toutes les dépenses et recettes du club
export const getDepensesRecettesClub = async () => {
  try {
    const response = await axios.get(`${apiUrl}/AllDepensesRecettesClub`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
  catch (error) {
    console.error("Erreur lors du chargement des dépenses et recettes du club:", error);
  }
}

/// POST
// Fonction pour créer une nouvelle dépense ou recette du club
export const createDepenseRecetteClub = async (depenseRecetteClubData) => {
  try {
    await axios.post(`${apiUrl}/CreateDepenseRecette`, depenseRecetteClubData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  catch (error) {
    console.error("Erreur lors de la création de la dépense ou recette du club:", error);
  }
}

/// PUT
// Fonction pour mettre à jour une dépense ou recette du club
export const updateDepenseRecetteClub = async (depenseRecetteClubId, depenseRecetteClubData) => {
  try {
    await axios.put(
      `${apiUrl}/UpdateDepenseRecette/${depenseRecetteClubId}`,
      depenseRecetteClubData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  catch (error) {
    console.error("Erreur lors de la mise à jour de la dépense ou recette du club:", error);
  }
}

/// DELETE
// Fonction pour supprimer une dépense ou recette du club
export const deleteDepenseRecetteClub = async (depenseRecetteClubId) => {
  try {
    await axios.delete(
      `${apiUrl}/DeleteDepenseRecette/${depenseRecetteClubId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  catch (error) {
    console.error("Erreur lors de la suppression de la dépense ou recette du club:", error);
  }
}