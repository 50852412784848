import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes, FaUpload } from 'react-icons/fa';
import Button from './../../../components/Button/Button';
import { updateNews, uploadNewsImage, deleteNews } from './../../../services/newsServices';
import { useUser } from './../../Home/UserContext';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateNewsModal = ({ isOpen, onClose, onCreateSuccess, news }) => {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image_url: '',
    author_id: user?.userId || '',
    is_active: true
  });
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newsId, setNewsId] = useState(null);

  useEffect(() => {
    if (news) {
      setFormData(f => ({ ...f, author_id: news.author_id, id: news.id }));
      setNewsId(news.id);
    }
  }, [news]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      content: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateNews(formData);
      onCreateSuccess();
      toast.success('Actualité mise à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'actualité', error);
      toast.error('Erreur lors de la mise à jour de l\'actualité.');
    }
  };

  const handleCancel = () => {
    onClose();
    setFormData({ title: '', content: '', image_url: '', author_id: user.userId || '', is_active: true });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    try {
      if (newsId && selectedFile) {
        const updatedNews = await uploadNewsImage(newsId, selectedFile);
        setFormData((prev) => ({ ...prev, image_url: updatedNews.image_url }));
        toast.success("Image mise à jour avec succès.");
        setIsUploadModalOpen(false);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image:", error);
      toast.error("Erreur lors de la mise à jour de l'image.");
    }
  };

  const handleDelete = async () => {
    try {
      if (formData.id) {
        await deleteNews(formData.id);
        toast.success("Actualité supprimée avec succès.");
        onClose();
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'actualité:", error);
      toast.error("Erreur lors de la suppression de l'actualité.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg dark:bg-gray-400">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Créer une Actualité</h3>
        <form onSubmit={handleSubmit}>
          <Button
            icon={FaUpload}
            text="Uploader une image"
            onClick={() => setIsUploadModalOpen(true)}
            className="btn-primary gap-2 mb-4"
          />
          <input
            type="text"
            name="title"
            placeholder="Titre de l'actualité"
            value={formData.title}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <ReactQuill
            value={formData.content}
            onChange={handleContentChange}
            className="mb-3"
          />
          <input
            type="url"
            name="image_url"
            placeholder="URL de l'image"
            value={formData.image_url}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            readOnly
          />
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={(e) => setFormData((prev) => ({ ...prev, is_active: e.target.checked }))}
              className="form-checkbox text-primary"
            />
            <span className="text-gray-700">Activer l'actualité</span>
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={handleCancel} type="button" className="btn-ghost gap-2" />
            {formData.id && (
              <Button text="Supprimer" icon={FaTimes} onClick={handleDelete} type="button" className="btn-ghost gap-2" />
            )}
          </div>
        </form>
      </div>

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Uploader une Image
            </h2>
            <form onSubmit={handleUploadSubmit} className="flex flex-col items-center space-y-4 w-full">
              <input type="file" onChange={handleFileChange} accept="image/*" className="my-2" />
              <div className="flex flex-auto gap-4">
                <Button text="Sauvegarder" type="submit" icon={FaSave} className="gap-2" />
                <Button text="Fermer" onClick={() => setIsUploadModalOpen(false)} icon={FaTimes} className="gap-2" />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateNewsModal;
