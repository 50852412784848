import React, { useState, useEffect } from "react";
import { getSettings } from "../../services/settingsServices"; // Assurez-vous que le chemin est correct

const About = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          setSettings(data[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres:", error);
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  if (loading) return <div>Chargement...</div>;

  return (
    <section
      className="py-12 bg-gray-100 dark:bg-gray-700 dark:text-white"
      id="about"
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4 overflow-hidden">
          <div className="px-4 w-full lg:w-1/3 overflow-hidden my-4">
            <img
              src={settings.logo_club}
              alt="Logo Club"
              className="block mx-auto drop-shadow-md"
            />
          </div>
          <div className="font-montserrat px-8 w-full lg:w-2/3 overflow-hidden my-4">
            <h2 className="text-3xl font-extrabold mb-4">
              {settings.about_title}
            </h2>
            <div
              className="text-justify mb-4 leading-relaxed"
              dangerouslySetInnerHTML={{ __html: settings.about_content }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
