import React, { useEffect, useState } from 'react';
import { getGroupName } from '../../../services/groupServices';
import getCategory from "../../../utils/getCategory";
import getWeightCategory from "../../../utils/getWeightCategory";
import { getAdherentById } from '../../../services/adherentServices';
import { getLicenceAssuranceUrl } from '../../../services/dossierServices';

const AdherentGroupCategory = ({ adherentId }) => {
  const [groupNames, setGroupNames] = useState(['Chargement...']);
  const [weightCategory, setWeightCategory] = useState('');
  const [ageCategory, setAgeCategory] = useState('');
  const [adherent, setAdherent] = useState({});
  const [licenceUrl, setLicenceUrl] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (adherentId) {
        const adherentData = await getAdherentById(adherentId);
        setAdherent(adherentData);

        const groupNames = await getGroupName(adherentId);
        setGroupNames(groupNames.length > 0 ? groupNames : ['N/A']);

        const currentAgeCategory = getCategory(adherentData.date_naissance);
        setAgeCategory(currentAgeCategory);

        const currentWeightCategory = getWeightCategory(adherentData.genre, currentAgeCategory, adherentData.poids);
        setWeightCategory(currentWeightCategory);

        const licenceLink = await getLicenceAssuranceUrl(adherentId);
        setLicenceUrl(licenceLink);
      }
    }

    fetchData();
  }, [adherentId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md flex flex-col items-center mt-4 mb-2">
      <h2 className="text-2xl font-semibold mb-4">Groupe & Catégories</h2>
      <div className="w-full">
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Groupes :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{groupNames.join(", ")}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Catégorie de poids :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{weightCategory}</p>
        </div>
        <div className="flex flex-wrap mb-2">
          <p className="w-1/2 font-medium text-gray-700 dark:text-gray-200">Catégorie d'âge :</p>
          <p className="w-1/2 text-gray-600 dark:text-gray-300">{ageCategory}</p>
        </div>
      </div>
    </div>
  );
};

export default AdherentGroupCategory;
