import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import MessagesReceived from "./MessagesReceived";
import MessagesSent from "./MessagesSent";
import GroupMessages from "./GroupMessages";
import CreateMessageForm from "./CreateMessageForm";
import Button from "./../../../components/Button/Button";
import { LuMessageSquarePlus } from "react-icons/lu";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";

const Messaging = () => {
  useScrollToTop();

  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [showForm, setShowForm] = useState(false);
  const isAdminOrTeacher = ["enseignant", "admin"].includes(userRole);
  const [refreshMessages, setRefreshMessages] = useState(false);

  const handleNewMessage = () => {
    setShowForm(true);
  };

  const handleSuccess = () => {
    setShowForm(false);
    setRefreshMessages(true); // Activer le rafraîchissement
  };

  const hideForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    console.log("userRole", userRole);
    console.log("userId", userId);
  }, [userRole, userId]);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Messagerie
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col items-center w-full px-4 mt-4 font-montserrat">
        <Button
          text="Nouveau Message"
          onClick={handleNewMessage}
          icon={LuMessageSquarePlus}
          className="w-xl gap-2 mb-2"
          hoverLabel="Nouveau Message"
        />
        <div className="w-full max-w-4xl px-2 mb-8">
          {showForm && (
            <CreateMessageForm
              userId={userId}
              userRole={userRole}
              onSuccess={handleSuccess}
              show={showForm}
              onHide={hideForm}
            />
          )}
        </div>
        <div className="w-full max-w-4xl px-2 mb-8">
          <MessagesReceived
            userId={userId}
            refresh={refreshMessages}
            setRefresh={setRefreshMessages}
          />
        </div>

        <div className="w-full max-w-4xl px-2 mb-8">
          <MessagesSent
            userId={userId}
            refresh={refreshMessages}
            setRefresh={setRefreshMessages}
          />
        </div>

        {isAdminOrTeacher && (
          <div className="w-full max-w-4xl px-2 mb-8">
            <GroupMessages userId={user?.userId} />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Messaging;
