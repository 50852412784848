import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/articles`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les articles
export const getArticles = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allarticles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des articles:", error);
  }
};

/// POST
// Fonction pour créer un nouvel article
export const createArticle = async (articleData) => {
  try {
    await axios.post(`${apiUrl}/CreateArticle`, articleData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la création de l'article:", error);
  }
};

/// PUT
// Fonction pour mettre à jour un article
export const updateArticle = async (articleId, articleData) => {
  try {
    await axios.put(`${apiUrl}/UpdateArticle/${articleId}`, articleData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'article:", error);
  }
};

// Fonction pour activer/désactiver un article
export const activateArticle = async (articleId) => {
  try {
    await axios.put(`${apiUrl}/activateArticle/${articleId}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error(
      "Erreur lors de l'activation/désactivation de l'article:",
      error
    );
  }
};

// Fonction pour mettre à jour l'ordre des articles
export const updateOrderArticle = async (articles) => {
  try {
    await axios.put(
      `${apiUrl}/updateOrderArticle`,
      { articles },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'ordre des articles:",
      error
    );
  }
};

/// PATCH
// Fonction pour mettre à jour l'image d'un article
export const updateArticleImage = async (articleId, file, token) => {
  const formData = new FormData();
  formData.append("image", file);
  try {
    const response = await axios.patch(
      `${apiUrl}/${articleId}/photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour de l'image de l'article:",
      error
    );
    return "";
  }
};

/// DELETE
// Fonction pour supprimer un article
export const deleteArticle = async (articleId) => {
  try {
    await axios.delete(`${apiUrl}/DeleteArticle/${articleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la suppression de l'article:", error);
  }
};
