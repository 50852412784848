import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getGenderDistribution } from "../../../services/dashboardServices";

const GenderDistributionChart = () => {
  const [data, setData] = useState([]);
  // Mise à jour des couleurs pour correspondre directement aux genres
  const COLORS = { Masculin: "#0088FE", Féminin: "#FF69B4" };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getGenderDistribution(); // Appel à la fonction qui retourne directement le tableau
        const transformedData = response.map((item) => ({
          // Utilisation directe de 'response'
          name: item.genre, // 'genre' est utilisé directement pour "Masculin" ou "Féminin"
          value: item.count,
        }));
        setData(transformedData);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de répartition par genre",
          error
        );
      }
    };

    fetchData();
  }, []);

  return (
    <div className="w-full" style={{ height: "50vh" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, percent }) =>
              `${name}: ${(percent * 100).toFixed(0)}%`
            }
            outerRadius="80%"
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                // Utilisez l'objet COLORS pour mapper le genre à sa couleur
                fill={COLORS[entry.name]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend verticalAlign="bottom" height={40} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenderDistributionChart;
