import React, { useState, useEffect } from "react";
import { getAttendanceList } from "../../../services/attendanceServices";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const AttendanceList = ({ adherentId }) => {
  const [attendances, setAttendances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAttendances = async () => {
      try {
        const data = await getAttendanceList(adherentId);
        setAttendances(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des présences:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttendances();
  }, [adherentId]);

  const groupAttendancesByMonth = (attendances) => {
    const grouped = {};

    attendances.forEach((attendance) => {
      const date = new Date(attendance.date_presence);
      const month = date.toLocaleString("default", { month: "long", year: "numeric" });

      if (!grouped[month]) {
        grouped[month] = [];
      }

      grouped[month].push(attendance);
    });

    return grouped;
  };

  const groupedAttendances = groupAttendancesByMonth(attendances);

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Présences et Absences</h3>
      {isLoading ? (
        <p>Chargement des données...</p>
      ) : (
        <div className="flex flex-wrap gap-5">
          {Object.keys(groupedAttendances).map((month) => (
            <div key={month} className="p-2 bg-gray-100 dark:bg-gray-700 rounded-md shadow-md w-full max-w-[170px]">
              <h4 className="text-lg font-semibold mb-2">{month}</h4>
              <div className="text-sm">
                <ul className="list-disc list-inside">
                  {groupedAttendances[month].map((attendance) => (
                    <li key={attendance.id} className="mb-1 flex items-center">
                      <span className="mr-2">
                        {new Date(attendance.date_presence).toLocaleDateString()}
                      </span>
                      {attendance.presence ? (
                        <FaCheckCircle className="text-green-500" />
                      ) : (
                        <FaTimesCircle className="text-red-500" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AttendanceList;
