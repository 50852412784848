import React, { useState, useEffect } from "react";
import { getGroupsList } from "./../../../services/groupServices";
import { getAllAdherentsListWithGroup } from "./../../../services/adherentServices";
import {
  getBeltHistoryByAdherent,
  getBeltsList,
} from "./../../../services/beltServices";
import { getSettings } from "./../../../services/settingsServices";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import useScrollToTop from "../../../hooks/useScollToTop";
import AdherentPassageCeinture from "./AdherentPassageCeinture";
import Modal from "./Modal.jsx";
import Loader from "./../../../utils/Loader.jsx";
import { ToastContainer } from "react-toastify";

const PassageCeintureGrid = () => {
  useScrollToTop();
  const [groups, setGroups] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const [filteredAdherents, setFilteredAdherents] = useState([]);
  const [beltHistories, setBeltHistories] = useState({});
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [filterGroup, setFilterGroup] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [selectedBeltFilter, setSelectedBeltFilter] = useState("");
  const [belts, setBelts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [activeSeason, setActiveSeason] = useState("");

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (activeSeason) {
      fetchData();
    }
  }, [activeSeason]);

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      if (settings && settings.length > 0) {
        const activeSeason = settings[0].saison_active;
        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);
        setActiveSeason(activeSeason);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des paramètres:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [groupList, adherentsList, beltsData] = await Promise.all([
        getGroupsList(),
        getAllAdherentsListWithGroup(),
        getBeltsList(),
      ]);
      setGroups(groupList);
      setAdherents(adherentsList);
      setBelts(beltsData);

      const beltHistoryPromises = adherentsList.map((adherent) =>
        getBeltHistoryByAdherent(adherent.id, activeSeason)
      );
      const beltHistoriesResults = await Promise.all(beltHistoryPromises);
      const beltHistories = beltHistoriesResults.reduce(
        (acc, history, index) => {
          acc[adherentsList[index].id] = history[0];
          return acc;
        },
        {}
      );
      setBeltHistories(beltHistories);
      filterAdherents(adherentsList, beltHistories);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      setLoading(false);
    }
  };

  const filterAdherents = (adherentsList = adherents, beltHistoriesList = beltHistories) => {
    const filtered = adherentsList.filter((adherent) => {
      const matchesGroup =
        filterGroup === "" ||
        adherent.Groups.some((g) => g.nom === filterGroup);
      const matchesSearch =
        searchTerm === "" ||
        adherent.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
        adherent.prenom.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesBelt =
        selectedBeltFilter === "" ||
        (beltHistoriesList[adherent.id] &&
          beltHistoriesList[adherent.id].BeltColor.nom === selectedBeltFilter);
      const matchesSeason = adherent.saison_active === activeSeason;
      return matchesGroup && matchesSearch && matchesBelt && matchesSeason;
    });
    setFilteredAdherents(filtered);
  };

  useEffect(() => {
    filterAdherents();
  }, [filterGroup, searchTerm, selectedBeltFilter, activeSeason]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGroupChange = (e) => {
    setFilterGroup(e.target.value);
  };

  const handleSeasonChange = (e) => {
    setActiveSeason(e.target.value);
  };

  const handleBeltHistoryChange = async () => {
    await fetchData();
  };

  const getStatusColor = (statut_inscription) => {
    switch (statut_inscription) {
      case "Licencié":
        return "bg-green-300";
      case "Licencié Hors Club":
        return "bg-green-200";
      case "Inscrit":
        return "bg-yellow-300";
      case "Cours d'essai":
        return "bg-red-300";
      case "Licencié - Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié et Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié":
        return "bg-slate-400";
      default:
        return "bg-base-100";
    }
  };

  const openModal = (adherentId) => {
    setSelectedAdherent(adherentId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdherent(null);
  };

  const getTitle = () => {
    if (searchTerm) {
      return `Saison ${activeSeason} | Résultat de la recherche par nom (${filteredAdherents.length})`;
    } else if (filterGroup && selectedBeltFilter) {
      return `Saison ${activeSeason} | ${filterGroup} | ${selectedBeltFilter} (${filteredAdherents.length})`;
    } else if (filterGroup) {
      return `Saison ${activeSeason} | ${filterGroup} (${filteredAdherents.length})`;
    } else if (selectedBeltFilter) {
      return `Saison ${activeSeason} | ${selectedBeltFilter} (${filteredAdherents.length})`;
    } else {
      return `Saison ${activeSeason} (${filteredAdherents.length})`;
    }
  };

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Passage de Ceinture
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mx-auto p-4 mb-4">
        <select
          onChange={handleSeasonChange}
          className="select select-bordered w-full max-w-xs mb-4 dark:text-black"
          value={activeSeason}
        >
          {seasons.map((season) => (
            <option key={season} value={season}>
              {season}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Rechercher par nom ou prénom"
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full max-w-xs mb-4"
        />
        <p className="italic text-gray-800 mb-1 pl-1 dark:text-white">Sélectionner un groupe</p>
        <select
          onChange={handleGroupChange}
          className="select select-bordered w-full max-w-xs mb-4 dark:text-black"
          value={filterGroup}
        >
          <option value="">Sélectionnez un groupe</option>
          {groups.map((group) => (
            <option key={group.id} value={group.nom}>
              {group.nom}
            </option>
          ))}
        </select>
        <select
          value={selectedBeltFilter}
          onChange={(e) => setSelectedBeltFilter(e.target.value)}
          className="select select-bordered w-full max-w-xs mb-4 dark:text-black"
        >
          <option value="">Toutes les ceintures</option>
          {belts.map((belt) => (
            <option key={belt.id} value={belt.nom}>
              {belt.nom}
            </option>
          ))}
        </select>

        {loading && <Loader />}

        <h3 className="text-2xl font-light mb-4 bg-slate-800 text-white p-2">
          {getTitle()}
        </h3>
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 mb-4">
          {filteredAdherents.map((adherent) => (
            <div
              key={adherent.id}
              className={`shadow-xl rounded-lg ${getStatusColor(
                adherent.statut_inscription
              )}`}
            >
              <figure className="aspect-auto">
                <img
                  src={
                    beltHistories[adherent.id]?.BeltColor?.belt_url
                      ? `/img/belt/${
                          beltHistories[adherent.id].BeltColor.belt_url
                        }`
                      : "https://res.cloudinary.com/dy5kblr32/image/upload/v1715614547/images/belts/ceinture_inconnue_grgifd.png"
                  }
                  alt={
                    beltHistories[adherent.id]?.BeltColor?.nom ||
                    "Ceinture inconnue"
                  }
                  className="object-cover w-sm h-sm rounded-t-lg cursor-pointer"
                  onClick={() => openModal(adherent.id)}
                />
              </figure>
              <div className="p-2 text-center">
                <p className="text-sm font-medium dark:text-black">
                  {adherent.nom} {adherent.prenom}
                </p>
                <p className="text-xs dark:text-black">
                  {beltHistories[adherent.id]?.BeltColor.nom}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <AdherentPassageCeinture
            adherentId={selectedAdherent}
            onBeltHistoryChange={handleBeltHistoryChange}
          />
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default PassageCeintureGrid;
