import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { getGroupsHome } from "../../services/groupServices";
import { getSettings } from "../../services/settingsServices";
import { useUser } from "./../Home/UserContext";

const GroupTable = () => {
  const { user } = useUser();
  const [groups, setGroups] = useState([]);
  const [openGroupIndex, setOpenGroupIndex] = useState(null); // État pour suivre le groupe ouvert
  const [saisonActive, setSaisonActive] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome();
        if (groupsData) {
          setGroups(groupsData);
        } else {
          setGroups([]);
          console.error("Aucune donnée reçue");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des groupes:", error);
      }
    };

    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (
          settingsData &&
          settingsData.length > 0 &&
          settingsData[0].saison_active
        ) {
          setSaisonActive(settingsData[0].saison_active);
        } else {
          console.error("Aucune saison active reçue");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des paramètres:", error);
      }
    };

    fetchGroups();
    fetchSettings();
  }, []);

  useEffect(() => {
    console.log("Saison Active:", saisonActive);
  }, [saisonActive]);

  const handleButtonClick = () => {
    if (user) {
      navigate("/ajouter-inscription-adherent");
    } else {
      navigate("/register");
    }
  };

  const getButtonText = () => {
    return user
      ? "Faire une inscription ou cours d'essai"
      : "S'inscrire pour faire une adhésion";
  };

  // Fonction pour déterminer la couleur de fond en fonction de la couleur stockée dans la base de données
  const getGroupColorClass = (color_groupe) => {
    return color_groupe || "bg-gray-400"; // Si aucune couleur n'est définie, utiliser une couleur par défaut
  };

  const handleGroupClick = (index) => {
    // Si le même groupe est cliqué, le fermer. Sinon, ouvrir le nouveau groupe.
    if (openGroupIndex === index) {
      setOpenGroupIndex(null);
    } else {
      setOpenGroupIndex(index);
    }
  };

  return (
    <div className="overflow-x-auto px-8 pb-8 bg-gray-100 dark:bg-gray-700 dark:text-white">
      <h2 className="text-3xl font-extrabold text-center mb-2 mt-4 font-montserrat">
        GROUPES - HORAIRES - TARIFS
      </h2>
      {saisonActive && (
        <h3 className="text-xl font-semibold text-center mb-8 mt-2 font-montserrat">
          pour la saison : {saisonActive}
        </h3>
      )}
      {groups.map((group, index) => (
        <div
          key={index}
          className={`max-w-[1280px] mx-auto collapse collapse-arrow rounded-box border mb-4 font-montserrat ${getGroupColorClass(
            group.color_groupe
          )} text-white`}
        >
          <input
            type="radio"
            name="accordion"
            className="peer"
            id={`group-${index}`}
            hidden
            checked={openGroupIndex === index} // Gestion de l'état du groupe ouvert
          />
          <label
            htmlFor={`group-${index}`}
            className="collapse-title text-xl font-medium cursor-pointer"
            onClick={() => handleGroupClick(index)} // Gérer le clic sur le label
          >
            {group.nom} |{" "}
            <span className="italic font-light text-base">
              {group.annees_naissance}
            </span>
          </label>
          <div className="collapse-content bg-white text-gray-800 dark:bg-gray-600 dark:text-white">
            <p className="mt-2 dark:text-slate-200">
              <strong className="dark:text-slate-400">Horaire 1 :</strong>{" "}
              {group.horaire1}
            </p>
            {group.horaire2 && (
              <p className="mt-2 dark:text-slate-200">
                <strong className="dark:text-slate-400">Horaire 2 :</strong>{" "}
                {group.horaire2}
              </p>
            )}
            <p className="mt-2 dark:text-slate-200">
              <strong className="dark:text-slate-400">Enseignant(s) :</strong>{" "}
              {group.enseignant1 && <span>{group.enseignant1}</span>}
              {group.enseignant1 && group.enseignant2 && " & "}
              {group.enseignant2 && <span>{group.enseignant2}</span>}
            </p>
            <p className="mt-2 dark:text-slate-200">
              <strong className="dark:text-slate-400">Tarifs : </strong>
              {group.tarif_licence}€ Licence - {group.tarif_cotisation}€
              Cotisation
            </p>
            <p className="mt-2 dark:text-slate-200">
              <strong className="dark:text-slate-400">
                Tarif Total Annuel :
              </strong>{" "}
              {parseFloat(group.tarif_licence) +
                parseFloat(group.tarif_cotisation)}
              €
            </p>
          </div>
        </div>
      ))}

      <Button
        text={getButtonText()}
        onClick={handleButtonClick}
        className="mx-auto"
      />
    </div>
  );
};

export default GroupTable;
