import React, { useState, useEffect } from "react";
import { FaSave, FaTimes, FaPlus, FaTrash } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { getAllUsers } from "../../../services/userServices";
import { getArticles } from "../../../services/articlesServices";
import { createSpecialAchat } from "../../../services/achatsServices";
import { getSettings } from "../../../services/settingsServices";
import { sendNotification } from "../../../services/notificationServices"; // Importer la fonction sendNotification
import { toast } from "react-toastify";

const CreateSpecialAchatModal = ({ isActive, onClose, refreshAchats }) => {
  const [users, setUsers] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedArticles, setSelectedArticles] = useState([{ article_id: "", quantite: 1 }]);
  const [saison, setSaison] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [defaultPhotoUrl, setDefaultPhotoUrl] = useState(""); // Ajouter l'état pour la photo par défaut
  const [token, setToken] = useState(""); // Ajout du token

  const resetForm = () => {
    setSelectedUser("");
    setSelectedArticles([{ article_id: "", quantite: 1 }]);
    setSaison("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersData, articlesData, settingsData] = await Promise.all([
          getAllUsers(),
          getArticles(),
          getSettings(),
        ]);
        setUsers(usersData);
        setArticles(articlesData);

        const settings = settingsData[0];
        const activeSeason = settings.saison_active;
        setSaison(activeSeason);

        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);

        const defaultPhoto = settings.photo_url_default_lic_cot;
        setDefaultPhotoUrl(defaultPhoto);

        // Supposons que le token soit stocké localement
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error.message);
        toast.error("Erreur lors du chargement des données.");
      }
    };

    fetchData();
  }, []);

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const handleAddArticle = () => {
    setSelectedArticles([...selectedArticles, { article_id: "", quantite: 1 }]);
  };

  const handleArticleChange = (index, key, value) => {
    const updatedArticles = selectedArticles.map((article, i) =>
      i === index ? { ...article, [key]: value } : article
    );
    setSelectedArticles(updatedArticles);
  };

  const handleRemoveArticle = (index) => {
    const updatedArticles = selectedArticles.filter((_, i) => i !== index);
    setSelectedArticles(updatedArticles);
  };

  const handleSendNotification = async (recipientId) => {
    const message = `Le responsable du club vous a affecté un nouveau règlement de licence-cotisation à régler sur la page Vos Commandes & Règlements.`;
    try {
      await sendNotification(message, recipientId, token);
      toast.success("Notification envoyée avec succès");
    } catch (error) {
      console.error("Erreur lors de l'envoi de la notification:", error);
      toast.error("Erreur lors de l'envoi de la notification");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const achatData = {
      user_achat_id: selectedUser,
      saison,
      articles: selectedArticles.map((article) => ({
        ...article,
        photo_url: defaultPhotoUrl, // Utiliser la photo par défaut lors de la soumission
      })),
    };

    try {
      await createSpecialAchat(achatData);
      await handleSendNotification(selectedUser); // Envoyer une notification à l'utilisateur
      onClose();
      refreshAchats();
      resetForm();
      toast.success("Règlement Licence-Cotisation ajouté avec succès.");
    } catch (error) {
      console.error("Erreur lors de la création du règlement Licence-Cotisation", error.message);
      toast.error("Erreur lors de la création du règlement Licence-Cotisation.");
    }
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Ajouter un Règlement Licence-Cotisation
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4 dark:text-black">
          <select
            className="select select-bordered w-full"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            required
          >
            <option value="">Sélectionnez un utilisateur</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.nom} {user.prenom}
              </option>
            ))}
          </select>
          <select
            className="select select-bordered w-full"
            value={saison}
            onChange={(e) => setSaison(e.target.value)}
            required
          >
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>
          <div>
            {selectedArticles.map((article, index) => (
              <div key={index} className="flex gap-2 mb-2 items-center">
                <select
                  className="select select-bordered flex-1"
                  value={article.article_id}
                  onChange={(e) => handleArticleChange(index, "article_id", e.target.value)}
                  required
                >
                  <option value="">Sélectionnez un article</option>
                  {articles.map((art) => (
                    <option key={art.id} value={art.id}>
                      {art.nom}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  className="input input-bordered flex-1"
                  value={article.quantite}
                  min="1"
                  onChange={(e) => handleArticleChange(index, "quantite", e.target.value)}
                  required
                />
                {article.article_id && (
                  <>
                    <img
                      src={articles.find((art) => art.id === parseInt(article.article_id))?.photo_url || defaultPhotoUrl}
                      alt="Article"
                      className="w-16 h-16 object-cover"
                    />
                    <button
                      type="button"
                      className="btn btn-ghost text-red-500"
                      onClick={() => handleRemoveArticle(index)}
                    >
                      <FaTrash />
                    </button>
                  </>
                )}
              </div>
            ))}
            <Button
              text="Ajouter un article"
              icon={FaPlus}
              onClick={handleAddArticle}
              className="btn-primary gap-2 mt-2"
              type="button"
            />
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={() => {
                resetForm();
                onClose();
              }}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSpecialAchatModal;
