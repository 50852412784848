import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import Button from "./../../../components/Button/Button";
import "react-toastify/dist/ReactToastify.css";
import { getDepensesRecettesClub, deleteDepenseRecetteClub } from "./../../../services/depensesrecettesclubServices";
import EditDepenseRecetteModal from "./EditDepenseRecetteModal";
import AddDepenseRecetteModal from "./AddDepenseRecetteModal";
import { GoFileSymlinkFile } from "react-icons/go";
import { format } from "date-fns";

const DepensesRecettes = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [depensesRecettesData, setDepensesRecettesData] = useState([]);
  const [saisons, setSaisons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSaison, setFilterSaison] = useState("");
  const [filterCategorie, setFilterCategorie] = useState("");
  const [filterTypeOperation, setFilterTypeOperation] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentDepenseRecette, setCurrentDepenseRecette] = useState(null);

  useEffect(() => {
    const fetchDepensesRecettes = async () => {
      try {
        const data = await getDepensesRecettesClub();
        if (Array.isArray(data)) {
          setDepensesRecettesData(data);
          setSaisons([...new Set(data.map(item => item.saison))]);
          setCategories([...new Set(data.map(item => item.categorie))]);
        } else {
          throw new Error("Les données reçues ne sont pas un tableau");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des dépenses et recettes du club:", error);
        toast.error("Erreur lors de la récupération des dépenses et recettes du club");
      }
    };

    fetchDepensesRecettes();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSaisonChange = (e) => {
    setFilterSaison(e.target.value);
  };

  const handleCategorieChange = (e) => {
    setFilterCategorie(e.target.value);
  };

  const handleTypeOperationChange = (e) => {
    setFilterTypeOperation(e.target.value);
  };

  const handleEditClick = (depenseRecette) => {
    setCurrentDepenseRecette(depenseRecette);
    setShowEditModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteDepenseRecetteClub(id);
      setDepensesRecettesData(depensesRecettesData.filter((depenseRecette) => depenseRecette.id !== id));
      toast.success("Dépense ou recette supprimée avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression de la dépense ou recette:", error);
      toast.error("Erreur lors de la suppression de la dépense ou recette");
    }
  };

  const filteredDepensesRecettes = depensesRecettesData.filter((depenseRecette) => {
    return (
      (filterSaison === "" || depenseRecette.saison === filterSaison) &&
      (filterCategorie === "" || depenseRecette.categorie === filterCategorie) &&
      (filterTypeOperation === "" || depenseRecette.type_operation === filterTypeOperation) &&
      (depenseRecette.designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
      depenseRecette.montant.toString().includes(searchTerm))
    );
  });

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Dépenses & Recettes
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="flex flex-col items-center w-full px-4 mt-4">
        <div className="flex flex-col sm:flex-row gap-2 w-full mb-4">
          <input
            type="text"
            placeholder="Rechercher par désignation ou montant"
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
          />
          <select
            value={filterSaison}
            onChange={handleSaisonChange}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
          >
            <option value="">Toutes les saisons</option>
            {saisons.map((saison) => (
              <option key={saison} value={saison}>{saison}</option>
            ))}
          </select>
          <select
            value={filterCategorie}
            onChange={handleCategorieChange}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
          >
            <option value="">Toutes les catégories</option>
            {categories.map((categorie) => (
              <option key={categorie} value={categorie}>{categorie}</option>
            ))}
          </select>
          <select
            value={filterTypeOperation}
            onChange={handleTypeOperationChange}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
          >
            <option value="">Tous</option>
            <option value="Dépense">Dépense</option>
            <option value="Recette">Recette</option>
          </select>
        </div>
        <Button
          text="Ajouter une Dépense ou Recette"
          onClick={handleAddClick}
          className="mb-4"
        />
        <DepensesRecettesTable
          depensesRecettesData={filteredDepensesRecettes}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      </div>
      <ToastContainer />
      {showEditModal && (
        <EditDepenseRecetteModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          depenseRecette={currentDepenseRecette}
          refreshList={() => {
            const fetchDepensesRecettes = async () => {
              try {
                const data = await getDepensesRecettesClub();
                setDepensesRecettesData(data);
              } catch (error) {
                console.error(
                  "Erreur lors de la récupération des dépenses et recettes du club:",
                  error
                );
                toast.error("Erreur lors de la récupération des dépenses et recettes du club");
              }
            };
            fetchDepensesRecettes();
          }}
        />
      )}
      {showAddModal && (
        <AddDepenseRecetteModal
          isOpen={showAddModal}
          onClose={handleCloseAddModal}
          refreshList={() => {
            const fetchDepensesRecettes = async () => {
              try {
                const data = await getDepensesRecettesClub();
                setDepensesRecettesData(data);
              } catch (error) {
                console.error(
                  "Erreur lors de la récupération des dépenses et recettes du club:",
                  error
                );
                toast.error("Erreur lors de la récupération des dépenses et recettes du club");
              }
            };
            fetchDepensesRecettes();
          }}
        />
      )}
    </div>
  );
};

const DepensesRecettesTable = ({ depensesRecettesData, onEditClick, onDeleteClick }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-500 shadow-lg max-w-full mx-auto font-montserrat">
      <div className="overflow-x-auto">
        <table className="table-auto bg-white dark:bg-slate-300 font-montserrat w-full mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Photo
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Désignation
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Montant
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Bénéficiaire
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Catégorie
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Type d'Opération
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Type de Règlement
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Lien
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {depensesRecettesData.length > 0 ? (
              depensesRecettesData.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm"
                >
                  <td className="px-5 py-3 text-center">{format(new Date(item.date_operation), 'dd/MM/yy')}</td>
                  <td className="px-5 py-3 flex justify-center">
                    {item.photo_url && (
                      <img
                        src={item.photo_url}
                        alt={item.designation}
                        className="w-12 h-12 object-cover"
                      />
                    )}
                  </td>
                  <td className="px-5 py-3">{item.designation}</td>
                  <td className="px-5 py-3">{item.montant.toFixed(2)}€</td>
                  <td className="px-5 py-3">{item.beneficiaire}</td>
                  <td className="px-5 py-3">{item.categorie}</td>
                  <td className="px-5 py-3">
                    <span className={`px-2 py-1 rounded-full ${item.type_operation === "Dépense" ? "bg-red-500 text-white" : "bg-green-500 text-white"}`}>
                      {item.type_operation}
                    </span>
                  </td>
                  <td className="px-5 py-3">{item.type_reglement}</td>
                  <td className="px-5 py-3 text-center">
                    {item.lien_url && (
                      <a href={item.lien_url} target="_blank" rel="noopener noreferrer">
                        <GoFileSymlinkFile className="w-6 h-6 text-blue-500" />
                      </a>
                    )}
                  </td>
                  <td className="px-5 py-3 flex items-center justify-center space-x-2">
                    <ButtonEdit onClick={() => onEditClick(item)} />
                    <ButtonDelete onClick={() => onDeleteClick(item.id)} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center py-4 px-6 text-gray-800">
                  Aucune dépense ou recette trouvée.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DepensesRecettes;
