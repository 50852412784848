import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/grade-cn`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour obtenir tous les grades CN
export const getAllGrades = async () => {
  try {
    const response = await axios.get(`${apiUrl}/all-grades`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des grades CN:", error);
  }
};

// Fonction pour obtenir un grade CN par son ID
export const getGradeById = async (gradeCNId) => {
  try {
    const response = await axios.get(`${apiUrl}/grade/${gradeCNId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du grade CN:", error);
  }
};

// Fonction pour obtenir les grades CN d'un adhérent (adherent_id) recupérer par req.params
export const getGradesByAdherentId = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/grades-by-adherent/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des grades CN de l'adhérent:",
      error
    );
  }
};

/// POST
// Fonction pour créer un nouveau grade CN
export const createGrade = async (gradeCNData) => {
  try {
    const response = await axios.post(`${apiUrl}/create-grade`, gradeCNData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du grade CN:", error);
  }
};

/// PUT
// Fonction pour mettre à jour un grade CN
export const updateGrade = async (gradeCNId, gradeCNData) => {
  try {
    const response = await axios.put(
      `${apiUrl}/update-grade/${gradeCNId}`,
      gradeCNData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du grade CN:", error);
  }
};

/// DELETE
// Fonction pour supprimer un grade CN
export const deleteGrade = async (gradeCNId) => {
  try {
    const response = await axios.delete(`${apiUrl}/delete-grade/${gradeCNId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du grade CN:", error);
  }
};
