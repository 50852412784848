import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { addTask } from '../../../services/tachesServices';
import { getAllUsers } from '../../../services/userServices';
import { sendNotification } from '../../../services/notificationServices'; // Importez la fonction d'envoi de notification
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useUser } from '../../Home/UserContext'; // Importez le contexte utilisateur

const AddTaskModal = ({ isOpen, onClose, refreshList }) => {
  const { user } = useUser(); // Utilisez le contexte utilisateur pour obtenir les informations de l'utilisateur actuel
  const [formData, setFormData] = useState({
    categorie: '',
    titre: '',
    contenu: '',
    user_id: '',
    statut: 'A faire',
  });

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        const filteredUsers = response.filter(user => user.role_id === 1 || user.role_id === 3);
        setUsers(filteredUsers);
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors du chargement des utilisateurs:", error);
        toast.error("Erreur lors du chargement des utilisateurs.");
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addTask(formData);
      const message = `${user.nom} ${user.prenom} vous a attribué une nouvelle tâche : ${formData.titre}`;
      await sendNotification(message, formData.user_id, user.token); // Envoyez la notification ici
      refreshList();
      onClose();
      toast.success('Tâche ajoutée avec succès');
    } catch (error) {
      console.error("Erreur lors de l'ajout de la tâche", error);
      toast.error("Erreur lors de l'ajout de la tâche");
    }
  };

  if (!isOpen) return null;

  if (isLoading) {
    return <p>Chargement des utilisateurs...</p>;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 dark:text-black p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Ajouter une Tâche
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="categorie" placeholder="Catégorie" value={formData.categorie} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="titre" placeholder="Titre" value={formData.titre} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <ReactQuill theme="snow" value={formData.contenu} onChange={(value) => setFormData((prev) => ({ ...prev, contenu: value }))} />
          <div className="flex flex-col mb-3">
            <label className="mb-2 font-semibold">Utilisateur:</label>
            <select name="user_id" value={formData.user_id} onChange={handleChange} className="select select-bordered w-full max-w-xs" required>
              <option value="">Sélectionnez un utilisateur</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.nom} {user.prenom}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col mb-3">
            <label className="mb-2 font-semibold">Statut:</label>
            <select name="statut" value={formData.statut} onChange={handleChange} className="select select-bordered w-full max-w-xs" required>
              <option value="A faire">A faire</option>
              <option value="En cours">En cours</option>
              <option value="Urgent">Urgent</option>
              <option value="Effectué">Effectué</option>
            </select>
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTaskModal;
