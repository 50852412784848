import React, { useState, useEffect } from 'react';
import { getAllAdherentsList } from '../../../services/adherentServices';
import { assignAdherentToUser, removeAdherentFromUser } from '../../../services/userServices';
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';

const ManageAdherentsModal = ({ isOpen, onClose, userId, refresh }) => {
  const [adherents, setAdherents] = useState([]);
  const [selectedAdherents, setSelectedAdherents] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredAdherents, setFilteredAdherents] = useState([]);


  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const fetchedAdherents = await getAllAdherentsList();
        setAdherents(fetchedAdherents);
        setFilteredAdherents(fetchedAdherents);
      } catch (error) {
        toast.error("Erreur lors du chargement des adhérents");
      }
    };

    fetchAdherents();
  }, []);

  useEffect(() => {
    setFilteredAdherents(adherents.filter(adherent =>
      adherent.nom.toLowerCase().includes(filter.toLowerCase()) ||
      adherent.prenom.toLowerCase().includes(filter.toLowerCase())
    ));
  }, [filter, adherents]);

  const handleAddAdherent = async (adherentId) => {
    try {
      await assignAdherentToUser(userId, adherentId);
      refresh(); // Refresh the user's adherent list
      toast.success("Adhérent assigné avec succès");
    } catch (error) {
      toast.error("Erreur lors de l'assignation de l'adhérent");
    }
  };

  const handleRemoveAdherent = async (adherentId) => {
    try {
      await removeAdherentFromUser(userId, adherentId);
      refresh(); // Refresh the user's adherent list
      toast.success("Adhérent retiré avec succès");
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'adhérent");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Gérer les Adhérents</h3>
        <input
          type="text"
          placeholder="Rechercher par nom ou prénom"
          value={filter}
          onChange={e => setFilter(e.target.value)}
          className="input input-bordered w-full mb-4"
        />
        <div className="flex justify-end space-x-2 mt-2 mb-4">
          <Button text="Fermer" onClick={onClose} className="btn-ghost" />
        </div>
        <ul>
           {filteredAdherents.map(adherent => (
            <li key={adherent.id} className="flex justify-between p-2 border-b">
              {adherent.nom} {adherent.prenom}
              <div>
                <button onClick={() => handleAddAdherent(adherent.id)} className="btn btn-sm btn-success mx-1">Ajouter</button>
                <button onClick={() => handleRemoveAdherent(adherent.id)} className="btn btn-sm btn-error">Retirer</button>
              </div>
            </li>
          ))}
        </ul>
        
      </div>
    </div>
  );
};

export default ManageAdherentsModal;
