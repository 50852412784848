import React, { useState, useEffect } from 'react';
import { updateAdherentGroup, updateAdherent } from './../../../services/adherentServices';
import { getActiveGroupsList } from './../../../services/groupServices'; // Assurez-vous que le chemin est correct
import Button from '../../../components/Button/Button';
import { FaSave, FaTimes } from 'react-icons/fa';

const RenewalModal = ({ isOpen, onClose, adherent, nextSeason }) => {
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [updatedBirthYears, setUpdatedBirthYears] = useState("");
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const activeGroups = await getActiveGroupsList();
        setGroups(activeGroups);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes actifs:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleGroupChange = (e) => {
    const groupId = e.target.value;
    setSelectedGroupId(groupId);

    const selectedGroup = groups.find(group => group.id === parseInt(groupId, 10));
    if (selectedGroup) {
      const { annees_naissance } = selectedGroup;
      const birthYearsFormat = /^\d{4}$|^\d{4}-\d{4}$/;

      if (!birthYearsFormat.test(annees_naissance)) {
        setUpdatedBirthYears(annees_naissance);
      } else {
        setUpdatedBirthYears(annees_naissance);  // Ne pas ajuster les années
      }
    } else {
      setUpdatedBirthYears("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedGroupId) {
      alert("Veuillez sélectionner un groupe.");
      return;
    }

    const groupId = parseInt(selectedGroupId, 10);
    if (isNaN(groupId)) {
      alert("L'ID de groupe sélectionné n'est pas valide.");
      return;
    }

    const updatedAdherent = {
      saison_active: nextSeason,
      statut_inscription: "Inscrit",
    };

    await updateAdherent(adherent.id, updatedAdherent);
    await updateAdherentGroup(adherent.id, groupId);
    
    const selectedGroup = groups.find(group => group.id === groupId);
    onClose(true, selectedGroup);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Renouvellement pour la saison {nextSeason}
        </h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium text-gray-700">Nouvelle Saison</label>
            <input
              type="text"
              value={nextSeason}
              readOnly
              className="input input-bordered w-full mb-4"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Sélectionnez un Groupe</label>
            <select
              value={selectedGroupId}
              onChange={handleGroupChange}
              className="select select-bordered w-full mb-4"
            >
              <option value="">Sélectionnez un groupe</option>
              {groups && groups.map(group => (
                <option key={group.id} value={group.id}>{group.nom}</option>
              ))}
            </select>
          </div>
          {updatedBirthYears && (
            <div className="mb-4">
              <p className="text-sm text-gray-700">Années de naissance pour la saison {nextSeason}: {updatedBirthYears}</p>
            </div>
          )}
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={() => onClose(false)} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default RenewalModal;
