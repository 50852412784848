// /client/src/components/StatutReglementsDiagramme.js
import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const STATUS_DETAILS = {
  Réglé: "#00C49F",
  "En cours": "#FFBB28",
  "Non réglé": "#FF8042",
  Problème: "#FF5555",
};

const StatutReglementsDiagramme = ({ data }) => {
  const preparedData = data.map((item) => ({
    name: item.statut,
    value: parseFloat(item.percent),
    color: STATUS_DETAILS[item.statut],
  }));

  return (
    <div className="flex flex-col md:flex-row items-center justify-center max-w-4xl mx-auto gap-4">
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={preparedData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          >
            {preparedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="flex flex-col w-full md:w-1/2 px-4">
        {preparedData.map((item, index) => (
          <div
            key={index}
            className="mb-4 p-4 text-black rounded-lg shadow"
            style={{
              backgroundColor: item.color,
              width: `${item.value}%`, // Ajuster dynamiquement la largeur en fonction du pourcentage
              minWidth: "5%", // S'assurer que les cartes ont au moins une largeur minimale
            }}
          >
            <div className="text-3xl font-bold text-right">{`${item.value}%`}</div>
            <div className="text-xl text-right">{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatutReglementsDiagramme;
