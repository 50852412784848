import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import { FaSave, FaTimes, FaPlus, FaMinus } from "react-icons/fa";
import {
  addAdherentToGroup,
  removeAdherentFromGroup,
} from "../../../services/adherentServices";
import { getGroupsList } from "../../../services/groupServices";
import { toast } from "react-toastify";

const ManageGroupModal = ({
  isOpen,
  onClose,
  adherentId,
  groups,
  adherentGroups,
  refreshData,
}) => {
  const [selectedGroupToAdd, setSelectedGroupToAdd] = useState("");
  const [selectedGroupToRemove, setSelectedGroupToRemove] = useState("");
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupList = await getGroupsList();
      setGroupList(groupList);
    };

    fetchGroups();
    setSelectedGroupToAdd("");
    setSelectedGroupToRemove("");
  }, [isOpen]);

  const getGroupIdByName = (groupName) => {
    const group = groupList.find((g) => g.nom === groupName);
    return group ? group.id : null;
  };

  const handleAddGroup = async () => {
    if (selectedGroupToAdd) {
      try {
        await addAdherentToGroup(adherentId, selectedGroupToAdd);
        toast.success("Adhérent ajouté au groupe avec succès");
        refreshData();
        onClose();
      } catch (error) {
        toast.error("Erreur lors de l'ajout de l'adhérent au groupe");
        console.error("Erreur lors de l'ajout de l'adhérent au groupe:", error);
      }
    }
  };

  const handleRemoveGroup = async () => {
    if (selectedGroupToRemove) {
      try {
        const groupIdToRemove = getGroupIdByName(selectedGroupToRemove);
        if (!groupIdToRemove) {
          toast.error("Erreur: le groupe sélectionné est introuvable");
          return;
        }
        console.log("Adherent ID:", adherentId);
        console.log("Group ID to remove:", groupIdToRemove);
        await removeAdherentFromGroup(adherentId, groupIdToRemove);
        toast.success("Adhérent supprimé du groupe avec succès");
        refreshData();
        onClose();
      } catch (error) {
        toast.error("Erreur lors de la suppression de l'adhérent du groupe");
        console.error(
          "Erreur lors de la suppression de l'adhérent du groupe:",
          error
        );
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Gérer les Groupes
        </h3>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Ajouter au groupe:
          </label>
          <select
            name="groupToAdd"
            value={selectedGroupToAdd}
            onChange={(e) => {
              console.log("Selected group to add ID:", e.target.value); // Log ID
              setSelectedGroupToAdd(e.target.value);
            }}
            className="input input-bordered w-full"
          >
            <option value="">Sélectionner un groupe</option>
            {groups
              .filter(
                (group) => !adherentGroups.some((ag) => ag.id === group.id)
              )
              .map((group) => (
                <option key={group.id} value={group.id}>
                  {group.nom}
                </option>
              ))}
          </select>
          <Button
            onClick={handleAddGroup}
            className="btn-primary flex items-center gap-2 mt-4"
            text="Ajouter"
            icon={FaPlus}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Supprimer du groupe:
          </label>
          <select
            name="groupToRemove"
            value={selectedGroupToRemove}
            onChange={(e) => {
              console.log("Selected group to remove ID:", e.target.value); // Log ID
              setSelectedGroupToRemove(e.target.value);
            }}
            className="input input-bordered w-full"
          >
            <option value="">Sélectionner un groupe</option>
            {adherentGroups.map((group) => (
              <option key={group.id} value={group.nom}>
                {group.nom}
              </option>
            ))}
          </select>
          <Button
            onClick={handleRemoveGroup}
            className="btn-error flex items-center gap-2 mt-4"
            text="Supprimer"
            icon={FaMinus}
          />
        </div>
        <Button
          onClick={onClose}
          className="btn-ghost flex items-center gap-2 mt-4"
          text="Fermer"
          icon={FaTimes}
        />
      </div>
    </div>
  );
};

export default ManageGroupModal;
