import React, { useState } from 'react';
import { FaSave, FaTimes, FaUpload } from 'react-icons/fa';
import Button from './../../../components/Button/Button';
import { addPhoto, updatePhoto, updatePhotoImage, deletePhoto } from './../../../services/photoServices'; 
import { toast } from 'react-toastify';

const AddPhotoToAlbumModal = ({ isOpen, onClose, galleryId, photoId }) => {
  const [photoUrl, setPhotoUrl] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();
    try {
      if (photoId && selectedFile) {
        const updatedPhoto = await updatePhotoImage(photoId, selectedFile);
        setPhotoUrl(updatedPhoto.photo_url);
        toast.success("Image mise à jour avec succès.");
        setIsUploadModalOpen(false);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image:", error);
      toast.error("Erreur lors de la mise à jour de l'image.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const photoData = {
        gallerie_id: galleryId,
        description: description,
        is_active: isActive
      };

      if (photoId) {
        await updatePhoto(photoId, photoData);
        toast.success("Photo mise à jour avec succès.");
      } else {
        const newPhoto = await addPhoto(photoData);
        setPhotoUrl(newPhoto.photo_url);
        toast.success("Photo ajoutée avec succès.");
      }

      onClose();
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de la photo:", error);
      toast.error("Erreur lors de la sauvegarde de la photo.");
    }
  };

  const handleCancel = async () => {
    try {
      if (photoId) {
        await deletePhoto(photoId);
        toast.success("Photo supprimée avec succès.");
      }
      onClose();
    } catch (error) {
      console.error("Erreur lors de la suppression de la photo:", error);
      toast.error("Erreur lors de la suppression de la photo.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Ajouter une Photo dans un Album
        </h3>
        <form onSubmit={handleSubmit}>
          <Button
            icon={FaUpload}
            text="Uploader une image"
            onClick={() => setIsUploadModalOpen(true)}
            className="btn-primary gap-2 mb-4"
          />
          <input
            type="text"
            value={photoUrl}
            placeholder="URL de la photo"
            className="input input-bordered w-full mb-4 dark:text-black dark:bg-slate-200"
            readOnly
          />
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder="Description"
            className="textarea textarea-bordered w-full mb-4 dark:text-black dark:bg-slate-200"
            required
          />
          <label className="label cursor-pointer flex justify-start items-center space-x-2 mb-4">
            <input
              type="checkbox"
              checked={isActive}
              onChange={e => setIsActive(e.target.checked)}
              className="checkbox checkbox-primary"
            />
            <span className="label-text">Actif</span>
          </label>
          <div className="flex flex-row justify-center gap-4">
            <Button text="Ajouter" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={handleCancel} className="btn-ghost gap-2" />
          </div>
        </form>
      </div>

      {isUploadModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-slate-200 dark:bg-slate-400 p-6 rounded-lg shadow-xl max-w-lg w-full">
            <h2 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
              Uploader une Image
            </h2>
            <form
              onSubmit={handleUploadSubmit}
              className="flex flex-col items-center space-y-4 w-full"
            >
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="my-2"
              />
              <div className="flex flex-auto gap-4">
                <Button
                  text="Sauvegarder"
                  type="submit"
                  icon={FaSave}
                  className="gap-2"
                />
                <Button
                  text="Fermer"
                  onClick={() => setIsUploadModalOpen(false)}
                  icon={FaTimes}
                  className="gap-2"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPhotoToAlbumModal;
