import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/techniques`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les techniques
export const getAllTechniques = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allTechniques`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des techniques:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};


