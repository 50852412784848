import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { countVisitesByDate } from "../../../services/visitesServices"; // Assurez-vous que le chemin est correct

const VisitesLineChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchVisites = async () => {
      try {
        const response = await countVisitesByDate();
        setData(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de visites", error);
      }
    };

    fetchVisites();
  }, []);

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 dark:bg-gray-900 dark:text-gray-200">
      <h3 className="text-lg font-bold text-white mb-4">Évolution des Visites</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date_visite" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VisitesLineChart;
