import React from "react";
import { useLocation } from "react-router-dom";
import useScrollToTop from "./../../../hooks/useScollToTop";
import "./AttestationPage.css";

const AttestationPage = () => {
  useScrollToTop();
  const location = useLocation();
  const {
    prenom,
    nom,
    dateNaissance,
    adresse,
    cp,
    commune,
    cotisation,
    licence,
    reductionFoyer,
    passSport,
    reductionSaison,
    typeReglement,
    nbReglement,
    statutReglement,
    observations,
    datePaiement,
    president,
    association,
    logo,
    adresse_club,
    tel_club,
    mail_club,
    siteweb_club,
    siret_club,
    saison_active,
  } = location.state || {};

  if (!location.state) {
    return <div>Pas de données disponibles pour cette attestation.</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  const totalMontant =
    parseFloat(cotisation) +
    parseFloat(licence) +
    (parseFloat(reductionFoyer) + parseFloat(passSport) + parseFloat(reductionSaison));

  return (
    <div className="container_print mx-auto py-[150px] px-4">
      <div className="printable-area mx-auto max-w-4xl">
        <div className="text-center mb-4">
          <img src={logo} alt="Logo Club" className="mx-auto mb-2 object-cover w-32 h-32" />
          <h1 className="text-xl font-bold">{association}</h1>
          <p className="text-md">{saison_active}</p>
        </div>
        <div className="bg-white p-4 shadow-lg rounded-lg mb-4">
          <h2 className="text-lg font-bold mb-2">ATTESTATION D’INSCRIPTION</h2>
          <p>
            Je soussigné <strong>{president}</strong>,<br />
            Président de l’association <strong>{association}</strong>,<br />
            certifie que :
          </p>
          <p className="mt-2">
            Prénom : <strong>{prenom}</strong>
            <br />
            NOM : <strong>{nom}</strong>
            <br />
            Né(e) le : <strong>{dateNaissance}</strong>
            <br />
            Adresse : <strong>{adresse}, {cp}, {commune}</strong>
          </p>
          <p className="mt-2">
            est adhérent(e) de notre association,
            <br />
            pour la période de la saison : <strong>{saison_active}</strong>
          </p>
          <div className="mt-2">
            <p>
              Cotisation : <strong>{cotisation} €</strong>
            </p>
            <p>
              Licence assurance : <strong>{licence} €</strong>
            </p>
            {parseFloat(reductionFoyer) !== 0 && (
              <p>Réduction Foyer : <strong>{reductionFoyer} €</strong></p>
            )}
            {parseFloat(passSport) !== 0 && (
              <p>Pass Sport : <strong>{passSport} €</strong></p>
            )}
            {parseFloat(reductionSaison) !== 0 && (
              <p>Réduction Saison : <strong>{reductionSaison} €</strong></p>
            )}
            <p className="mt-2">
              Montant total : <strong>{totalMontant.toFixed(2)} €</strong>
            </p>
            {typeReglement && (
              <p>Type de règlement : <strong>{typeReglement}</strong></p>
            )}
            {nbReglement !== 0 && (
              <p>Nombre de règlements : <strong>{nbReglement}</strong></p>
            )}
            {statutReglement && (
              <p>Statut de règlement : <strong>{statutReglement}</strong></p>
            )}
            {observations && (
              <p>Observations : <strong>{observations}</strong></p>
            )}
          </div>
          <p className="mt-2">Facture acquittée.</p>
          <p className="mt-4">
            Fait le <strong>{datePaiement}</strong>
            <br />
            <span>{president}</span>
          </p>
        </div>
        <div className="text-center mt-4 text-sm">
          <p className="font-bold">{association}</p>
          <p>{adresse_club}</p>
          <p>Tél : {tel_club}</p>
          <p>Email : {mail_club}</p>
          <p>Site Web : {siteweb_club}</p>
          <p>SIRET : {siret_club}</p>
        </div>
      </div>
      <div className="text-center mt-4">
        <button
          onClick={handlePrint}
          className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700"
        >
          Imprimer / Générer PDF
        </button>
      </div>
    </div>
  );
};

export default AttestationPage;
