import React, { useState, useEffect } from "react";
import {
  getNotificationsByUser,
  deleteNotificationById,
  markNotificationAsRead,
  markAllNotificationsAsRead,
} from "../../../services/notificationServices";
import { useNotification } from "./../../../hooks/NotificationContext";
import { RiCheckDoubleFill } from "react-icons/ri";
import Button from "../../../components/Button/Button";
import ButtonEyeNoRead from "../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../components/Button/ButtonEyeRead";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import { FaBell } from "react-icons/fa";
import { toast } from "react-toastify";
import { useUser } from "../../Home/UserContext";
import NotificationCard from "./NotificationCard";
import "./styles.css";

const NotificationsDisplay = ({ userId }) => {
  const { user } = useUser();
  const [notifications, setNotifications] = useState([]);
  const { unreadCount, setUnreadCount } = useNotification();

  useEffect(() => {
    if (!user || !user.userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    const loadNotifications = async () => {
      try {
        const data = await getNotificationsByUser(user.userId);
        setNotifications(data);
      } catch (error) {
        console.error("Erreur lors du chargement des notifications:", error);
        toast.error("Erreur lors du chargement des notifications.");
      }
    };

    loadNotifications();
  }, [user]);

  const loadNotifications = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getNotificationsByUser(userId);
      setNotifications(data);
    } catch (error) {
      console.error("Erreur lors du chargement des notifications:", error);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotificationById(notificationId);
      loadNotifications(); // Recharger les notifications après la suppression
      toast.success("Notification supprimée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression de la notification:", error);
      toast.error("Erreur lors de la suppression de la notification.");
    }
  };

  const toggleReadStatus = async (notificationId) => {
    try {
      const notification = notifications.find((n) => n.id === notificationId);
      if (!notification) {
        console.error("Notification non trouvée");
        return;
      }

      const currentReadStatus = notification.read_notification;

      await markNotificationAsRead(notificationId);

      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notificationId
            ? { ...n, read_notification: !n.read_notification }
            : n
        )
      );

      setUnreadCount((prevCount) =>
        currentReadStatus ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllNotificationsAsRead(userId);
      setNotifications(
        notifications.map((n) => ({ ...n, read_notification: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error(
        "Erreur lors du marquage de toutes les notifications comme lues:",
        error
      );
      toast.error(
        "Erreur lors du marquage de toutes les notifications comme lues."
      );
    }
  };

  return (
    <div className="overflow-x-auto shadow-xl font-montserrat">
      <div className="flex justify-between">
        <h3 className="card-title mb-2 ml-2">
          <div className="relative">
            <FaBell
              className={`icon ${unreadCount > 0 ? "" : ""}`}
              title="Notifications"
            />
            {unreadCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
                {unreadCount}
              </span>
            )}
          </div>
          Notifications
        </h3>
        <Button
          hoverLabel="Tout Marquer Comme Lu"
          onClick={() => handleMarkAllAsRead(userId)}
          icon={RiCheckDoubleFill}
          className="mb-4 align-right"
        />
      </div>
      {notifications.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-50 divide-y divide-gray-400">
              <thead className="bg-gray-50">
                <tr className="bg-slate-400">
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Notification
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {notifications.map((notification) => (
                  <tr
                    key={notification.id}
                    className={`hover:bg-gray-300 ${
                      notification.read_notification
                        ? "bg-gray-200"
                        : "bg-gray-100"
                    }`}
                  >
                    <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
                      {new Date(notification.created_at).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500 max-w-xs overflow-hidden text-ellipsis">
                      {notification.message}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium gap-2 flex">
                      {notification.read_notification ? (
                        <ButtonEyeRead
                          onClick={() => toggleReadStatus(notification.id)}
                          hoverLabel="Marquer comme non lu"
                        />
                      ) : (
                        <ButtonEyeNoRead
                          onClick={() => toggleReadStatus(notification.id)}
                          hoverLabel="Marquer comme lu"
                        />
                      )}
                      <ButtonDelete
                        onClick={() =>
                          handleDeleteNotification(notification.id)
                        }
                        hoverLabel="Supprimer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
                toggleReadStatus={toggleReadStatus}
                handleDeleteNotification={handleDeleteNotification}
              />
            ))}
          </div>
        </>
      ) : (
        <p className="text-center">Pas encore de notifications.</p>
      )}
    </div>
  );
};

export default NotificationsDisplay;
