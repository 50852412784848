import React, { useState, useEffect } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "./../../../components/Button/Button";
import { updateGrade } from "../../../services/gradCnServices";
import { getAllAdherentsList } from "../../../services/adherentServices";
import { toast } from "react-toastify";

const EditGradeModal = ({ isOpen, onClose, grade, refreshList }) => {
  const [formValues, setFormValues] = useState({
    adherent_id: "",
    dominante: "",
    uv1: false,
    uv2: false,
    uv3_points: "",
    uv3: false,
    uv4: false,
  });
  const [adherents, setAdherents] = useState([]);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const adherentsList = await getAllAdherentsList();
        setAdherents(adherentsList);
      } catch (error) {
        console.error("Erreur lors du chargement des adhérents:", error);
        toast.error("Erreur lors du chargement des adhérents.");
      }
    };

    fetchAdherents();
  }, []);

  useEffect(() => {
    if (grade) {
      setFormValues({
        adherent_id: grade.adherent_id,
        dominante: grade.dominante,
        uv1: grade.uv1 === 1,
        uv2: grade.uv2 === 1,
        uv3_points: grade.uv3_points,
        uv3: grade.uv3 === 1,
        uv4: grade.uv4 === 1,
      });
    }
  }, [grade]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateGrade(grade.id, formValues);
      toast.success("Grade mis à jour avec succès");
      refreshList();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du grade", error);
      toast.error("Erreur lors de la mise à jour du grade");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 dark:text-black font-montserrat">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Éditer un Grade CN
        </h3>
        <form onSubmit={handleSubmit}>
          <select
            name="adherent_id"
            className="block w-full p-2 border border-gray-300 rounded mb-3"
            value={formValues.adherent_id}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un adhérent</option>
            {adherents.map((adherent) => (
              <option key={adherent.id} value={adherent.id}>
                {adherent.nom} {adherent.prenom}
              </option>
            ))}
          </select>
          <select
            name="dominante"
            className="block w-full p-2 border border-gray-300 rounded mb-3"
            value={formValues.dominante}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez une dominante</option>
            <option value="Compétition">Compétition</option>
            <option value="Technique">Technique</option>
          </select>
          <div className="flex items-center mb-3">
            <input
              type="checkbox"
              name="uv1"
              checked={formValues.uv1}
              onChange={handleChange}
              className="toggle toggle-primary"
            />
            <span className="ml-2">UV1</span>
          </div>
          <div className="flex items-center mb-3">
            <input
              type="checkbox"
              name="uv2"
              checked={formValues.uv2}
              onChange={handleChange}
              className="toggle toggle-primary"
            />
            <span className="ml-2">UV2</span>
          </div>
          <input
            type="number"
            name="uv3_points"
            placeholder="UV3 Points"
            value={formValues.uv3_points}
            onChange={handleChange}
            className="input input-bordered w-full mb-3"
            required
          />
          <div className="flex items-center mb-3">
            <input
              type="checkbox"
              name="uv3"
              checked={formValues.uv3}
              onChange={handleChange}
              className="toggle toggle-primary"
            />
            <span className="ml-2">UV3</span>
          </div>
          <div className="flex items-center mb-3">
            <input
              type="checkbox"
              name="uv4"
              checked={formValues.uv4}
              onChange={handleChange}
              className="toggle toggle-primary"
            />
            <span className="ml-2">UV4</span>
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={onClose}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditGradeModal;
