import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/disciplinegenerale`;
const token = localStorage.getItem("token");


/// GET
// Fonction pour récupérer toutes les disciplines
export const getAllDisciplinegenerales = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allDisciplineGenerale`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des disciplines:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};