import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { updateArticle } from '../../../services/articlesServices';
import Button from '../../../components/Button/Button';
import { toast } from 'react-toastify';

const EditArticleModal = ({ isOpen, onClose, article, refreshList }) => {
  const [formData, setFormData] = useState({
    nom: '',
    description: '',
    prix: '',
    taille: '',
    quantite_stock: '',
    categorie: '',
    photo_url: '',
    is_active: true,
  });

  useEffect(() => {
    if (article) {
      setFormData({
        nom: article.nom || '',
        description: article.description || '',
        prix: article.prix || '',
        taille: article.taille || '',
        quantite_stock: article.quantite_stock || '',
        categorie: article.categorie || '',
        photo_url: article.photo_url || '',
        is_active: article.is_active || true,
      });
    }
  }, [article]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateArticle(article.id, formData);
      refreshList();
      onClose();
      toast.success('Article mis à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'article", error);
      toast.error("Erreur lors de la mise à jour de l'article");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier un Article
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="nom" placeholder="Nom" value={formData.nom} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="description" placeholder="Description" value={formData.description} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="number" name="prix" placeholder="Prix" value={formData.prix} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="taille" placeholder="Taille" value={formData.taille} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="number" name="quantite_stock" placeholder="Quantité en stock" value={formData.quantite_stock} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="categorie" placeholder="Catégorie" value={formData.categorie} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="photo_url" placeholder="URL de la Photo" value={formData.photo_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <div className="flex items-center mb-3">
            <input type="checkbox" name="is_active" checked={formData.is_active} onChange={handleChange} className="mr-2" />
            <label className="text-gray-700">Afficher sur la boutique</label>
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditArticleModal;
