import React, { useEffect, useState } from "react";
import ArticleCard from "./ArticleCard";
import CartModal from "./CartModal";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { ToastContainer, toast } from "react-toastify";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getArticles } from "../../../services/articlesServices";
import { FaShoppingCart } from "react-icons/fa";
import Button from "../../../components/Button/Button";

const Boutique = () => {
  useScrollToTop();
  const [articles, setArticles] = useState([]);
  const [filtreCategorie, setFiltreCategorie] = useState(
    "Toutes les catégories"
  );
  const [cart, setCart] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const articlesData = await getArticles();
      const activeArticles = articlesData.filter(
        (article) => article.is_active
      );
      setArticles(activeArticles);
      const categories = new Set(
        activeArticles.map((article) => article.categorie)
      );
      setCategories(["Toutes les catégories", ...categories]);
    } catch (error) {
      console.error("Erreur lors de la récupération des articles:", error);
      toast.error("Erreur lors de la récupération des articles.");
    }
  };

  const addToCart = (article) => {
    setCart((prev) => {
      const itemExists = prev.find((item) => item.id === article.id);
      if (itemExists) {
        return prev.map((item) =>
          item.id === article.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prev, { ...article, quantity: 1 }];
    });
  };

  const updateQuantity = (id, newQuantity) => {
    setCart((prev) =>
      prev
        .map((item) =>
          item.id === id ? { ...item, quantity: newQuantity } : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const removeFromCart = (id) => {
    const remainingItems = cart.filter((item) => item.id !== id);
    setCart(remainingItems);
    toast.success("Article retiré du panier.");
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const refreshArticles = async () => {
    const articlesData = await getArticles();
    const activeArticles = articlesData.filter((article) => article.is_active);
    setArticles(activeArticles);
  };

  const filteredArticles = articles.filter(
    (article) =>
      filtreCategorie === "Toutes les catégories" ||
      article.categorie === filtreCategorie
  );

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Boutique
      </h2>
      <DashboardNavigation
        role={userRole}
        position="top-right"
        autoClose={5000}
        newestOnTop={true}
      />

      <div className="flex w-full max-w-[1280px] mx-auto">
        <select
          className="select select-bordered w-full dark:text-black max-w-none dark:bg-slate-200"
          onChange={(e) => setFiltreCategorie(e.target.value)}
          value={filtreCategorie}
        >
          {categories.map((categorie, index) => (
            <option key={index} value={categorie}>
              {categorie}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-center my-4 gap-4">
        <Button
          text="Afficher mon Panier"
          onClick={toggleModal}
          icon={FaShoppingCart}
          className="gap-2"
          hoverLabel="Voir le panier"
        />
      </div>
      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-[1280px] mx-auto">
        {filteredArticles.map((article) => (
          <ArticleCard key={article.id} {...article} addToCart={addToCart} />
        ))}
      </div>
      {isModalOpen && (
        <CartModal
          cart={cart}
          setCart={setCart}
          closeModal={toggleModal}
          updateQuantity={updateQuantity}
          removeFromCart={removeFromCart}
          isModalOpen={isModalOpen}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Boutique;
