import React, { useState, useEffect } from "react";
import { FaInfoCircle, FaHeart, FaRegHeart, FaTimes, FaVideo } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { getUserFavorites } from "../../../services/usersTechniquesServices";

const TechniqueCard = ({ technique, userId, onAddFavorite, onRemoveFavorite }) => {
  const { image1_url, nom, famille, traduction, ceinture, video_url } = technique;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const checkFavorite = async () => {
      if (userId) {
        const favorites = await getUserFavorites(userId);
        setIsFavorite(favorites.some(fav => fav.technique_id === technique.id));
      }
    };
    checkFavorite();
  }, [userId, technique.id]);

  const handleFavoriteToggle = async () => {
    if (isFavorite) {
      await onRemoveFavorite(technique.id);
    } else {
      await onAddFavorite(technique.id);
    }
    setIsFavorite(!isFavorite);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openVideoInNewTab = () => {
    window.open(video_url, '_blank');
  };

  const handleModalClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const getCeintureColor = (ceinture) => {
    switch (ceinture.toLowerCase()) {
      case "jaune": return "bg-yellow-400 text-black";
      case "orange": return "bg-orange-400 text-black";
      case "verte": return "bg-green-400 text-black";
      case "bleue": return "bg-blue-400 text-white";
      case "marron": return "bg-yellow-900 text-white";
      case "noire": return "bg-gray-900 text-white";
      default: return "bg-gray-400 text-black";
    }
  };

  return (
    <>
      <div className={`bg-base-100 shadow-xl mx-2 mb-3 w-full cursor-pointer flex flex-col rounded-xl h-full min-h-[400px]`}>
        <figure className="w-full h-full flex-auto">
          <img src={image1_url || "https://daisyui.com/images/stock/photo-1635805737707-575885ab0820.jpg"} alt={nom} className="object-cover w-full h-full rounded-t-xl" onClick={openModal} />
        </figure>
        <div className="p-2 flex flex-col w-full">
          <div className="flex flex-row justify-between align-middle">
            <div className={`badge badge-ghost ${getCeintureColor(ceinture)} text-xs w-max`}>{ceinture}</div>
            <div className="flex flex-row gap-2">
              <div className="badge badge-info text-white rounded-full cursor-pointer text-xl p-3" onClick={openModal}>
                <FaInfoCircle />
              </div>
              <div className="badge badge-ghost text-red-500 rounded-full cursor-pointer text-xl p-3" onClick={handleFavoriteToggle}>
                {isFavorite ? <FaHeart /> : <FaRegHeart />}
              </div>
            </div>
          </div>
          <p className="font-bold">{nom}</p>
          <p className="italic text-xs">{traduction}</p>
          <p className="text-xs text-gray-500">Famille : {famille}</p>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 font-montserrat" onClick={handleModalClick}>
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
            <img src={image1_url || "https://daisyui.com/images/stock/photo-1635805737707-575885ab0820.jpg"} alt="Modal Content" className="w-full max-h-[600px] mb-4 object-cover"/>
            <p className="text-lg font-normal uppercase mb-3">{nom} - <span className="text-sm italic normal-case mb-2">{traduction}</span> </p>
            <div className="flex justify-center gap-2">
              {video_url && <Button text="Vidéo" onClick={openVideoInNewTab} icon={FaVideo} hoverLabel="Voir la vidéo" className="gap-2"/>}
              <Button text="Fermer" onClick={closeModal} icon={FaTimes} hoverLabel="Fermer la modale" className="gap-2"/>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TechniqueCard;
