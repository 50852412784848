import React, { useState, useEffect } from "react";
import TopBar from "./TopBar";
import LinkNav from "./LinkNav";
import LogoSection from "./LogoSection";
import { useUser } from "../UserContext"; // Assure-toi que ce hook est correctement défini et exporté
import { getUnreadMessagesCountByUser } from "../../../services/messageServices";
import { getUnreadNotificationsCountByUser } from "../../../services/notificationServices";

const Navbar2 = () => {
  const { user, updateUser } = useUser();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);

  useEffect(() => {
    // Tente de lire les informations de l'utilisateur depuis localStorage
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      try {
        const userInfo = JSON.parse(storedUserInfo);
        if (userInfo && !user) {
          updateUser(userInfo); // Met à jour l'état global de l'utilisateur si pas déjà défini
        }
      } catch (error) {
        console.error(
          "Erreur lors du parsing des informations de l'utilisateur",
          error
        );
      }
    }
  }, [user, updateUser]);

  // Messages count non lus
  useEffect(() => {
    if (user && user.userId) {
      const fetchUnreadMessagesCount = async () => {
        const count = await getUnreadMessagesCountByUser();
        setMessagesCount(count);
      };

      fetchUnreadMessagesCount();
    }
  }, [user]);

  const onLogout = () => {
    localStorage.clear(); // Efface le localStorage
    updateUser(null); // Réinitialise l'état de l'utilisateur dans le contexte global
  };

  // Notifications count non lues
  // Assurez-vous que getUnreadNotificationsCountByUser est correctement défini et exporté
  // Gestion des erreurs si user ou user.userId n'est pas défini
  useEffect(() => {
    if (user && user.userId) {
      const fetchUnreadNotificationsCount = async () => {
        const count = await getUnreadNotificationsCountByUser();
        setNotificationsCount(count);
      };

      fetchUnreadNotificationsCount();
    }
  }, [user]);

  //   useEffect(() => {
  //   if (user && user.userId) {
  //     const fetchUnreadNotificationsCount = async () => {
  //       const count = await getUnreadNotificationsCountByUser();
  //       setNotificationsCount(count);
  //     };

  //     fetchUnreadNotificationsCount();
  //   }
  // }, [user]);

  return (
    <nav className="fixed w-full z-30">
      <TopBar
        user={user}
        onLogout={onLogout}
        messagesCount={messagesCount}
        notificationsCount={notificationsCount}
      />
      <div className="flex justify-between items-center w-full">
        <div className="hidden custom:block">
          <LogoSection />
        </div>
        <div className="flex-1">
          <LinkNav user={user} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar2;
