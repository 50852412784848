import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { getAdherentsByEvent, getCoachesByEvent, toggleAdherentValidation } from './../../../services/eventServices';

const EventDetailsModal = ({ event, isOpen, onClose }) => {
  const [adherents, setAdherents] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    const loadAdherentsAndCoaches = async () => {
      if (event) {
        const [adherentsData, coachesData] = await Promise.all([
          getAdherentsByEvent(event.id),
          getCoachesByEvent(event.id)
        ]);
        const filteredAdherents = adherentsData.filter(
          adherent => !coachesData.some(coach => coach.id === adherent.id)
        );
        setAdherents(filteredAdherents);
        setCoaches(coachesData);
      }
    };

    if (isOpen) {
      loadAdherentsAndCoaches();
    }
  }, [event, isOpen]);

  const handleToggleValidation = async (adherentId, isCoach = false) => {
    try {
      await toggleAdherentValidation(adherentId, event.id);

      // Mise à jour de la validation pour les adhérents
      if (isCoach) {
        setCoaches(coaches.map(coach =>
          coach.id === adherentId ? { ...coach, AdherentEvent: { ...coach.AdherentEvent, is_validate: !coach.AdherentEvent.is_validate } } : coach
        ));
      } else {
        setAdherents(adherents.map(adh =>
          adh.id === adherentId ? { ...adh, AdherentEvent: { ...adh.AdherentEvent, is_validate: !adh.AdherentEvent.is_validate } } : adh
        ));
      }

    } catch (error) {
      console.error("Erreur lors du changement de validation :", error);
    }
  };

  if (!isOpen || !event) {
    return null;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? null : date.toLocaleDateString("fr-FR");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
      <div className="relative bg-white dark:bg-slate-600 rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 max-h-[80vh] overflow-y-auto">
        <div className="absolute top-4 right-4">
          <button onClick={onClose} className="text-white bg-primary rounded-full p-2">
            <FaTimes />
          </button>
        </div>
        <div className="flex flex-col md:flex-row h-full overflow-y-auto">
          <div className="flex flex-col w-full md:w-2/3 p-4 overflow-y-auto">
            <h3 className="text-xl leading-6 font-medium mb-4 bg-slate-800 text-white p-2">{event.titre}</h3>
            {event.description && <p className="text-sm text-gray-500 dark:text-slate-200">{event.description}</p>}
            {event.start && (
              <p>
                <strong className="dark:text-slate-400">Date de début :</strong> {formatDate(event.start)}
              </p>
            )}
            {event.end && formatDate(event.end) && (
              <p>
                <strong className="dark:text-slate-400">Date de fin :</strong> {formatDate(event.end)}
              </p>
            )}
            {event.lieu_event && <p><strong className="dark:text-slate-400">Lieu :</strong> {event.lieu_event}</p>}
            {event.type_event && <p><strong className="dark:text-slate-400">Type :</strong> {event.type_event}</p>}
            {event.level_event && <p><strong className="dark:text-slate-400">Niveau :</strong> {event.level_event}</p>}
            {event.cate_event && <p><strong className="dark:text-slate-400">Catégories :</strong> {event.cate_event}</p>}
            {event.horaire_event && (
              <p>
                <strong className="dark:text-slate-400">Horaires :</strong>
                <span
                  className="text-gray-600 dark:text-slate-200"
                  dangerouslySetInnerHTML={{ __html: event.horaire_event }}
                />
              </p>
            )}
            {event.agenda_url && (
              <p>
                <a href={event.agenda_url} target="_blank" rel="noopener noreferrer" className="text-black dark:text-white hover:text-primary dark:hover:text-primary">
                  Ajouter à votre agenda
                </a>
              </p>
            )}
            <hr className="my-4 border-t border-gray-200"/>

            {/* Afficher les coachs inscrits */}
            <h4 className="text-lg font-semibold mb-2">Coachs inscrits :</h4>
            <ul className="list-none">
              {coaches.map((coach) => (
                <li key={coach.id} className="flex items-center">
                  <button onClick={() => handleToggleValidation(coach.id, true)} className="mr-2">
                  </button>
                  <span>🥋 {coach.nom} {coach.prenom}</span>
                </li>
              ))}
            </ul>

            {/* Afficher les adhérents inscrits */}
            <h4 className="text-lg font-semibold mt-4 mb-2">Adhérents inscrits :</h4>
            <ul className="list-none">
              {adherents.map((adherent) => (
                <li key={adherent.id} className="flex items-center">
                  <button onClick={() => handleToggleValidation(adherent.id)} className="mr-2">
                    {adherent.AdherentEvent?.is_validate ? '✅' : '🔲'}
                  </button>
                  <span>{adherent.nom} {adherent.prenom}</span>
                </li>
              ))}
            </ul>
          </div>
          {event.photo_url && (
            <div className="w-full md:w-1/3 flex justify-center items-center p-4 bg-gray-100 dark:bg-gray-600 overflow-hidden">
              <img src={event.photo_url} alt="Event" className="max-w-full h-full object-cover rounded-md shadow"/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsModal;
