import React, { useState, useEffect } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { getAllUsers } from "../../../services/userServices";
import { getArticles } from "../../../services/articlesServices";
import { createReglement } from "../../../services/achatsServices";
import { sendNotification } from "../../../services/notificationServices"; // Importer la fonction sendNotification
import { toast } from "react-toastify";

const CreateAchatModal = ({ isActive, onClose, refreshAchats }) => {
  const [users, setUsers] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedArticle, setSelectedArticle] = useState("");
  const [quantite, setQuantite] = useState(1);
  const [dateAchat, setDateAchat] = useState("");
  const [messageAchat, setMessageAchat] = useState("");
  const [statutReglement, setStatutReglement] = useState("");
  const [typeReglement, setTypeReglement] = useState(""); // Nouvel état pour type_reglement
  const [token, setToken] = useState(""); // Ajout du token

  const resetForm = () => {
    setSelectedUser("");
    setSelectedArticle("");
    setQuantite(1);
    setDateAchat("");
    setMessageAchat("");
    setStatutReglement("");
    setTypeReglement(""); // Réinitialiser type_reglement
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersData, articlesData] = await Promise.all([
          getAllUsers(),
          getArticles(),
        ]);
        setUsers(usersData);
        setArticles(articlesData);

        // Supposons que le token soit stocké localement
        const storedToken = localStorage.getItem("token");
        setToken(storedToken);
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error.message);
        toast.error("Erreur lors du chargement des données.");
      }
    };
    fetchData();
  }, []);

  const handleSendNotification = async (recipientId) => {
    const message = `Le responsable du club vous a affecté un règlement d'un article de la boutique à régler sur la page Commandes & Règlements.`;
    try {
      await sendNotification(message, recipientId, token);
      toast.success("Notification envoyée avec succès");
    } catch (error) {
      console.error("Erreur lors de l'envoi de la notification:", error);
      toast.error("Erreur lors de l'envoi de la notification");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const achatData = {
      user_achat_id: selectedUser,
      article_id: selectedArticle,
      quantite,
      date_achat: dateAchat,
      message_achat: messageAchat,
      statut_reglement: statutReglement,
      type_reglement: typeReglement, // Ajouter type_reglement aux données
    };

    try {
      await createReglement(achatData);
      await handleSendNotification(selectedUser); // Envoyer une notification à l'utilisateur
      onClose();
      refreshAchats();
      resetForm();
      toast.success("Achat ajouté avec succès.");
    } catch (error) {
      console.error("Erreur lors de la création de l'achat", error.message);
      toast.error("Erreur lors de la création de l'achat.");
    }
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Ajouter un Nouveau Achat
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4 dark:text-black">
          <select
            className="select select-bordered w-full"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            required
          >
            <option value="">Sélectionnez un utilisateur</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.nom} {user.prenom}
              </option>
            ))}
          </select>
          <select
            className="select select-bordered w-full"
            value={selectedArticle}
            onChange={(e) => setSelectedArticle(e.target.value)}
            required
          >
            <option value="">Sélectionnez un article</option>
            {articles.map((article) => (
              <option key={article.id} value={article.id}>
                {article.nom}
              </option>
            ))}
          </select>
          <input
            type="number"
            className="input input-bordered w-full"
            value={quantite}
            min="1"
            onChange={(e) => setQuantite(e.target.value)}
            required
          />
          <input
            type="date"
            className="input input-bordered w-full"
            value={dateAchat}
            onChange={(e) => setDateAchat(e.target.value)}
            required
          />
          <textarea
            className="textarea textarea-bordered w-full"
            value={messageAchat}
            onChange={(e) => setMessageAchat(e.target.value)}
            placeholder="Message d'achat"
          />
          <select
            className="select select-bordered w-full"
            value={statutReglement}
            onChange={(e) => setStatutReglement(e.target.value)}
            required
          >
            <option value="">Sélectionnez un statut de réglement</option>
            <option value="Non Réglé">Non Réglé</option>
            <option value="Réglé">Réglé</option>
            <option value="En Attente">En Attente</option>
            <option value="Annulé">Annulé</option>
            <option value="Remboursé">Remboursé</option>
            <option value="Partiellement Réglé">Partiellement Réglé</option>
          </select>
          <select
            className="select select-bordered w-full"
            value={typeReglement} // Lier à l'état typeReglement
            onChange={(e) => setTypeReglement(e.target.value)}
            required
          >
            <option value="">Sélectionnez un type de réglement</option>
            <option value="En ligne">En ligne</option>
            <option value="Chèque">Chèque</option>
            <option value="Espèces">Espèces</option>
            <option value="Virement">Virement</option>
            <option value="Autre">Autre</option>
          </select>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={() => {
                resetForm();
                onClose();
              }}
              type="button"
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAchatModal;
