import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from './../../../components/Button/Button';
import { updateGallery } from './../../../services/gallerieServices';
import { toast } from 'react-toastify';

const EditAlbumModal = ({ isOpen, onClose, refreshList, gallery }) => {
    const [formData, setFormData] = useState({
        titre: gallery.titre,
        description: gallery.description,
        is_active: gallery.is_active
    });

    useEffect(() => {
        // Réinitialiser les champs du formulaire chaque fois que la galerie change
        setFormData({
            titre: gallery.titre,
            description: gallery.description,
            is_active: gallery.is_active
        });
    }, [gallery]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateGallery(gallery.id, formData);
            refreshList();
            onClose();
            toast.success("Galerie mise à jour avec succès.");
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'album', error);
            toast.error("Erreur lors de la mise à jour de la galerie.");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="modal-box bg-slate-200 dark:bg-slate-400 p-4 rounded-lg shadow-lg">
                <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">Éditer un Album</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="titre"
                        placeholder="Titre de l'album"
                        value={formData.titre}
                        onChange={handleChange}
                        className="input input-bordered w-full mb-3"
                        required
                    />
                    <textarea
                        name="description"
                        placeholder="Description de l'album"
                        value={formData.description}
                        onChange={handleChange}
                        className="textarea textarea-bordered w-full mb-3"
                        required
                    />
                    <label className="label cursor-pointer flex items-center space-x-2 mb-4">
                        <input
                            type="checkbox"
                            name="is_active"
                            checked={formData.is_active}
                            onChange={handleChange}
                            className="checkbox checkbox-primary"
                        />
                        <span className="label-text">Actif</span>
                    </label>
                    <div className="flex justify-center space-x-2 mt-4">
                        <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
                        <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditAlbumModal;
