import React, { useState } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../components/Button/Button";
import { addPartenaire } from "../../../services/partenairesServices";
import { toast } from "react-toastify";

const CreatePartenaireModal = ({ isActive, onClose, refreshPartenaires }) => {
  const [formData, setFormData] = useState({
    nom: "",
    photo_url: "",
    link_url: "",
    is_active: true,
    ordre: 0,
  });

  const resetForm = () => {
    setFormData({
      nom: "",
      photo_url: "",
      link_url: "",
      is_active: true,
      ordre: 0,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addPartenaire(formData);
      toast.success("Partenaire ajouté avec succès");
      resetForm();
      onClose();
      refreshPartenaires();
    } catch (error) {
      console.error("Erreur lors de l'ajout du partenaire", error);
      toast.error("Erreur lors de l'ajout du partenaire");
    }
  };

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-slate-400 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center bg-primary text-white rounded-md">
          Ajouter un Nouveau Partenaire
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            className="input input-bordered w-full"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            required
            placeholder="Nom du partenaire"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="photo_url"
            value={formData.photo_url}
            onChange={handleChange}
            placeholder="URL de la photo"
          />
          <input
            type="text"
            className="input input-bordered w-full"
            name="link_url"
            value={formData.link_url}
            onChange={handleChange}
            placeholder="URL du lien"
          />
          <input
            type="number"
            className="input input-bordered w-full"
            name="ordre"
            value={formData.ordre}
            onChange={handleChange}
            placeholder="Ordre"
          />
          <label className="flex items-center space-x-2">
            <span className="label-text">Actif ?</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
            />
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={() => {
                onClose();
                resetForm();
              }}
              className="btn-ghost gap-2"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePartenaireModal;
