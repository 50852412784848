import React, { useState, useEffect } from "react";
import { getUserIdByAdherentId } from "../../../services/adherentServices";
import { getReglementsByUser } from "../../../services/achatsServices";

const AchatsAdherent = ({ adherentId }) => {
  const [achats, setAchats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getStatusColor = (status) => {
    switch (status) {
      case "Non Réglé":
        return "bg-red-500 text-white text-sm";
      case "Réglé":
        return "bg-green-500 text-white text-sm";
      case "En Attente":
        return "bg-yellow-300 text-black text-sm";
      case "Annulé":
        return "bg-black text-white text-sm";
      case "Remboursé":
        return "bg-purple-500 text-white text-sm";
      case "Partiellement Réglé":
        return "bg-green-200 text-black text-sm";
      default:
        return "bg-gray-200 text-black text-sm";
    }
  };

  useEffect(() => {
    const fetchAchats = async () => {
      try {
        console.log(`Fetching users for adherentId: ${adherentId}`);
        const userObjects = await getUserIdByAdherentId(adherentId);
        console.log("User Objects:", userObjects);

        let achatsData = [];

        for (const userObject of userObjects) {
          console.log("User Object:", userObject); // Log détaillé pour chaque utilisateur

          const userId = userObject.UserAdherent?.user_id;
          console.log(`Fetching reglements for userId: ${userId}`);

          const userAchats = await getReglementsByUser(userId);
          console.log(`Achats for userId ${userId}:`, userAchats);

          if (Array.isArray(userAchats)) {
            achatsData = [...achatsData, ...userAchats];
          }
        }

        setAchats(achatsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des achats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAchats();
  }, [adherentId]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-2">
      <h3 className="text-xl font-semibold mb-4">Achats des Utilisateurs</h3>
      <div className="overflow-x-auto">
        {isLoading ? (
          <p>Chargement des données...</p>
        ) : (
          <table className="min-w-full bg-white dark:bg-gray-800">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b-2">Date</th>
                <th className="py-2 px-4 border-b-2">Utilisateur</th>
                <th className="py-2 px-4 border-b-2">Article</th>
                <th className="py-2 px-4 border-b-2">Montant</th>
                <th className="py-2 px-4 border-b-2">Statut</th>
              </tr>
            </thead>
            <tbody>
              {achats.length > 0 ? (
                achats.map((achat, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <td className="py-2 px-4 border-b">
                      {new Date(achat.date_achat).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4 border-b">{`${achat.User.prenom} ${achat.User.nom}`}</td>
                    <td className="py-2 px-4 border-b">
                      {achat.Article
                        ? achat.Article.nom
                        : "Licence-Assurance-Cotisation"}
                    </td>
                    <td className="py-2 px-4 border-b">{achat.prix_total} €</td>
                    <td className="py-2 px-4 border-b text-center">
                      <span
                        className={`px-2 py-1 rounded ${getStatusColor(
                          achat.statut_reglement
                        )}`}
                      >
                        {achat.statut_reglement}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    Aucun achat trouvé.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AchatsAdherent;
