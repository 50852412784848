import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { ToastContainer, toast } from "react-toastify";
import { getAllAdherentsList, getAdherentById } from "../../../services/adherentServices";
import AdherentDetails from "./AdherentDetails";
import AdherentGroupCategory from "./AdherentGroupCategory";
import AdherentManagers from "./AdherentManagers";
import AdherentPassageCeinture from "./AdherentPassageCeinture";
import AdherentPalmares from "./AdherentPalmares";
import AdherentCompetitions from "./AdherentCompetitions";
import DossierInscription from "./DossierInscription";
import ReglementInscription from "./ReglementInscription";
import AchatsAdherent from "./AchatsAdherent";
import AttendanceList from "./AttendanceList";
import ManageGroupModal from "../../PagesEnseignants/ListingAdherents/ManageGroupModal";
import EditAdherentModal from "../../PagesEnseignants/ListingAdherents/EditAdherentModal";
import "react-toastify/dist/ReactToastify.css";

const DetailsAdherent = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [adherents, setAdherents] = useState([]);
  const [selectedAdherentId, setSelectedAdherentId] = useState(null);
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false);
  const [isEditAdherentModalOpen, setIsEditAdherentModalOpen] = useState(false);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const data = await getAllAdherentsList();
        setAdherents(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents:", error);
        toast.error("Erreur lors de la récupération des adhérents");
      }
    };
    fetchAdherents();
  }, []);

  const handleSelectAdherent = async (e) => {
    const adherentId = e.target.value;
    if (adherentId) {
      try {
        const adherent = await getAdherentById(adherentId);
        setSelectedAdherentId(adherentId);
        setSelectedAdherent(adherent);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'adhérent:", error);
        toast.error("Erreur lors de la récupération de l'adhérent");
      }
    } else {
      setSelectedAdherentId(null);
      setSelectedAdherent(null);
    }
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Détails Adhérents
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="flex flex-col w-full mx-auto p-2 mt-4">
        <div className="mb-4">
          <label htmlFor="adherent-select" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Sélectionnez un adhérent
          </label>
          <select
            id="adherent-select"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-slate-700 dark:text-white"
            onChange={handleSelectAdherent}
          >
            <option value="">-- Choisissez un adhérent --</option>
            {adherents.map((adherent) => (
              <option key={adherent.id} value={adherent.id}>
                {adherent.nom} {adherent.prenom}
              </option>
            ))}
          </select>
        </div>

        {selectedAdherent && (
          <>
            <AdherentDetails adherent={selectedAdherent} />
            <AdherentGroupCategory adherentId={selectedAdherentId} />
            <AdherentManagers adherentId={selectedAdherentId} />
            <AdherentPassageCeinture adherentId={selectedAdherentId} />
            <AdherentPalmares adherentId={selectedAdherentId} />
            <AdherentCompetitions adherentId={selectedAdherentId} />
            <DossierInscription adherentId={selectedAdherentId} />
            <ReglementInscription adherentId={selectedAdherentId} saison={selectedAdherent.saison_active} />
            <AchatsAdherent adherentId={selectedAdherentId} />
            <AttendanceList adherentId={selectedAdherentId} />
          </>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default DetailsAdherent;
