import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/chat`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour afficher tous les messages
export const getAllMessages = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllMessages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des messages:", error);
    return [];
  }
};

// Fonction pour afficher les messages d'un groupe
export const getGroupMessages = async (groupId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getMessagesByGroup/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des messages:", error);
    return [];
  }
};

// Fonction pour afficher les messages d'un utilisateur
export const getUserMessages = async (userId) => {
  try {
    const response = await axios.get(`${apiUrl}/getMessagesByUser/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des messages:", error);
    return [];
  }
};

/// POST
// Fonction pour créer un message
export const createMessage = async (message) => {
  try {
    const response = await axios.post(`${apiUrl}/sendMessage`, message, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Réponse non attendue du serveur:", response);
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la création du message:", error);
    return null;
  }
};

/// PUT
// Fonction pour modifier un message
export const updateMessage = async (messageId) => {
  try {
    const response = await axios.put(`${apiUrl}/updateMessage/${messageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du message:", error);
    return null;
  }
};

/// DELETE
// Fonction pour supprimer un message
export const deleteMessage = async (messageId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteMessage/${messageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du message:", error);
    return null;
  }
};
