import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getAllEnseignants } from "../../../services/adherentServices";
import { getTeacherEvents } from "../../../services/eventServices";

const CoachingHoursChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teachers = await getAllEnseignants();
        const teacherHours = await Promise.all(teachers.map(async (teacher) => {
          const events = await getTeacherEvents(teacher.id);
          const totalHours = events.Events.reduce((acc, event) => acc + (event.AdherentEvent.coach_hour || 0), 0);
          return { nom: `${teacher.nom} ${teacher.prenom}`, hours: totalHours };
        }));
        setData(teacherHours);
      } catch (error) {
        console.error("Erreur lors de la récupération des heures de coaching", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: '100%', height: 300 }} className="flex w-full max-w-[800px] mx-auto">
      <ResponsiveContainer>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="nom" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="hours" fill="#8884d8" name="Heures de Coaching" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CoachingHoursChart;
