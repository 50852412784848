import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getAdherentsByGroup } from "../../../services/dashboardServices";

const GroupDistributionChart = () => {
  const [groupData, setGroupData] = useState([]);
  const isDarkMode = document.body.classList.contains("dark-mode");

  const darkModeStyles = {
    text: { fill: "#d1d5db" }, // dark:text-gray-200
    grid: { stroke: "#374151" }, // dark:border-gray-700
  };

  const lightModeStyles = {
    text: { fill: "#000000" }, // text-black
    grid: { stroke: "#e5e7eb" }, // border-gray-300
  };

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groups = await getAdherentsByGroup();
        setGroupData(
          groups.map((group) => {
            const adherentCount = group.adherentCount;
            const quotaGroupe = group.quota_groupe || 0;
            return {
              nom: group.nom,
              adherentCount,
              remainingQuota:
                quotaGroupe - adherentCount > 0
                  ? quotaGroupe - adherentCount
                  : 0, // Assurez-vous que la valeur est positive
            };
          })
        );
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données des groupes",
          error
        );
      }
    };

    fetchGroupData();
  }, []);

  return (
    <div className="overflow-x-auto shadow-xl max-w-[1280px] mx-auto">
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          layout="vertical"
          data={groupData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tick={isDarkMode ? darkModeStyles.text : lightModeStyles.text}
          />
          <YAxis
            type="category"
            dataKey="nom"
            axisLine={false}
            tickLine={false}
            tick={isDarkMode ? darkModeStyles.text : lightModeStyles.text}
            width={150} // Ajustez la largeur pour s'adapter aux noms longs
          />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={
              isDarkMode
                ? darkModeStyles.grid.stroke
                : lightModeStyles.grid.stroke
            }
          />
          <Legend
            wrapperStyle={
              isDarkMode ? darkModeStyles.text : lightModeStyles.text
            }
          />
          <Tooltip />
          <Bar
            dataKey="adherentCount"
            stackId="a"
            fill="#8884d8"
            name="Inscrits"
          />
          <Bar
            dataKey="remainingQuota"
            stackId="a"
            fill="#82ca9d"
            name="Quota Restant"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GroupDistributionChart;
