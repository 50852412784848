import React, { useState, useEffect } from "react";
import { getGroupsList } from "./../../../services/groupServices";
import {
  getAllAdherentsListWithGroup,
  getBirthdayAdherents,
  updateTeacherNotes,
  deleteAdherent,
} from "./../../../services/adherentServices";
import { getSettings } from "./../../../services/settingsServices";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import useScrollToTop from "../../../hooks/useScollToTop";
import ModalFicheAdherent from "./ModalFicheAdherent";
import EditAdherentModal from "./EditAdherentModal";
import AdherentDetails from "./../../PagesUtilisateurs/FicheAdherent/AdherentDetails";
import AdherentGroupCategory from "./../../PagesUtilisateurs/FicheAdherent/AdherentGroupCategory";
import AdherentPassageCeinture from "./../../PagesUtilisateurs/FicheAdherent/AdherentPassageCeinture";
import AdherentPalmares from "./../../PagesUtilisateurs/FicheAdherent/AdherentPalmares";
import Button from "../../../components/Button/Button";
import {
  FaTrashAlt,
  FaEdit,
  FaExchangeAlt,
  FaBirthdayCake,
  FaSave,
  FaPlus,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import ChangeGroupModal from "./ChangeGroupModal";
import AdherentGradeCN from "./../../PagesUtilisateurs/FicheAdherent/AdherentGradeCN";
import ManageGroupModal from "./ManageGroupModal";
import BirthdaySection from "./BirthdaySection"; // Importation du nouveau composant

const AdherentsGrid = () => {
  useScrollToTop();
  const [groups, setGroups] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const isAdmin = userRole === "admin";

  const userId = user?.userId;
  const [filterGroup, setFilterGroup] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [activeSeason, setActiveSeason] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [isChangeGroupModalOpen, setIsChangeGroupModalOpen] = useState(false);
  const [birthdayAdherents, setBirthdayAdherents] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [hideLicencieArret, setHideLicencieArret] = useState(false);
  const [teacherNotes, setTeacherNotes] = useState("");
  const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false);

  useEffect(() => {
    fetchSettings();
    fetchData();
    fetchBirthdayAdherents();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupList = await getGroupsList();
      setGroups(groupList);
    };

    fetchGroups();
  }, []);

  const fetchSettings = async () => {
    try {
      const settings = await getSettings();
      if (settings && settings.length > 0) {
        const activeSeason = settings[0].saison_active;
        const previousSeason = getPreviousSeason(activeSeason);
        const nextSeason = getNextSeason(activeSeason);
        setSeasons([previousSeason, activeSeason, nextSeason]);
        setActiveSeason(activeSeason);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des paramètres:", error);
    }
  };

  const fetchData = async () => {
    try {
      const groupList = await getGroupsList();
      setGroups(groupList);
      const adherentList = await getAllAdherentsListWithGroup();
      filterAdherents(adherentList);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  };

  const filterAdherents = (adherentList) => {
    const filteredAdherents = adherentList.filter((adherent) => {
      return (
        adherent.saison_active === activeSeason &&
        (selectedGroup === "" ||
          adherent.Groups.some((g) => g.nom === selectedGroup)) &&
        (!hideLicencieArret ||
          adherent.statut_inscription !== "Licencié - Arrêt")
      );
    });
    setAdherents(filteredAdherents);
    console.log("Adherents after filter:", filteredAdherents); // Log for debugging
  };

  const fetchBirthdayAdherents = async () => {
    try {
      const birthdayData = await getBirthdayAdherents();
      setBirthdayAdherents(birthdayData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des adhérents d'anniversaire",
        error
      );
    }
  };

  const openModal = (adherent) => {
    setSelectedAdherent(adherent);
    setTeacherNotes(adherent.notes_enseignant || "");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdherent(null);
  };

  const openEditModal = (adherent) => {
    setSelectedAdherent(adherent);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedAdherent(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGroupChange = async (e) => {
    const groupName = e.target.value;
    setSelectedGroup(groupName);

    if (groupName) {
      const allAdherents = await getAllAdherentsListWithGroup();
      const filteredAdherents = allAdherents.filter(
        (adherent) =>
          adherent.Groups.some((group) => group.nom === groupName) &&
          adherent.saison_active === activeSeason
      );
      setAdherents(filteredAdherents);
    } else {
      setAdherents([]);
    }
  };

  const handleSeasonChange = async (e) => {
    const season = e.target.value;
    setActiveSeason(season);

    if (selectedGroup) {
      const allAdherents = await getAllAdherentsListWithGroup();
      const filteredAdherents = allAdherents.filter(
        (adherent) =>
          adherent.Groups.some((group) => group.nom === selectedGroup) &&
          adherent.saison_active === season
      );
      setAdherents(filteredAdherents);
    } else {
      setAdherents([]);
    }
  };

  const openChangeGroupModal = (adherent) => {
    setSelectedAdherent(adherent);
    setIsChangeGroupModalOpen(true);
  };

  const closeChangeGroupModal = (refresh = false) => {
    setIsChangeGroupModalOpen(false);
    setSelectedAdherent(null);
    if (refresh) fetchData();
  };

  const openManageGroupModal = (adherent) => {
    setSelectedAdherent(adherent);
    setIsManageGroupModalOpen(true);
  };

  const closeManageGroupModal = (refresh = false) => {
    setIsManageGroupModalOpen(false);
    setSelectedAdherent(null);
    if (refresh) fetchData();
  };

  const getStatusColor = (statut_inscription) => {
    switch (statut_inscription) {
      case "Licencié":
        return "bg-green-300";
      case "Licencié Hors Club":
        return "bg-green-200";
      case "Inscrit":
        return "bg-yellow-300";
      case "Cours d'essai":
        return "bg-red-300";
      case "Licencié - Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié et Arrêt":
        return "bg-slate-300";
      case "Ancien Licencié":
        return "bg-slate-400";
      default:
        return "bg-base-100";
    }
  };

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const getDefaultPhoto = (genre) => {
    return genre === "Masculin"
      ? "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judoka_avatar_pi37iq.png"
      : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714525147/images/adherents/judokate_avatar_odsr9l.png";
  };

  const filteredAdherents = () => {
    return adherents.filter((adherent) => {
      return (
        adherent.saison_active === activeSeason &&
        (selectedGroup === "" ||
          adherent.Groups.some((g) => g.nom === selectedGroup)) &&
        (searchTerm === "" ||
          adherent.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
          adherent.prenom.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!hideLicencieArret ||
          adherent.statut_inscription !== "Licencié - Arrêt")
      );
    });
  };

  const handleDeleteAdherent = async (adherentId, adherentName) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer l'adhérent ${adherentName} ? Cette action est irréversible.`
      )
    ) {
      try {
        await deleteAdherent(adherentId);
        toast.success("Adhérent supprimé avec succès");
        closeModal();
        fetchData();
      } catch (error) {
        console.error("Erreur lors de la suppression", error);
        toast.error("Erreur lors de la suppression de l’adhérent");
      }
    }
  };

  const handleSaveTeacherNotes = async () => {
    if (selectedAdherent) {
      try {
        await updateTeacherNotes(selectedAdherent.id, {
          notes_enseignant: teacherNotes,
        });
        toast.success("Notes enseignant mises à jour avec succès");
        fetchData();
        setIsModalOpen(false);
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des notes enseignant",
          error
        );
        toast.error("Erreur lors de la mise à jour des notes enseignant");
      }
    }
  };

  const calculateAge = (date_naissance) => {
    const birthDate = new Date(date_naissance);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const formatDate = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date().toLocaleDateString("fr-FR", options);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing Adhérents
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="mx-auto p-4 mb-4 max-w-[1280px]">
        <select
          onChange={handleSeasonChange}
          className="select select-bordered w-full max-w-xs mb-4 dark:bg-slate-200 dark:text-gray-800"
          value={activeSeason}
        >
          {seasons.map((season) => (
            <option key={season} value={season}>
              {season}
            </option>
          ))}
        </select>

        <select
          onChange={handleGroupChange}
          className="select select-bordered w-full max-w-xs mb-4 dark:bg-slate-200 dark:text-gray-800"
          value={selectedGroup}
        >
          <option value="">Sélectionnez un groupe</option>
          {groups.map((group) => (
            <option key={group.id} value={group.nom}>
              {group.nom}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Rechercher par nom ou prénom"
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full max-w-xs mb-4 dark:bg-slate-300"
        />

        <BirthdaySection
          birthdayAdherents={birthdayAdherents}
          formatDate={formatDate}
          calculateAge={calculateAge}
          getDefaultPhoto={getDefaultPhoto}
          token={user.token} // Ajout du token pour l'authentification de la notification
        />

        <div className="flex items-center gap-4 mb-4">
          <input
            type="checkbox"
            checked={hideLicencieArret}
            onChange={() => setHideLicencieArret(!hideLicencieArret)}
            className="toggle toggle-primary"
          />
          <label>Masquer les 'Licencié - Arrêt'</label>
        </div>

        {selectedGroup && (
          <div>
            <h3 className="text-2xl font-light mb-4 bg-slate-800 text-white p-2 font-montserrat">
              Groupe {selectedGroup} -{" "}
              {
                adherents.filter(
                  (adherent) =>
                    !hideLicencieArret ||
                    adherent.statut_inscription !== "Licencié - Arrêt"
                ).length
              }{" "}
              Adhérent(s)
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-4 font-montserrat">
              {filteredAdherents()
                .filter(
                  (adherent) =>
                    (searchTerm === "" ||
                      adherent.nom
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      adherent.prenom
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) &&
                    (!hideLicencieArret ||
                      adherent.statut_inscription !== "Licencié - Arrêt")
                )
                .map((adherent) => {
                  const isBirthday = birthdayAdherents.some(
                    (birthdayAd) => birthdayAd.id === adherent.id
                  );
                  return (
                    <div
                      onClick={() => openModal(adherent)}
                      key={adherent.id}
                      className={`relative shadow-xl rounded-lg ${getStatusColor(
                        adherent.statut_inscription
                      )}`}
                    >
                      {isBirthday && (
                        <div className="absolute top-2 right-2 hover:scale-110 transition-transform duration-300">
                          <FaBirthdayCake className="text-2xl text-white bg-primary rounded-badge px-2 py-2 w-10 h-10 shadow-lg" />
                        </div>
                      )}
                      <figure className="aspect-square">
                        <img
                          src={
                            adherent.autorisation_photo
                              ? adherent.photo_url ||
                                getDefaultPhoto(adherent.genre)
                              : "https://res.cloudinary.com/dy5kblr32/image/upload/v1714598563/images/adherents/panneau-interdiction-de-filmer-ou-de-photographier_shhqq4.jpg"
                          }
                          alt={`${adherent.nom} ${adherent.prenom}`}
                          className="object-cover w-full h-full rounded-t-lg"
                        />
                      </figure>
                      <div className="p-2 text-center">
                        <p className="text-sm font-medium dark:text-gray-800 font-montserrat">
                          {adherent.nom} {adherent.prenom}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>

      {selectedAdherent && isModalOpen && (
        <ModalFicheAdherent onClose={closeModal}>
          <AdherentDetails adherentId={selectedAdherent.id} />
          <AdherentGroupCategory adherentId={selectedAdherent.id} />
          <AdherentPassageCeinture adherentId={selectedAdherent.id} />
          <AdherentGradeCN adherentId={selectedAdherent.id} />
          <AdherentPalmares adherentId={selectedAdherent.id} />
          {isAdmin && (
            <>
              <div className="border p-4 rounded-lg bg-white dark:bg-gray-800 mb-4">
                <h3 className="text-lg font-bold mb-2">Notes Enseignants</h3>
                <textarea
                  value={teacherNotes}
                  onChange={(e) => setTeacherNotes(e.target.value)}
                  placeholder="Notes de l'enseignant"
                  className="textarea textarea-bordered w-full mb-4 dark:bg-slate-200"
                />
                <Button
                  onClick={handleSaveTeacherNotes}
                  className="btn-primary flex items-center gap-2"
                  text="Sauvegarder les notes"
                  icon={FaSave}
                />
              </div>
              <div className="flex gap-4 justify-between mt-2">
                <Button
                  onClick={() => openChangeGroupModal(selectedAdherent)}
                  className="btn-secondary flex items-center gap-2 mt-2"
                  text="Changer de Groupe"
                  icon={FaExchangeAlt}
                />
                {isChangeGroupModalOpen && (
                  <ChangeGroupModal
                    isOpen={isChangeGroupModalOpen}
                    onClose={closeChangeGroupModal}
                    adherentId={selectedAdherent?.id}
                    groups={groups}
                  />
                )}
                <Button
                  onClick={() => openManageGroupModal(selectedAdherent)}
                  className="btn-primary flex items-center gap-2 mt-2"
                  text="Gérer les Groupes"
                  icon={FaPlus}
                />
                {isManageGroupModalOpen && (
                  <ManageGroupModal
                    isOpen={isManageGroupModalOpen}
                    onClose={closeManageGroupModal}
                    adherentId={selectedAdherent?.id}
                    groups={groups}
                    adherentGroups={selectedAdherent?.Groups || []}
                    refreshData={fetchData}
                  />
                )}
                <Button
                  onClick={() => openEditModal(selectedAdherent)}
                  className="btn-info flex items-center gap-2 mt-2"
                  text="Éditer Adhérent"
                  icon={FaEdit}
                />
                {isEditModalOpen && (
                  <EditAdherentModal
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    adherent={selectedAdherent}
                    refreshList={fetchData}
                  />
                )}
                <Button
                  onClick={() =>
                    handleDeleteAdherent(
                      selectedAdherent.id,
                      `${selectedAdherent.nom} ${selectedAdherent.prenom}`
                    )
                  }
                  className="btn-error flex items-center gap-2 mt-2"
                  text="Supprimer l'adhérent"
                  icon={FaTrashAlt}
                />
              </div>
            </>
          )}
        </ModalFicheAdherent>
      )}

      <ToastContainer />
    </div>
  );
};

export default AdherentsGrid;
