import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { RiCheckDoubleFill } from "react-icons/ri";
import { FaTrashAlt } from "react-icons/fa";
import ButtonEyeNoRead from "../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../components/Button/ButtonEyeRead";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import "./NotificationCard.css"; // Fichier CSS pour les styles

const NotificationCard = ({ notification, toggleReadStatus, handleDeleteNotification }) => {
  const [swipeDirection, setSwipeDirection] = useState(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeDirection("left");
      setTimeout(() => {
        toggleReadStatus(notification.id);
        setSwipeDirection(null);
      }, 300);
    },
    onSwipedRight: () => {
      setSwipeDirection("right");
      setTimeout(() => {
        handleDeleteNotification(notification.id);
        setSwipeDirection(null);
      }, 300);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div
      className={`notification-card shadow-md rounded-lg p-4 mb-4 hover:bg-gray-300 ${
        notification.read_notification ? "bg-gray-200" : "bg-white"
      } ${swipeDirection === "left" ? "swipe-left" : ""} ${
        swipeDirection === "right" ? "swipe-right" : ""
      }`}
      {...handlers}
    >
      {swipeDirection === "left" && (
        <div className="swipe-icon">
          {notification.read_notification ? (
            <ButtonEyeNoRead hoverLabel="Marquer comme non lu" />
          ) : (
            <ButtonEyeRead hoverLabel="Marquer comme lu" />
          )}
        </div>
      )}
      {swipeDirection === "right" && (
        <div className="swipe-icon">
          <ButtonDelete hoverLabel="Supprimer" />
        </div>
      )}
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm font-semibold text-gray-900">
          {new Date(notification.created_at).toLocaleDateString("fr-FR", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          })}
        </span>
        <div className="flex space-x-2">
          {notification.read_notification ? (
            <ButtonEyeRead
              onClick={() => toggleReadStatus(notification.id)}
              hoverLabel="Marquer comme non lu"
            />
          ) : (
            <ButtonEyeNoRead
              onClick={() => toggleReadStatus(notification.id)}
              hoverLabel="Marquer comme lu"
            />
          )}
          <ButtonDelete
            onClick={() => handleDeleteNotification(notification.id)}
            hoverLabel="Supprimer"
          />
        </div>
      </div>
      <div className="text-sm text-gray-500 mb-2 max-w-xs overflow-hidden text-ellipsis">
        {notification.message}
      </div>
    </div>
  );
};

export default NotificationCard;
